/* import __COLOCATED_TEMPLATE__ from './two-column-layout.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface TwoColumnLayoutSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const TwoColumnLayout = templateOnlyComponent<TwoColumnLayoutSignature>();

export default TwoColumnLayout;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TwoColumnLayout: typeof TwoColumnLayout;
  }
}
