/* import __COLOCATED_TEMPLATE__ from './org-deactivation.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

interface DeactivationRequirementsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DeactivationRequirementsComponent extends Component<DeactivationRequirementsSignature> {
  topBanner = TopBanner;

  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  get routeName() {
    let { hasKycKybUpdateFeature } = this.organizationManager.organization;

    if (!this.subscriptionManager.features.bankAccount) {
      return 'settings.organization-profile';
    }

    return hasKycKybUpdateFeature ? 'settings.company-profile' : 'settings.organization-profile';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::OrgDeactivation': typeof DeactivationRequirementsComponent;
  }
}
