/* import __COLOCATED_TEMPLATE__ from './multi-direct-debit-collection.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MultiDirectDebitCollectionSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const MultiDirectDebitCollection = templateOnlyComponent<MultiDirectDebitCollectionSignature>();

export default MultiDirectDebitCollection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Cell::MultiDirectDebitCollection': typeof MultiDirectDebitCollection;
  }
}
