/* import __COLOCATED_TEMPLATE__ from './financing-installment-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { not } from 'macro-decorators';

import { Slug } from 'qonto/react/components/transactions/sidebar/details/slug';

interface TransactionsSidebarFinancingInstallmentDetailsSignature {
  // The arguments accepted by the component
  Args: {
    isErrorLoadingAttachments?: boolean;
    isLoadingAttachments?: boolean;
    isInvoiceTooltipDismissed?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransactionsSidebarFinancingInstallmentDetailsComponent extends Component<TransactionsSidebarFinancingInstallmentDetailsSignature> {
  slug = Slug;

  // @ts-expect-error
  @not('args.transaction.declined') hasAccountingValue;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];

  get showAttachments() {
    return this.hasAccountingValue;
  }

  get showBookkeeping() {
    return this.abilities.can('view vat bookkeeping') && this.hasAccountingValue;
  }

  get showAnalyticLabels() {
    return (
      this.abilities.can('access custom-label') &&
      this.abilities.can('read custom-label') &&
      this.hasAccountingValue
    );
  }

  get showBudget() {
    // @ts-expect-error
    let { transaction, isBudgetSupervisor } = this.args;

    return (
      this.abilities.can('update transaction budget') && transaction.debit && isBudgetSupervisor
    );
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    // @ts-expect-error
    if (this.attachmentModal && !this.attachmentModal.result) {
      // @ts-expect-error
      this.attachmentModal.close();
    }
  }

  @action
  handleOpenDetails() {
    // @ts-expect-error
    this.handleShowAttachment();
  }

  @action
  // @ts-expect-error
  handleShowAttachment(file) {
    // @ts-expect-error
    let selectedFile = file || this.args.transaction.attachments?.[0]?.file;

    // @ts-expect-error
    this.attachmentModal = this.modals.open(
      'attachments/attachment-viewer-modal',
      {
        isFullScreenModal: true,
        hasSidebar: true,
        title: this.intl.t('transactions.sidebar.bookkeeping.bookkeeping-details'),
        // @ts-expect-error
        transaction: this.args.transaction,
        selectedFile,
        // @ts-expect-error
        onDownload: (...args) => this.args.saveAttachment(this.args.transaction, ...args),
        // @ts-expect-error
        onRemove: (...args) => this.args.confirmRemoveAttachment(...args),
        // @ts-expect-error
        onSaveAttachment: (...args) => this.args.saveAttachment(...args),
        // @ts-expect-error
        handleOpenInInvoices: (...args) => this.args.openInInvoices(...args),
        // @ts-expect-error
        onSaveLabel: (...args) => this.args.saveLabel(...args),
        // @ts-expect-error
        onSelectTag: (...args) => this.args.selectTag(...args),
        // @ts-expect-error
        onSave: (...args) => this.args.saveTransaction(...args),
        // @ts-expect-error
        markAsReviewTask: (...args) => this.args.markAsReviewTask.perform(...args),
        // @ts-expect-error
        saveInInvoices: (...args) => this.args.saveAttachmentAsInvoice(...args),
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::FinancingInstallmentDetails': typeof TransactionsSidebarFinancingInstallmentDetailsComponent;
  }
}
