/* import __COLOCATED_TEMPLATE__ from './multi-transfer.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MultiTransferSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const MultiTransfer = templateOnlyComponent<MultiTransferSignature>();

export default MultiTransfer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Cell::MultiTransfer': typeof MultiTransfer;
  }
}
