/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';

import type { Beneficiary } from 'qonto/services/international-out/types';

interface Signature {
  Args: {
    canDelete?: boolean;
    canView?: boolean;
    onDelete?: (beneficiary: Beneficiary) => Promise<void>;
    onView?: (beneficiary: Beneficiary) => Promise<void>;
    isLoading?: boolean;
  };
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutBeneficiaryCardActionsComponent extends Component<Signature> {
  spinner = Spinner;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::BeneficiaryCard::Actions': typeof TransfersInternationalOutBeneficiaryCardActionsComponent;
  }
}
