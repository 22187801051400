/* import __COLOCATED_TEMPLATE__ from './refuse-delete-team.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface RefuseDeleteTeamModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RefuseDeleteTeamModal extends Component<RefuseDeleteTeamModalSignature> {
  @action
  handleClick() {
    // @ts-expect-error
    this.args.close();
    // @ts-expect-error
    this.args.data.viewTeamMemberships();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Teams::Modal::RefuseDeleteTeam': typeof RefuseDeleteTeamModal;
  }
}
