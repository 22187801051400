export default {
  "row": "qJi body-1",
  "active": "qJA",
  "cell": "qJY",
  "empty": "qJh",
  "ellipsis": "qJf",
  "text-secondary": "qJK",
  "cell-type": "qJG",
  "cell-reason": "qJr",
  "cell-amount": "qJb",
  "row-sidebar": "qJy",
  "animated": "qJU",
  "fadein-item": "qJj",
  "animated-cell": "qJW"
};
