/* import __COLOCATED_TEMPLATE__ from './x-phone-input.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { PREFERRED_COUNTRIES } from 'qonto/constants/countries';

interface XPhoneInputSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class XPhoneInputComponent extends Component<XPhoneInputSignature> {
  @tracked isValidNumber = false;

  @service declare organizationManager: Services['organizationManager'];

  initialCountry =
    // @ts-expect-error
    this.args.initialCountry ||
    // @ts-expect-error
    this.organizationManager.organization?.legalCountry ||
    PREFERRED_COUNTRIES[0];
  preferredCountries = PREFERRED_COUNTRIES;

  get autovalidate() {
    // @ts-expect-error
    return this.args.autovalidate ?? true;
  }

  get disabled() {
    // @ts-expect-error
    return this.args.disabled ?? false;
  }

  get required() {
    // @ts-expect-error
    return this.args.required ?? true;
  }

  @action
  // @ts-expect-error
  handleKeyPress(e) {
    // prevent letters and `+` to be input
    // but allow `ctrl+a`
    let character = e.key;

    let isLetterOrPlus = character && character.match(/[a-z+]/i);
    let isCtrlPressed = e.ctrlKey;
    let isMetaPressed = e.metaKey;
    let isBackSpace = character === 'Backspace';
    let isTab = character === 'Tab';
    let isEnter = character === 'Enter';

    if (isLetterOrPlus && !isCtrlPressed && !isMetaPressed && !isBackSpace && !isTab && !isEnter) {
      e.preventDefault();
      return false;
    }
  }

  @action
  // @ts-expect-error
  handleUpdate(number, { isValidNumber }) {
    this.isValidNumber = isValidNumber;
    // @ts-expect-error
    return this.args.update?.(number, { isValidNumber });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    XPhoneInput: typeof XPhoneInputComponent;
  }
}
