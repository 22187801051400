export default {
  "row": "qkK body-1",
  "active": "qkG",
  "cell": "qkr",
  "empty": "qkb",
  "ellipsis": "qky",
  "text-secondary": "qkU",
  "transfer-type": "qkj",
  "transfer-type__icon": "qkW",
  "quick-actions": "qku",
  "amount": "qJS",
  "cell-approve": "qJc",
  "cell-type": "qJq",
  "cell-requester": "qJZ",
  "account-select": "qJR",
  "row-compact": "qJQ",
  "row-sidebar": "qJe",
  "cell-amount": "qJB",
  "hidden": "qJE",
  "animated": "qJd",
  "fadein-item": "qJI",
  "animated-cell": "qJt"
};
