/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ActionsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Actions = templateOnlyComponent<ActionsSignature>();

export default Actions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::BeneficiaryCard::Actions': typeof Actions;
  }
}
