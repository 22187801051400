/* import __COLOCATED_TEMPLATE__ from './instant-transfer-toggle.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface InstantTransferToggleSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const InstantTransferToggle = templateOnlyComponent<InstantTransferToggleSignature>();

export default InstantTransferToggle;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::InstantTransferToggle': typeof InstantTransferToggle;
  }
}
