/* import __COLOCATED_TEMPLATE__ from './confirm-delete-team.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface ConfirmDeleteTeamModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConfirmDeleteTeamModal extends Component<ConfirmDeleteTeamModalSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  deleteTeamTask = dropTask(async () => {
    // @ts-expect-error
    let { team, confirmTask } = this.args.data;

    try {
      await team.destroyRecord();
      await confirmTask.linked().perform();
    } catch (error) {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
      throw error;
    }

    let successMessage = this.intl.t('teams.delete.toast-success');
    this.toastFlashMessages.toastSuccess(successMessage);
    this.segment.track('teams_delete_success');

    // @ts-expect-error
    this.args.close();
  });

  @action
  cancel() {
    if (this.deleteTeamTask.isRunning) {
      return;
    }

    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Teams::Modal::ConfirmDeleteTeam': typeof ConfirmDeleteTeamModal;
  }
}
