/* import __COLOCATED_TEMPLATE__ from './invoice-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { DOCUMENT_TYPE } from 'qonto/constants/self-billing';

interface SelfBillingFormInvoiceDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class SelfBillingFormInvoiceDetailsComponent extends Component<SelfBillingFormInvoiceDetailsSignature> {
  @service declare intl: Services['intl'];

  get documentTypes() {
    return [
      {
        code: DOCUMENT_TYPE.TD17,
        label: this.intl.t('self-billing.document-type.td17'),
      },
      { code: DOCUMENT_TYPE.TD18, label: this.intl.t('self-billing.document-type.td18') },
      { code: DOCUMENT_TYPE.TD19, label: this.intl.t('self-billing.document-type.td19') },
    ];
  }

  get selectedDocumentType() {
    // @ts-expect-error
    return this.documentTypes.find(({ code }) => code === this.args.invoice?.documentType);
  }

  // @ts-expect-error
  @action updateDocumentType({ code }) {
    // @ts-expect-error
    this.args.invoice.documentType = code;
  }

  @action
  // @ts-expect-error
  onIssueDateUpdate(date) {
    // @ts-expect-error
    this.args.invoice.issueDate = date;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::Form::InvoiceDetails': typeof SelfBillingFormInvoiceDetailsComponent;
  }
}
