/* import __COLOCATED_TEMPLATE__ from './sidebar-labels-dropdown.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarLabelsDropdownSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SidebarLabelsDropdown = templateOnlyComponent<SidebarLabelsDropdownSignature>();

export default SidebarLabelsDropdown;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SidebarLabelsDropdown: typeof SidebarLabelsDropdown;
  }
}
