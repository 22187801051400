export default {
  "savings-account": "cyG",
  "closed": "cyr",
  "badge": "cyb",
  "subtitle": "cyy",
  "amount": "cyU",
  "processing": "cyj",
  "gain": "cyW",
  "active": "cyu",
  "progress": "cUS",
  "progress-bar": "cUc",
  "progress-text": "cUq",
  "info": "cUZ",
  "menu-item": "cUR body-2",
  "close-account": "cUQ"
};
