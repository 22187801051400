/* import __COLOCATED_TEMPLATE__ from './transfer-funds.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

interface TransferFundsTopBarSignature {
  Args: {
    content: {
      message: string;
    };
    close: () => void;
  };
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class TransferFundsTopBar extends Component<TransferFundsTopBarSignature> {
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  topBanner = TopBanner;

  constructor(owner: unknown, args: TransferFundsTopBarSignature['Args']) {
    super(owner, args);
    this.segment.track('account-closing_status-banner_displayed', {
      Status: 'Transfer funds',
    });
  }

  @action onTransferFundsClick() {
    this.segment.track('account-closing_status-information_transfer-funds-clicked');
    this.router.transitionTo('transfers.landing');
  }
}
