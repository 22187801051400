/* import __COLOCATED_TEMPLATE__ from './splash-landing.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SplashLandingSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SplashLanding = templateOnlyComponent<SplashLandingSignature>();

export default SplashLanding;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SplashLanding: typeof SplashLanding;
  }
}
