/* import __COLOCATED_TEMPLATE__ from './illustration.hbs'; */
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

// @ts-expect-error
import { USER_ACTIONS_ILLUSTRATION_TYPE } from 'qonto/constants/user-actions';

interface UserActionsIllustrationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class UserActionsIllustrationComponent extends Component<UserActionsIllustrationSignature> {
  lottiePlayer = LottiePlayer;

  get svgPath() {
    // @ts-expect-error
    return this.args.type === USER_ACTIONS_ILLUSTRATION_TYPE.SVG && this.args.name
      ? // @ts-expect-error
        `/illustrations/user-actions/${this.args.name}.svg`
      : '';
  }

  get lottiePath() {
    // @ts-expect-error
    return this.args.type === USER_ACTIONS_ILLUSTRATION_TYPE.LOTTIE && this.args.name
      ? // @ts-expect-error
        `/lotties/user-actions/${this.args.name}.json`
      : '';
  }

  get remoteImagePath() {
    // @ts-expect-error
    return this.args.type === USER_ACTIONS_ILLUSTRATION_TYPE.REMOTE_IMAGE && this.args.name
      ? // @ts-expect-error
        this.args.name
      : '';
  }

  get remoteLottiePath() {
    // @ts-expect-error
    return this.args.type === USER_ACTIONS_ILLUSTRATION_TYPE.REMOTE_LOTTIE && this.args.name
      ? // @ts-expect-error
        this.args.name
      : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UserActions::Illustration': typeof UserActionsIllustrationComponent;
  }
}
