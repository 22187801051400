/* import __COLOCATED_TEMPLATE__ from './column.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

import { SortIcon } from 'qonto/react/components/paginated-table';

interface SupplierInvoicesTableHeaderColumnSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierInvoicesTableHeaderColumnComponent extends Component<SupplierInvoicesTableHeaderColumnSignature> {
  checkbox = Checkbox;
  sortIcon = SortIcon;

  get style() {
    // @ts-expect-error
    let { width, align } = this.args.column;
    let colWidth = width ? `width:${width};` : '';
    let textAlign = align || 'left';

    return htmlSafe(`${colWidth}text-align:${textAlign};`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table::Header::Column': typeof SupplierInvoicesTableHeaderColumnComponent;
  }
}
