/* import __COLOCATED_TEMPLATE__ from './cta.hbs'; */
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import {
  USER_ACTIONS_CTA_BUTTON_TYPE,
  USER_ACTIONS_CTA_BUTTON_TYPE_MAP,
  USER_ACTIONS_CTA_TYPE,
  // @ts-expect-error
} from 'qonto/constants/user-actions';

interface UserActionsCtaSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class UserActionsCtaComponent extends Component<UserActionsCtaSignature> {
  get isLinkType() {
    // @ts-expect-error
    return this.args.type === USER_ACTIONS_CTA_TYPE.LINK && this.args.href && this.args.text;
  }

  get isLinkToType() {
    // @ts-expect-error
    return this.args.type === USER_ACTIONS_CTA_TYPE.LINK_TO && this.args.route && this.args.text;
  }

  get isFlowInFlowType() {
    return (
      // @ts-expect-error
      this.args.type === USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW &&
      // @ts-expect-error
      this.args.name &&
      // @ts-expect-error
      this.args.stepId &&
      // @ts-expect-error
      this.args.text
    );
  }

  get isButtonType() {
    return (
      // @ts-expect-error
      this.args.type === USER_ACTIONS_CTA_TYPE.BUTTON && this.args.text && this.args.onClickTask
    );
  }

  get buttonClass() {
    return (
      // @ts-expect-error
      USER_ACTIONS_CTA_BUTTON_TYPE_MAP[this.args.buttonType] ??
      USER_ACTIONS_CTA_BUTTON_TYPE_MAP[USER_ACTIONS_CTA_BUTTON_TYPE.PRIMARY]
    );
  }

  get queryParams() {
    // @ts-expect-error
    return this.args.query ?? {};
  }

  handleClickTask = dropTask(async e => {
    // @ts-expect-error
    await this.args.onClickTask?.perform(e);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UserActions::Cta': typeof UserActionsCtaComponent;
  }
}
