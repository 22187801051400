/* import __COLOCATED_TEMPLATE__ from './details-sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import styles from 'qonto/components/supplier-invoices/details-sidebar.module.css';
import {
  E_CREDIT_NOTES_LIFECYCLE_EVENT_DESCRIPTION,
  EINVOICING_LIFECYCLE_EVENT_DESCRIPTION,
  EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES,
  INVOICE_STATUSES,
  SOURCE_TYPES,
} from 'qonto/constants/supplier-invoice';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { RelatedDocuments } from 'qonto/react/components/supplier-invoices/related-documents';
import { RelatedDocumentsLoading } from 'qonto/react/components/supplier-invoices/related-documents-loading';

interface DetailsSidebarSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
    isConfirmTransferTaskRunning?: boolean;
    invoice: SupplierInvoiceModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class DetailsSidebarComponent extends Component<DetailsSidebarSignature> {
  relatedDocumentsLoading = RelatedDocumentsLoading;
  relatedDocuments = RelatedDocuments;

  @service declare abilities: Services['abilities'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  @tracked showEditForm =
    // @ts-expect-error
    this.router.currentRoute?.queryParams.edit === 'true' || this.showEinvoicingForm;

  @action
  toggleShowEditForm() {
    this.showEditForm = !this.showEditForm;
    this.router.transitionTo({ queryParams: { edit: this.showEditForm } });
  }

  get showEinvoicingForm() {
    return (
      (this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice) &&
      this.args.invoice?.status === INVOICE_STATUSES.paid
    );
  }

  get showCTA() {
    return (
      this.abilities.can('update supplierInvoice') &&
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay, INVOICE_STATUSES.pending].includes(
        this.args.invoice?.status
      )
    );
  }

  get showSection() {
    return this.showCTA && !this.showEditForm;
  }

  get shouldShowRelatedDocuments() {
    return this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice;
  }

  get showRequestValidation() {
    // @ts-expect-error
    return this.args.request && this.args.invoice.status === INVOICE_STATUSES.pending;
  }

  get showRejectionSection() {
    return this.args.invoice?.status === INVOICE_STATUSES.rejected;
  }

  get showSelfInvoiceSection() {
    let isEInvoice = this.args.invoice?.source === SOURCE_TYPES.E_INVOICING;
    return this.abilities.can('read self-invoice') && !isEInvoice;
  }

  get beneficiaryWarning() {
    // @ts-expect-error
    return this.args.confirmTransferResult?.beneficiaryWarning;
  }

  get confirmErrors() {
    // @ts-expect-error
    return this.args.confirmTransferResult?.confirmErrors;
  }

  get confirmWarnings() {
    // @ts-expect-error
    return this.args.confirmTransferResult?.confirmWarnings;
  }

  get spendLimitsWarning() {
    // @ts-expect-error
    return this.args.confirmTransferResult?.spendLimitsWarning;
  }

  get spendLimits() {
    // @ts-expect-error
    return this.args.confirmTransferResult?.spendLimits;
  }

  onApproveRequestTask = dropTask(async request => {
    // @ts-expect-error
    await this.args.approveRequestTask.perform(request, this.args.invoice);
  });

  onDeclineRequestTask = dropTask(async request => {
    // @ts-expect-error
    await this.args.declineRequestTask.perform(request, this.args.invoice);
  });

  @action
  onCancel() {
    // @ts-expect-error
    this.args.onRequestWorkflow?.();
    this.segment.track('supplier-invoices_request-cancel_clicked');
    this.toastFlashMessages.clearToastMessages();
    this.toastFlashMessages.toastInfo(
      this.intl.t('supplier-invoices.info-toast.transfers.request-cancelled')
    );
  }

  get isCreditNote() {
    return this.args.invoice?.isCreditNote || false;
  }

  // French E-invoicing
  get frenchEinvoicingEventsToDisplay() {
    let events = this.args.invoice?.einvoicingLifecycleEvents || [];

    // @ts-expect-error
    return events?.filter(item =>
      EINVOICING_LIFECYCLE_EVENT_STATUS_CODES.includes(item.status_code)
    );
  }

  get showFrenchEinvoicingTimeline() {
    return Boolean(this.frenchEinvoicingEventsToDisplay.length);
  }

  get frenchEinvoicingCurrentStatusCode() {
    return this.frenchEinvoicingEventsToDisplay.at(-1)?.status_code;
  }

  get frenchEInvoicingLifecycleStatusText() {
    let descriptionConstant = this.isCreditNote
      ? E_CREDIT_NOTES_LIFECYCLE_EVENT_DESCRIPTION
      : EINVOICING_LIFECYCLE_EVENT_DESCRIPTION;
    let latestEventDescription = descriptionConstant(
      this.intl,
      this.frenchEinvoicingCurrentStatusCode
    );

    let htmlTemplate = `<div><span class="${styles['timeline-status-label']}">${this.intl.t('supplier-invoices.einvoicing-timeline.title')}</span><span class="ml-8">${latestEventDescription}</span></div>`;

    return htmlSafe(htmlTemplate);
  }

  get frenchEInvoicingLifecycleSteps() {
    let descriptionConstant = this.isCreditNote
      ? E_CREDIT_NOTES_LIFECYCLE_EVENT_DESCRIPTION
      : EINVOICING_LIFECYCLE_EVENT_DESCRIPTION;
    // @ts-expect-error
    return this.frenchEinvoicingEventsToDisplay.map((event, index) => {
      let description = descriptionConstant(this.intl, event.status_code);
      let timeFormat = this.localeManager.locale === 'en' ? 'hh:mm A' : 'HH:mm';
      let caption = dayjs(event.timestamp).format(`MMM DD, YYYY · ${timeFormat}`);
      let reason = event.reason;
      let comment = event.reason_message;
      let showPdpTooltip = description?.includes('PDP');
      let isLastEvent = index === this.frenchEinvoicingEventsToDisplay.length - 1;
      let stepIcon = this.frenchEInvoicingTimelineStepIcon(event.status_code, isLastEvent);

      return {
        icon: { type: stepIcon, filled: isLastEvent },
        description,
        caption,
        additionalInformation:
          (reason || comment) &&
          htmlSafe(`${
            reason
              ? this.intl.t('supplier-invoices.einvoicing-timeline.subtitle.reason', { reason })
              : ''
          }
          ${
            comment
              ? `${reason ? `<br/>` : ''}${this.intl.t('supplier-invoices.einvoicing-timeline.subtitle.comment', { comment })}`
              : ''
          }`),
        tooltipInfoMessage: showPdpTooltip
          ? this.intl.t('receivable-invoices.einvoicing-timeline.pdp-tooltip')
          : null,
        isLastEvent,
      };
    });
  }

  // @ts-expect-error
  frenchEInvoicingTimelineStepIcon(statusCode, isLastEvent) {
    let isfrenchEInvoicingWarningStatus =
      EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES.includes(statusCode);
    let isfrenchEInvoicingErrorStatus =
      EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES.includes(statusCode);

    if (isfrenchEInvoicingWarningStatus && isLastEvent) {
      return `${statusCode === 214 ? 'default-colour ' : ''}warning-filled`;
    }
    if (isfrenchEInvoicingWarningStatus) {
      return 'warning';
    }
    if (isLastEvent && !isfrenchEInvoicingErrorStatus) {
      return 'success';
    }
    if (isfrenchEInvoicingErrorStatus) {
      return 'reject';
    }

    return 'default';
  }

  @action
  trackTimelineOpened() {
    this.segment.track('invoice-timeline_drawer_opened', {
      source: 'AP',
      latest_status: this.frenchEinvoicingCurrentStatusCode,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::DetailsSidebar': typeof DetailsSidebarComponent;
  }
}
