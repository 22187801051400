/* import __COLOCATED_TEMPLATE__ from './physical-renew.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

interface TopbarCardsPhysicalRenewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopbarCardsPhysicalRenewComponent extends Component<TopbarCardsPhysicalRenewSignature> {
  topBanner = TopBanner;

  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  @action
  handleClick() {
    this.segment.track('cards_renewal_top_banner');
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::Cards::PhysicalRenew': typeof TopbarCardsPhysicalRenewComponent;
  }
}
