/* import __COLOCATED_TEMPLATE__ from './default-trigger.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

import type { FormFieldWithOptions } from 'qonto/components/transfers/international-out/dynamic-form/field/arguments';

interface Signature {
  Args: {
    selected?: FormFieldWithOptions['valuesAllowed'][number]['name'];
    placeholder?: string;
  };
  Element: HTMLDivElement;
}

const DefaultTrigger = templateOnlyComponent<Signature>();

export default DefaultTrigger;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::Select::DefaultTrigger': typeof DefaultTrigger;
  }
}
