export default {
  "modal": "qZr",
  "action-container": "qZb",
  "similar-label": "qZy",
  "info-icon": "qZU",
  "container": "qZj",
  "subtitle": "qZW caption-bold",
  "error": "qZu",
  "item-container": "qRS"
};
