/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

// @ts-expect-error
import { MIN_SAVINGS_ACCOUNT_AMOUNT } from 'qonto/constants/savings-account';

interface SavingsAccountsStepsDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SavingsAccountsStepsDetailsComponent extends Component<SavingsAccountsStepsDetailsSignature> {
  amountField = AmountField;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  @service declare organizationManager: Services['organizationManager'];
  @tracked amountErrorMessage = null;
  @tracked durationError = false;

  // @ts-expect-error
  @reads('organizationManager.organization.authorizedBalance') authorizedBalance;

  maturityTranslations = {
    // @ts-expect-error
    '6M': this.intl.t('accounts.savings.creation.details.duration-6'),
    // @ts-expect-error
    '12M': this.intl.t('accounts.savings.creation.details.duration-12'),
    // @ts-expect-error
    '18M': this.intl.t('accounts.savings.creation.details.duration-18'),
    // @ts-expect-error
    '24M': this.intl.t('accounts.savings.creation.details.duration-24'),
    // @ts-expect-error
    '36M': this.intl.t('accounts.savings.creation.details.duration-36'),
    // @ts-expect-error
    '60M': this.intl.t('accounts.savings.creation.details.duration-60'),
  };

  get durationOptions() {
    // @ts-expect-error
    return this.args.context.rates.map(({ nominalMaturity, nominalInterestRate }) => ({
      code: nominalMaturity,
      // @ts-expect-error
      label: this.maturityTranslations[nominalMaturity],
      ratio: this._formatRate(nominalInterestRate),
    }));
  }

  @action
  // @ts-expect-error
  handleUpdateAmount(value) {
    // @ts-expect-error
    this.args.context.updateAmount(value);
    this.validateAmountAuthorizedBalance();
  }

  @action
  // @ts-expect-error
  handleUpdateDurationOption(value) {
    // @ts-expect-error
    this.args.context.updateDurationOption(value);
    this.validateDurationExists();
  }

  get maxAmount() {
    // @ts-expect-error
    return this.args.context.information.maxAmountCents / 100 || 0;
  }

  @action
  handleNext() {
    let validAmount = this.validateAmount();
    this.validateDurationExists();
    let isValid = !this.durationError && validAmount;

    if (isValid) {
      this.segment.track('savings_amount_duration_cta');
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }

  validateDurationExists() {
    // @ts-expect-error
    this.durationError =
      // @ts-expect-error
      this.args.context.maturity === undefined
        ? this.intl.t('accounts.savings.creation.details.error.select-duration')
        : false;
  }

  validateAmountExists() {
    // @ts-expect-error
    if (!this.args.context.amount) {
      // @ts-expect-error
      this.amountErrorMessage = this.intl.t('accounts.savings.creation.details.error.amount-empty');
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountMinimum() {
    // @ts-expect-error
    if (Number(this.args.context.amount) < MIN_SAVINGS_ACCOUNT_AMOUNT) {
      // @ts-expect-error
      this.amountErrorMessage = this.intl.t(
        'accounts.savings.creation.details.error.amount-minimum'
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountMaximum() {
    // @ts-expect-error
    if (Number(this.args.context.amount) > this.maxAmount) {
      // @ts-expect-error
      this.amountErrorMessage = this.intl.t(
        'accounts.savings.creation.details.error.amount-maximum',
        { amount: this._formatPrice(this.maxAmount) }
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountAuthorizedBalance() {
    // @ts-expect-error
    if (Number(this.args.context.amount) > this.authorizedBalance) {
      // @ts-expect-error
      this.amountErrorMessage = this.intl.t(
        'accounts.savings.creation.details.error.amount-input',
        { amount: this._formatPrice(this.authorizedBalance) }
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmount() {
    return (
      this.validateAmountExists() &&
      this.validateAmountMinimum() &&
      this.validateAmountMaximum() &&
      this.validateAmountAuthorizedBalance()
    );
  }

  // @ts-expect-error
  _formatRate(rate) {
    return this.intl.formatNumber(rate / 100, {
      style: 'percent',
      minimumFractionDigits: 3,
    });
  }

  // @ts-expect-error
  _formatPrice(price) {
    return this.intl.formatNumber(price, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::Steps::Details': typeof SavingsAccountsStepsDetailsComponent;
  }
}
