/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarSignature {
  Element: null;
  Args: {
    isOpened?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const Sidebar = templateOnlyComponent<SidebarSignature>();

export default Sidebar;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Sidebar: typeof Sidebar;
  }
}
