export default {
  "header-cell": "qlR",
  "header-content": "qlQ caption-bold",
  "active": "qle",
  "align-right": "qlB",
  "empty": "qlE",
  "align-checkbox": "qld",
  "col-5": "qlI",
  "col-9": "qlt",
  "emitted-at-header-tag": "qlM"
};
