/* import __COLOCATED_TEMPLATE__ from './transfer-sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import type TransferModel from 'qonto/models/transfer';
import { ProcessingEventsTimeline } from 'qonto/react/components/transfers/processing-events-timeline';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { getScheduledTransferTimeline } from 'qonto/utils/transfers/scheduled-transfer-timeline';

interface TransferSidebarSignature {
  // The arguments accepted by the component
  Args: {
    transfer: TransferModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransferSidebarComponent extends Component<TransferSidebarSignature> {
  processingEventsTimeline = ProcessingEventsTimeline;

  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare store: Services['store'];

  // @ts-expect-error
  @tracked timeline;

  constructor(owner: unknown, args: TransferSidebarSignature['Args']) {
    super(owner, args);
    this.checkAndLoadTransferTimeline();
  }

  get transferCanBeEdited() {
    let { isStanding, isScheduled, isPending } = this.args.transfer;
    return (isStanding || isScheduled) && isPending;
  }

  get isUserKycSubmittedAndPending() {
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;
      return kycSubmitted && kycPending;
    }

    return false;
  }

  get isUserInitiator() {
    let { id: userId } = this.organizationManager.membership;
    let { id: initiatorId } = this.args.transfer.initiator;

    return userId === initiatorId;
  }

  get isScheduledWithValidStatus() {
    const { instant, isScheduled, isInternalTransfer, isProcessing, isValidated, isCompleted } =
      this.args.transfer;

    if (instant || isInternalTransfer || !isScheduled) {
      return false;
    }

    return isProcessing || isValidated || isCompleted;
  }

  @action
  checkAndLoadTransferTimeline() {
    const { isCanceled, isDeclined, isInternationalOut } = this.args.transfer;

    if (isCanceled || isDeclined) {
      return;
    }

    if (isInternationalOut) {
      // @ts-expect-error
      return this.loadTransferTimelineTask.perform(this.args.transfer).catch(ignoreCancelation);
    }

    if (this.isScheduledWithValidStatus) {
      this.loadScheduledTransferTimeline();
    }
  }

  get shouldDisplayKycDisclaimer() {
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;

      return !kycSubmitted || kycPending;
    }

    let initiator = this.findInitiatorMembership();

    if (initiator) {
      return !initiator.kycSubmitted || initiator.kycPending;
    }

    return false;
  }

  get shouldShowTimeline() {
    const {
      isInternationalOut,
      isInternationalScheduledOneOff,
      isInternationalRecurring,
      isCanceled,
      isDeclined,
    } = this.args.transfer;

    if (isCanceled || isDeclined || isInternationalScheduledOneOff || isInternationalRecurring) {
      return false;
    }

    return Boolean(isInternationalOut || this.isScheduledWithValidStatus);
  }

  get hasDisclaimer() {
    const { settlementDate } = this.args.transfer;
    return Boolean(this.isScheduledWithValidStatus && !settlementDate);
  }

  findInitiatorMembership() {
    let initiatorMembership = this.store.peekAll('membership').filter(({ id }) => {
      return this.args.transfer.initiator.id === id;
    });

    if (initiatorMembership.length > 0) {
      let [initiator] = initiatorMembership;
      return initiator;
    }
    return null;
  }

  loadTransferTimelineTask = dropTask(async ({ id }: { id: string }) => {
    if (!this.shouldShowTimeline) {
      return;
    }

    try {
      this.timeline = await this.internationalOutManager.getTransferTimeline(id);
    } catch {
      this.timeline = null;
    }
  });

  loadScheduledTransferTimeline() {
    this.timeline = getScheduledTransferTimeline(this.args.transfer);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TransferSidebar: typeof TransferSidebarComponent;
  }
}
