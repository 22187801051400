/* import __COLOCATED_TEMPLATE__ from './transition-modal.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface TransfersKYCIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersKYCIntroComponent extends Component<TransfersKYCIntroSignature> {
  lottiePlayer = LottiePlayer;

  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  animationReady = false;
  defaultLottieSrc = '/lotties/fourthline/intro.json';

  constructor(owner: unknown, args: TransfersKYCIntroSignature['Args']) {
    super(owner, args);

    this.segment.track('submit_kyc_information_viewed', {
      origin: 'transfer_flow',
    });

    assert(
      '<Transfers::Kyc::TransitionModal /> must have a @data.transfer',
      // @ts-expect-error
      this.args.data.transfer
    );
  }

  @action
  handleConfirm() {
    let { personalInfoProvided } = this.organizationManager.membership;
    if (personalInfoProvided) {
      this.router.transitionTo('kyc.start', {
        queryParams: {
          trackingEventOrigin: 'transfer_flow',
        },
      });
    } else {
      this.segment.track('submit_kyc_information_start');
      this.flowLinkManager.transitionTo({
        name: 'management-kyc',
        stepId: 'user-info',
      });
    }
    // @ts-expect-error
    this.args.close();
  }

  @action
  handleClose() {
    this.router.transitionTo('transfers.pending');
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Kyc::TransitionModal': typeof TransfersKYCIntroComponent;
  }
}
