/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

// @ts-expect-error
import { ensureSafeComponent } from '@embroider/util';
import { dropTask } from 'ember-concurrency';

interface TransfersRequestTableApproverRowSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
    isApproveCompactView?: boolean;
    isSidebarOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class TransfersRequestTableApproverRowComponent extends Component<TransfersRequestTableApproverRowSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get requestComponentName() {
    // @ts-expect-error
    let type = dasherize(this.args.item.requestType);
    return ensureSafeComponent(`request/table/cell/${type}`, this);
  }

  get isRequestedByCurrentUser() {
    let currentUserId = this.organizationManager.membership.id;
    // @ts-expect-error
    let initiatorId = this.args.item.get?.('initiator.id') || this.args.item.initiator.id;

    return currentUserId === initiatorId;
  }

  get negativeTooltipMessage() {
    return this.isRequestedByCurrentUser
      ? this.intl.t('btn.cancel_request')
      : this.intl.t('transfers.requests.quick-reject-tooltip');
  }

  get approveTooltipMessage() {
    // @ts-expect-error
    return this.args.item.lastStep
      ? this.intl.t('transfers.tooltip.pay-by-transfer')
      : this.intl.t('transfers.requests.quick-approve-tooltip');
  }

  // @ts-expect-error
  stopPropagation(event) {
    event.stopPropagation();
  }

  @action
  // @ts-expect-error
  onBankAccountSelect(account) {
    // @ts-expect-error
    this.args.onBankAccountSelect(this.args.item, account);
  }

  @action
  // @ts-expect-error
  onBankAccountInteraction(event) {
    this.segment.track('bank_account_picker_opened', {
      origin: 'transfers',
    });

    this.stopPropagation(event);
  }

  @action
  // @ts-expect-error
  onRowClick(id) {
    if (this.onTransferApprove.isRunning || this.onTransferReject.isRunning) {
      return;
    }

    // @ts-expect-error
    this.args.highlightItem(id);
  }

  onTransferApprove = dropTask(async () => {
    // @ts-expect-error
    await this.args.onTransferApprove(this.args.item);
  });

  onTransferReject = dropTask(async () => {
    // @ts-expect-error
    await this.args.onTransferReject(this.args.item);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::RequestTable::Approver::Row': typeof TransfersRequestTableApproverRowComponent;
  }
}
