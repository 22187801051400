/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Disclaimer } from '@repo/design-system-kit';
import { AmountsBreakdown } from '@repo/domain-kit/accounts-payable';
import dayjs from 'dayjs';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import {
  EDITABLE_DESCRIPTION_INVOICE_STATUSES,
  INVOICE_SOURCES,
  INVOICE_STATUSES,
  STATUS_COLORS,
} from 'qonto/constants/supplier-invoice';
// @ts-expect-error
import formats from 'qonto/formats';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { PayableAmountTooltip } from 'qonto/react/components/supplier-invoices/payable-amount-tooltip/payable-amount-tooltip.tsx';
import { StatusTag } from 'qonto/react/components/supplier-invoices/status-tag';

interface SupplierInvoicesDetailsSidebarHeaderSignature {
  // The arguments accepted by the component
  Args: {
    invoice?: SupplierInvoiceModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SupplierInvoicesDetailsSidebarHeaderComponent extends Component<SupplierInvoicesDetailsSidebarHeaderSignature> {
  badge = Badge;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  payableAmountTooltip = PayableAmountTooltip;
  statusTag = StatusTag;
  amountsBreakdown = AmountsBreakdown;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @tracked invoiceStatuses = INVOICE_STATUSES;

  get strikeTotalAmount() {
    return (
      Number(this.args.invoice?.payableAmount?.value) === 0 &&
      Number(this.args.invoice?.totalAmount?.value) !== 0 &&
      !this.args.invoice?.isCreditNote
    );
  }

  get amount() {
    let { value, currency } = this.args.invoice?.totalAmount || {};
    let { isCreditNote } = this.args.invoice || {};

    if (currency && value !== null && value !== undefined) {
      // @ts-expect-error
      return this.intl.formatMoney(value, {
        currency,
        signus: isCreditNote ? '-' : null,
      });
    }

    return `--\xA0${formats.number.EUR.currency}`;
  }

  get supplierName() {
    const { invoice } = this.args || {};
    return invoice?.supplierSnapshot?.name || invoice?.supplierName || '--';
  }

  get status() {
    let { invoice = {} } = this.args;
    // @ts-expect-error
    let { status, paymentDate, scheduledDate } = invoice;

    if (status === INVOICE_STATUSES.scheduled) {
      return {
        dateLabel: this.intl.t('supplier-invoices.preview.operation-date'),
        date: scheduledDate,
      };
    }
    if (status === INVOICE_STATUSES.paid) {
      return {
        display: this.intl.t('supplier-invoices.preview.status.paid'),
        dateLabel: this.intl.t('supplier-invoices.preview.payment-date'),
        color: STATUS_COLORS.paid,
        date: paymentDate,
      };
    }
  }

  get hasDescription() {
    return Boolean(this.args.invoice?.description?.trim());
  }

  get isOverdue() {
    let { dueDate, status } = this.args.invoice || {};
    let canOverdue = dueDate && status !== INVOICE_STATUSES.paid;

    return canOverdue ? dayjs().isAfter(dueDate) : false;
  }

  get showStatusDate() {
    return [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid].includes(this.args.invoice?.status);
  }

  get isCreditNote() {
    return this.args.invoice?.isCreditNote || false;
  }

  get shouldDisplayEditButton() {
    let canUpdateSupplierInvoices = this.abilities.can('update supplierInvoices');

    let hasEditableStatus = ![
      INVOICE_STATUSES.archived,
      INVOICE_STATUSES.paid,
      INVOICE_STATUSES.scheduled,
    ].includes(this.args.invoice?.status);

    let isNotElectronicInvoicing = Boolean(
      !this.args.invoice?.frenchEInvoicing && !this.args.invoice?.isGermanEInvoice
    );

    // Since the feature flag is now always enabled, we use the same condition for both credit notes and invoices
    let editConditions = hasEditableStatus && canUpdateSupplierInvoices && isNotElectronicInvoicing;

    return editConditions;
  }

  get showEditButton() {
    // We prioritise the `hideEditButton` prop ahead of the `shouldDisplayEditButton` because the `shouldDisplayEditButton` uses the status of the invoice to display the button while the prop doesn't care about the status - it just needs the button hidden.
    // @ts-expect-error
    return this.args.hideEditButton ? false : this.shouldDisplayEditButton;
  }

  get editButtonCopy() {
    return this.isCreditNote
      ? this.intl.t('supplier-invoices.edit-credit-note.cta')
      : this.intl.t('supplier-invoices.edit.cta');
  }

  get eInvoiceToolTipMessage() {
    let { organization } = this.organizationManager;
    switch (organization?.legalCountry) {
      case 'DE':
        return this.intl.t('supplier-invoices.preview.einvoice.tooltip');

      case 'FR':
        return this.intl.t('supplier-invoices.preview.supplier-einvoicing.tooltip');

      default:
        break;
    }
  }

  get isEinvoicingDescriptionFormShown() {
    return (
      (this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice) &&
      EDITABLE_DESCRIPTION_INVOICE_STATUSES.includes(this.args.invoice?.status)
    );
  }

  get isFrenchOrGermanEInvoicing() {
    let { organization } = this.organizationManager;
    switch (organization?.legalCountry) {
      case 'DE':
        return this.args.invoice?.isEinvoice;

      case 'FR':
        return this.args.invoice?.source === INVOICE_SOURCES.eInvoicing;

      default:
        return false;
    }
  }

  get documentNumberLabel() {
    let { isCreditNote } = this.args.invoice || {};
    return isCreditNote
      ? this.intl.t('supplier-invoices.preview.credit-note-number')
      : this.intl.t('supplier-invoices.preview.invoice-number');
  }

  get payableAmount() {
    let { payableAmount } = this.args.invoice || {};
    // @ts-expect-error
    return this.intl.formatMoney(payableAmount?.value, { currency: payableAmount?.currency });
  }

  get totalAmount() {
    let { totalAmount } = this.args.invoice || {};
    // @ts-expect-error
    return this.intl.formatMoney(totalAmount?.value, { currency: totalAmount?.currency });
  }

  get creditNotesAmount() {
    // @ts-expect-error
    let { relatedInvoices } = this.args.invoice || [];

    if (!relatedInvoices?.length) {
      return '';
    }

    let creditNotesSumUp = relatedInvoices
      // @ts-expect-error
      .map(invoice => parseFloat(invoice.total_amount?.value))
      // @ts-expect-error
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    // @ts-expect-error
    return this.intl.formatMoney(creditNotesSumUp, {
      currency: relatedInvoices[0]?.total_amount?.currency,
      signus: '-',
    });
  }

  get creditNotesCount() {
    // @ts-expect-error
    let { relatedInvoices } = this.args.invoice || [];

    return relatedInvoices?.length;
  }

  get showAmountsBreakdown() {
    return !this.args.invoice?.isCreditNote && this.args.invoice?.hasRelatedInvoices;
  }

  get amountsBreakdownItems() {
    return [
      {
        label: this.intl.t('supplier-invoices.preview.invoice-amount-vat'),
        amount: this.args.invoice?.totalAmount,
      },
      {
        label: this.intl.t('supplier-invoices.preview.credit-note-amount'),
        amount: this.args.invoice?.totalAmountCreditNotes,
        sign: '-',
      },
      {
        label: this.intl.t('supplier-invoices.preview.payable-amount'),
        amount: this.args.invoice?.payableAmount,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::DetailsSidebar::Header': typeof SupplierInvoicesDetailsSidebarHeaderComponent;
  }
}
