/* import __COLOCATED_TEMPLATE__ from './sidepanel.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { getFullCurrencyName } from 'qonto/utils/currency';

interface SupplierSidepanelSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierSidepanel extends Component<SupplierSidepanelSignature> {
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];

  numberOfLoadingRelatedInvoices = new Array(5);

  get relatedInvoices() {
    // @ts-expect-error
    return this.args.fetchRelatedInvoicesTask.lastSuccessful?.value || [];
  }

  get showRelatedInvoices() {
    return (
      // @ts-expect-error
      this.args.supplier.status === SUPPLIER_STATUSES.UNARCHIVED && this.relatedInvoices.length > 0
    );
  }

  get isLoading() {
    // @ts-expect-error
    return this.args.fetchRelatedInvoicesTask.isRunning;
  }

  get supplierAddress() {
    // @ts-expect-error
    let { supplier } = this.args;

    return [
      supplier.streetAddress,
      supplier.zipCode,
      supplier.city,
      // @ts-expect-error
      supplier.countryCode && this.intl.formatCountry(supplier.countryCode),
    ]
      .filter(Boolean)
      .join(', ');
  }

  get supplierDefaultVatRate() {
    // @ts-expect-error
    let { supplier } = this.args;

    if (!supplier.defaultVatRate) {
      return this.intl.t('supplier-invoices.supplier-creation.vat-rate.choice-chip.none');
    }
    return `${supplier.defaultVatRate}%`;
  }

  get supplierCurrency() {
    // @ts-expect-error
    let { supplier } = this.args;

    return getFullCurrencyName({
      currencyCode: supplier.currency,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
    });
  }

  @action archive() {
    // @ts-expect-error
    let { supplier } = this.args;
    this.modals.open('suppliers/archive-popup', { supplier });
  }

  @action unarchive() {
    // @ts-expect-error
    let { supplier } = this.args;
    this.modals.open('suppliers/unarchive-popup', { supplier });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Suppliers::Sidepanel': typeof SupplierSidepanel;
  }
}
