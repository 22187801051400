/* import __COLOCATED_TEMPLATE__ from './transactions-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask, task, timeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { DEFAULT_SEARCH_INCLUDES, LINKED_ATTACHMENTS_LIMIT } from 'qonto/constants/transactions';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import type TransactionModel from 'qonto/models/transaction';
import { InvoicePreview } from 'qonto/react/components/match-invoice/invoice-preview';
import { TransactionPlaceholder } from 'qonto/react/components/supplier-invoices/transactions-modal/transaction/placeholder';
import { Transaction } from 'qonto/react/components/supplier-invoices/transactions-modal/transaction/transaction';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface TransactionsModalSignature {
  // The arguments accepted by the component
  Args: {
    data: {
      invoice: SupplierInvoiceModel;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

interface TransactionTrackingParams {
  direction: 'credit' | 'debit';
  origin_type: 'credit_note' | 'invoice';
}

export default class TransactionsModalComponent extends Component<TransactionsModalSignature> {
  transaction = Transaction;
  transactionPlaceholder = TransactionPlaceholder;
  invoicePreview = InvoicePreview;
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  @tracked suggestedTransactions: TransactionModel[] = [];
  @tracked showOnlySuggestedTransactions: boolean = false;

  filterGroup = {
    conditional: 'and',
    expressions: this.filterGroupExpressions,
  };

  @action
  // @ts-expect-error
  updatePeriodSelection(period) {
    let indexToUpdate = this.filterGroup.expressions.findIndex(
      expression => expression.property === 'settled_at'
    );

    if (period) {
      let periodFilter = { property: 'settled_at' };

      switch (period.key) {
        case 'this_month':
          Object.assign(periodFilter, { values: ['current_month'], operator: 'interval' });
          break;
        case 'last_month':
          Object.assign(periodFilter, { values: ['last_month'], operator: 'interval' });
          break;
        case 'custom_period':
          Object.assign(periodFilter, {
            values: [
              dayjs(period.startDate).startOf('day').valueOf(),
              dayjs(period.endDate).endOf('day').valueOf(),
            ],
            operator: 'within',
          });
          break;
        default:
          return;
      }

      if (indexToUpdate !== -1) {
        // @ts-expect-error
        this.filterGroup.expressions[indexToUpdate] = periodFilter;
      } else {
        // @ts-expect-error
        this.filterGroup.expressions.push(periodFilter);
      }
    } else {
      this.filterGroup.expressions.splice(indexToUpdate, 1);
    }

    this.fetchTransactionsTask.perform().catch(ignoreCancelation);
  }

  get invoice() {
    return this.args.data.invoice;
  }

  get supplierName() {
    return this.invoice?.supplierSnapshot?.name ?? this.invoice?.fileName;
  }

  get titleText() {
    return this.isCreditNote
      ? this.intl.t('supplier-invoices.match-transaction-modal.credit-note-details')
      : this.intl.t('supplier-invoices.match-transaction-modal.invoice-details.title');
  }

  get isCreditNote() {
    return this.invoice?.isCreditNote || false;
  }

  get filterGroupExpressions() {
    let baseExpressions = [
      {
        property: 'attachment_ids',
        // @ts-expect-error
        values: [this.args.data.attachment.id],
        operator: 'not_in',
      },
      {
        property: 'status',
        values: ['completed'],
        operator: 'in',
      },
      {
        property: 'operation_method',
        values: ['pagopa_payment'],
        operator: 'not_in',
      },
    ];

    if (!this.isCreditNote) {
      baseExpressions.push({
        property: 'amount',
        values: ['0.00'],
        operator: 'lt',
      });
    }

    return baseExpressions;
  }

  get shouldFetchSuggestedTransactions() {
    return (
      this.invoice.suggestedTransactions.length && this.invoice.status === INVOICE_STATUSES.toReview
    );
  }

  get showSuggestedTransaction() {
    return this.shouldFetchSuggestedTransactions && this.suggestedTransactions.length;
  }

  get hasActivePeriodFilter() {
    return Boolean(
      this.filterGroup.expressions.find(expression => expression.property === 'settled_at')
    );
  }

  linkTransactionTask = dropTask(async transaction => {
    try {
      let transactionTrackingParams: TransactionTrackingParams = {
        direction: transaction.side,
        origin_type: this.invoice?.isCreditNote ? 'credit_note' : 'invoice',
      };

      // @ts-expect-error
      await transaction.linkAttachment([this.args.data.attachment]);
      await this.invoice.markAsPaid(dayjs(transaction.settledAt).format(DATE_PICKER_FIELD_FORMAT));
      this.segment.track('supplier-invoices_mark-as-paid_submitted', transactionTrackingParams);
      // @ts-expect-error
      this.args.close();
      // @ts-expect-error
      this.args.data.onCloseModal();
    } catch (error) {
      this._handleError(error);
    }
  });

  markAsPaidWithoutAttachmentTask = dropTask(async () => {
    try {
      await this.invoice.markAsPaid(dayjs().format(DATE_PICKER_FIELD_FORMAT));
      // @ts-expect-error
      this.args.close();
      // @ts-expect-error
      this.args.data.onCloseModal();
    } catch {
      // @ts-expect-error
      this._handleError();
    }
  });

  fetchTransactionsTask = task(this, { restartable: true }, async (searchQuery = '') => {
    await timeout(DEBOUNCE_MS);

    // @ts-expect-error
    let { transactions } = (await this.store.adapterFor('transaction').search({
      includes: DEFAULT_SEARCH_INCLUDES,
      filter_group: this.filterGroup,
      sort: { property: 'emitted_at', direction: 'desc' },
      pagination: { page: 1, per_page: 20 },
      search: searchQuery,
      organization_id: this.organizationManager.organization.id,
    })) as { transactions: TransactionModel[] };

    if (transactions.length) {
      let membershipsIds = transactions.map(transaction => transaction.belongsTo('initiator').id());

      await this.store.query('membership', {
        organization_id: this.organizationManager.organization.id,
        filters: { ids: membershipsIds },
        per_page: membershipsIds.length,
      });
    }

    if (this.shouldFetchSuggestedTransactions) {
      await this.fetchSuggestedTransactionsTask.perform().catch(ignoreCancelation);
    }

    let shouldFilterSuggestedTransactions = !searchQuery && !this.hasActivePeriodFilter;

    let allTransactions = shouldFilterSuggestedTransactions
      ? transactions.filter(
          (transaction: TransactionModel) =>
            !this.suggestedTransactions.find(({ id }) => id === transaction.id)
        )
      : transactions;

    let hasFilteredSuggestedTransactions = allTransactions.length !== transactions.length;

    this.showOnlySuggestedTransactions =
      hasFilteredSuggestedTransactions && allTransactions.length === 0;

    return allTransactions;
  });

  fetchSuggestedTransactionsTask = task(this, { restartable: true }, async () => {
    try {
      this.suggestedTransactions = await Promise.all(
        this.invoice.suggestedTransactions.map(({ id }) => {
          return this.store.findRecord('transaction', id);
        })
      );
    } catch (error) {
      this.sentry.captureException(error);
      this.suggestedTransactions = [];
    }
  });

  // @ts-expect-error
  _handleError(error) {
    // @ts-expect-error
    this.args.close();
    if (error?.status === 422) {
      this.toastFlashMessages.toastError(
        this.intl.t('receivable-invoices.match-transaction.error-toast', {
          invoiceLimit: LINKED_ATTACHMENTS_LIMIT,
        })
      );
    } else {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
    if (this.invoice.status !== INVOICE_STATUSES.paid) {
      this.router.transitionTo('supplier-invoices.index');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::TransactionsModal': typeof TransactionsModalComponent;
  }
}
