export default {
  "button-wrapper": "qtP",
  "btn-filter": "qtl",
  "name-wrapper": "qtX",
  "preset-name": "qtC",
  "cancel-placeholder": "qtk",
  "cancel-button": "qtJ",
  "dropdown-opened": "qts",
  "dropdown-closed": "qtF",
  "dropdown-menu": "qtg",
  "loading-item": "qtD",
  "placeholder": "qtV",
  "label": "qtL",
  "list-body": "qtT",
  "list-item": "qtz",
  "error-state": "qta body-2",
  "warning": "qtH",
  "warning-icon": "qtO",
  "footer": "qtm",
  "add-icon": "qtp",
  "empty-state": "qtx"
};
