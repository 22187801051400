export default {
  "insight-pill": "qZe",
  "interactive": "qZB",
  "focus": "qZE",
  "subtitle": "qZd caption",
  "hidden": "qZI",
  "missing-amounts-warning": "qZt",
  "totals": "qZM",
  "counter": "qZP title-3",
  "loading": "qZl",
  "error": "qZX",
  "title": "qZC title-4"
};
