/* import __COLOCATED_TEMPLATE__ from './schedule.hbs'; */
import Component from '@glimmer/component';

import { ScheduleForm } from '@repo/domain-kit/transfers';

interface Signature {
  Args: {
    data: {
      title: string;
    };
    close: () => void;
  };
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutModalsScheduleComponent extends Component<Signature> {
  scheduleForm = ScheduleForm;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Modals::Schedule': typeof TransfersInternationalOutModalsScheduleComponent;
  }
}
