export default {
  "wrapper": "qXd",
  "title": "qXI",
  "activity-tag": "qXt",
  "select": "qXM",
  "tooltip": "qXP",
  "date-picker": "qXl",
  "internal-notes": "qXX",
  "scheduled": "qXC",
  "payroll-selector-wrapper": "qXk"
};
