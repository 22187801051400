/* import __COLOCATED_TEMPLATE__ from './disclaimer.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { CARD_TRANSACTION_STATUS, STATUS } from 'qonto/constants/transactions';

interface TransactionsSidebarHeaderCardDisclaimerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSidebarHeaderCardDisclaimerComponent extends Component<TransactionsSidebarHeaderCardDisclaimerSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare intl: Services['intl'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  get disclaimerText() {
    // @ts-expect-error
    let { status, cardTransactionStatus } = this.args;

    switch (status) {
      case STATUS.COMPLETED:
        if (cardTransactionStatus === CARD_TRANSACTION_STATUS.PRE_AUTHORIZED_COMPLETED) {
          return this.intl.t('transactions.sidebar.card_disclaimers.completed.pre_authorized');
        }
        break;
      case STATUS.PENDING:
        return this.getPendingDisclaimers(cardTransactionStatus);

      case STATUS.REVERSED:
        return this.getRevertedDisclaimers(cardTransactionStatus);
    }
  }

  // @ts-expect-error
  getPendingDisclaimers(cardTransactionStatus) {
    switch (cardTransactionStatus) {
      case CARD_TRANSACTION_STATUS.PRE_AUTHORIZED_MORE_THAN_1000:
        return this.intl.t(
          'transactions.sidebar.card_disclaimers.pending.pre_authorized.more_than_1000.body',
          {
            // @ts-expect-error
            depositReleaseFaqLink: htmlSafe(
              `<a
                href=${this.zendeskLocalization.getLocalizedArticle(5165756)}
                target="_blank" rel="noopener noreferrer"
                data-test-more-1000-discalimer-link>
                ${this.intl.t(
                  'transactions.sidebar.card_disclaimers.pending.pre_authorized.more_than_1000.link'
                )}
              </a>`
            ),
            htmlSafe: true,
          }
        );

      case CARD_TRANSACTION_STATUS.PRE_AUTHORIZED_LESS_THAN_OR_EQUAL_TO_1000:
        return this.intl.t(
          'transactions.sidebar.card_disclaimers.pending.pre_authorized.less_than_or_equal_to_1000.body',
          {
            // @ts-expect-error
            merchantHoldFaqLink: htmlSafe(
              `<a
                href=${this.zendeskLocalization.getLocalizedArticle(5165756)}
                target="_blank" rel="noopener noreferrer"
                data-test-less-equal-1000-discalimer-link>
                ${this.intl.t(
                  'transactions.sidebar.card_disclaimers.pending.pre_authorized.less_than_or_equal_to_1000.link'
                )}
              </a>`
            ),
            htmlSafe: true,
          }
        );
      case CARD_TRANSACTION_STATUS.COMPLETED_ZERO:
        return this.intl.t('transactions.sidebar.card_disclaimers.pending.zero');

      default:
        return this.intl.t('transactions.sidebar.card_disclaimers.pending.default.body', {
          // @ts-expect-error
          processingTransactionFaqLink: htmlSafe(
            `<a
              href=${this.zendeskLocalization.getLocalizedArticle(4359569)}
              target="_blank" rel="noopener noreferrer"
              data-test-pending-default-discalimer-link>
              ${this.intl.t('transactions.sidebar.card_disclaimers.pending.default.link')}
            </a>`
          ),
          htmlSafe: true,
        });
    }
  }

  // @ts-expect-error
  getRevertedDisclaimers(cardTransactionStatus) {
    if (cardTransactionStatus === CARD_TRANSACTION_STATUS.CANCELED) {
      return this.intl.t('transactions.sidebar.card_disclaimers.reverted.canceled');
    } else {
      return this.intl.t('transactions.sidebar.card_disclaimers.reverted.default');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::Card::Disclaimer': typeof TransactionsSidebarHeaderCardDisclaimerComponent;
  }
}
