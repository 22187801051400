/* import __COLOCATED_TEMPLATE__ from './create-invoice-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ToggleButton } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

export const SWITCH_VALUES = {
  SUPPLIER_INVOICE: 'SUPPLIER_INVOICE',
  SELF_INVOICE: 'SELF_INVOICE',
};

interface SelfBillingCreateInvoiceModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SelfBillingCreateInvoiceModalComponent extends Component<SelfBillingCreateInvoiceModalSignature> {
  toggleButton = ToggleButton;

  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  @tracked activeSwitchItem = SWITCH_VALUES.SUPPLIER_INVOICE;

  SWITCH_VALUES = SWITCH_VALUES;
  switchItems = [
    {
      value: SWITCH_VALUES.SELF_INVOICE,
      // @ts-expect-error
      label: this.intl.t('self-billing.create.toggle.self-invoice'),
    },
    {
      value: SWITCH_VALUES.SUPPLIER_INVOICE,
      // @ts-expect-error
      label: this.intl.t('self-billing.create.toggle.supplier-invoice'),
    },
  ];

  get hideSelfInvoicePreview() {
    return this.activeSwitchItem === this.SWITCH_VALUES.SUPPLIER_INVOICE;
  }

  // @ts-expect-error
  @action togglePreview(state) {
    this.activeSwitchItem = state;
    this.segment.track('invoice_preview_switch');
  }

  confirmExitTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('self-billing.create.exit-modal.title'),
      description: this.intl.t('self-billing.create.exit-modal.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.exitTask,
    });
  });

  exitTask = task(async close => {
    await close();
    this.router.transitionTo('supplier-invoices.index');
    this.segment.track('self-invoice_creation_canceled');
  });

  confirmInvoiceCreationTask = dropTask(async () => {
    this.segment.track('self-invoice_creation_submitted');
    await this.modals.open('popup/confirmation', {
      title: this.intl.t('self-billing.create.confirm-modal.title'),
      description: this.intl.t('self-billing.create.confirm-modal.description', { htmlSafe: true }),
      confirm: this.intl.t('self-billing.create.confirm-modal.create-invoice'),
      cancel: this.intl.t('btn.cancel'),
      confirmTask: this.saveInvoiceTask,
    });
  });

  saveInvoiceTask = dropTask(async close => {
    try {
      // @ts-expect-error
      await this.args.data.selfInvoice.save();
      this.segment.track('self-invoice_creation_confirmed');
      // @ts-expect-error
      this.args.data.supplierInvoice.selfInvoiceId = true;
      // @ts-expect-error
      this.args.data.selfInvoice.clearItemsWithNoId();
      this.router.transitionTo('supplier-invoices.index');
      this.toastFlashMessages.toastSuccess(this.intl.t('self-billing.toast.creation-success'));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('self-billing.toast.creation-fail'));
    }
    await close();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::CreateInvoiceModal': typeof SelfBillingCreateInvoiceModalComponent;
  }
}
