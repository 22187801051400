export default {
  "transfer-header": "qPA",
  "infobox": "qPY header-section",
  "proof-section": "qPh",
  "proof-link": "qPf",
  "download-icon": "qPK",
  "cancel-button": "qPG",
  "request-refund-button": "qPr",
  "refund-requested-indicator": "qPb",
  "recall-indicator": "qPy",
  "payment-purpose": "qPU",
  "payment-purpose-label": "qPj",
  "repeat-infobox": "qPW qPY header-section",
  "additional-infobox": "qPu qPY header-section",
  "repeat-transfer-action": "qlS",
  "financing-not-compliant-disclaimer": "qlc"
};
