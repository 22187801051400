export default {
  "cell": "qQQ",
  "supplier-container": "qQe",
  "overflow": "qQB",
  "cell-label-container": "qQE",
  "cell-label": "qQd",
  "missing": "qQI",
  "flex-label": "qQt",
  "sub-label": "qQM body-2",
  "with-separator": "qQP",
  "file-name-warning": "qQl",
  "self-invoice-icon": "qQX"
};
