export default {
  "cell": "qRf",
  "supplier-container": "qRK",
  "cell-label-container": "qRG",
  "cell-label": "qRr",
  "overflow": "qRb",
  "sub-label": "qRy body-2",
  "overdue": "qRU",
  "file-name-warning": "qRj",
  "missing": "qRW",
  "pending-icon": "qRu",
  "icon": "qQS",
  "flex-label": "qQc",
  "with-separator": "qQq",
  "self-invoice-icon": "qQZ",
  "tooltip-wrapper": "qQR"
};
