/* import __COLOCATED_TEMPLATE__ from './account-information.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import { DEFAULT_LOCALE } from '@repo/shared-config/app/constants/locales';

import type { AccountInformation } from 'qonto/services/international-out/types';
import maybeT from 'qonto/utils/maybe-t';
import transformKeys from 'qonto/utils/transform-keys';

interface ReadableAccountInformation {
  key: string;
  label: string;
  value: NonNullable<AccountInformation[keyof AccountInformation]>;
}

interface Signature {
  Args: {
    counterpartyAccountInformation?: Partial<AccountInformation> | null;
  };
  Blocks: {
    default: [
      data: {
        accountInformation: ReadableAccountInformation[];
      },
    ];
  };
  Element: null;
}

export default class TransfersInternationalOutProvidersAccountInformationComponent extends Component<Signature> {
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];

  get #counterpartyAccountInformation(): Partial<AccountInformation> {
    return this.args.counterpartyAccountInformation || {};
  }

  get #formattedCounterpartyAccountInformation(): Partial<AccountInformation> {
    return transformKeys(this.#counterpartyAccountInformation);
  }

  get #accountType(): AccountInformation['accountType'] {
    return this.#formattedCounterpartyAccountInformation.accountType || 'unknown';
  }

  get #identifiers(): Omit<Partial<AccountInformation>, 'accountType'> {
    let { accountIdentifier, bankIdentifier, branchIdentifier } =
      this.#formattedCounterpartyAccountInformation;
    return {
      ...(accountIdentifier && { accountIdentifier }),
      ...(bankIdentifier && { bankIdentifier }),
      ...(branchIdentifier && { branchIdentifier }),
    };
  }

  get accountInformation(): ReadableAccountInformation[] {
    return Object.entries(this.#identifiers).reduce<ReadableAccountInformation[]>(
      (accumulator, [identifierKey, identifierValue]) => {
        if (identifierValue !== null) {
          let formattedIdentifierKey = dasherize(identifierKey);
          accumulator.push({
            key: formattedIdentifierKey,
            label: maybeT(
              {
                key: `international-out.account.label.${this.#accountType}.${formattedIdentifierKey}`,
                fallback: this.intl.t(
                  `international-out.account.label.generic.${formattedIdentifierKey}`
                ),
              },
              this.intl,
              this.localeManager.locale ?? DEFAULT_LOCALE
            ),
            value: identifierValue,
          });
        }
        return [...accumulator];
      },
      []
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Providers::AccountInformation': typeof TransfersInternationalOutProvidersAccountInformationComponent;
  }
}
