/* import __COLOCATED_TEMPLATE__ from './custom-rate.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface VatAccountingMultiCustomRateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class VatAccountingMultiCustomRateComponent extends Component<VatAccountingMultiCustomRateSignature> {
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked vatCustomRate;

  get isExistingRate() {
    // @ts-expect-error
    return this.args.transactionRates?.includes(parseFloat(this.vatCustomRate));
  }

  get errorMessage() {
    if (this.isExistingRate) {
      return this.intl.t('attachment-viewer.bookkeeping.vat-already-exists.error');
    }
  }

  @action
  confirm() {
    // @ts-expect-error
    this.args.onConfirm({ value: parseFloat(this.vatCustomRate) });
  }

  @action
  cancel() {
    // @ts-expect-error
    this.args.onCancel();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'VatAccounting::Multi::CustomRate': typeof VatAccountingMultiCustomRateComponent;
  }
}
