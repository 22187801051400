/* import __COLOCATED_TEMPLATE__ from './invoices-list.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_HTTP_STATUSES = [401];

interface SelfBillingInvoiceListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SelfBillingInvoiceListComponent extends Component<SelfBillingInvoiceListSignature> {
  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare router: Services['router'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare errors: Services['errors'];

  get selfInvoices() {
    // @ts-expect-error
    return !this.isLoading && this.args.fetchSelfInvoicesTask.last.value;
  }

  get isLoading() {
    // @ts-expect-error
    return this.args.fetchSelfInvoicesTask.isRunning;
  }

  createSelfInvoiceTask = dropTask(async () => {
    try {
      this.segment.track('self-invoice_creation_started');
      let canCreate = this.abilities.can('create selfInvoice');

      if (canCreate) {
        // @ts-expect-error
        let supplierInvoiceId = this.args.supplierInvoice.id;
        // @ts-expect-error
        let organizationId = this.args.supplierInvoice.belongsTo('organization').id();

        let eInvoiceActivation = await this.store.findRecord(
          'e-invoice-activation',
          organizationId
        );

        if (eInvoiceActivation.eInvoicingActivated) {
          this.router.transitionTo('self-billing.new', {
            queryParams: {
              supplierInvoiceId,
            },
          });
        } else {
          this.flowLinkManager.replaceWith({
            name: 'invoice-onboarding',
            stepId: 'einvoice-activation',
            queryParams: { supplierInvoiceId },
          });
        }
      }
    } catch (error) {
      this.handleErrors(error);
    }
  });

  // @ts-expect-error
  handleErrors(error) {
    if (
      ErrorInfo.for(error).shouldSendToSentry &&
      !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
    ) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(
      this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::InvoicesList': typeof SelfBillingInvoiceListComponent;
  }
}
