/* import __COLOCATED_TEMPLATE__ from './bookkeeping-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';

// @ts-expect-error
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';

interface TransfersSepaTransferFormBookkeepingSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransfersSepaTransferFormBookkeepingSelectorComponent extends Component<TransfersSepaTransferFormBookkeepingSelectorSignature> {
  textAreaField = TextAreaField;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];

  @tracked checked = this.isNotEmpty;

  constructor(owner: unknown, args: TransfersSepaTransferFormBookkeepingSelectorSignature['Args']) {
    super(owner, args);
    this._onCheckedStatusUpdate();
  }

  get isActive() {
    return this.checked || this.isNotEmpty;
  }

  get displayCustomLabels() {
    let accessCustomLabels = this.abilities.can('access custom-label');
    let readCustomLabels = this.abilities.can('read custom-label');
    return accessCustomLabels && readCustomLabels;
  }

  get displayVat() {
    return this.abilities.can('viewVat bookkeeping');
  }

  get title() {
    return this.displayVat || this.displayCustomLabels
      ? this.intl.t('transfers.new.optional-settings.bookkeeping.title')
      : this.intl.t('transfers.new.optional-settings.additional-note.title');
  }

  get subtitle() {
    return this.displayVat || this.displayCustomLabels
      ? this.intl.t('transfers.new.optional-settings.bookkeeping.subtitle')
      : this.intl.t('transfers.new.optional-settings.additional-note.subtitle');
  }

  get isNotEmpty() {
    // @ts-expect-error
    let { note, labels, vatRate } = this.args.transfer;
    let hasNote = Boolean(note);
    let hasCustomLabel = Boolean(labels.length > 0);
    let hasVat = vatRate !== null && vatRate !== undefined;

    return hasNote || hasCustomLabel || hasVat;
  }

  @action
  // @ts-expect-error
  handleLabelSelect(labelList, label) {
    // @ts-expect-error
    let { transfer } = this.args;
    transfer.labels = getLabelsToUpdate(transfer.labels, labelList, label);
    return transfer;
  }

  // Disabled rule because using .then in `handleSaveVatAmount`
  // @ts-expect-error
  // eslint-disable-next-line require-await
  async handleSelectVat(model, value) {
    model.setProperties(value);
    return model;
  }

  @action
  toggleSelector() {
    this.checked = !this.checked;

    if (!this.checked) {
      // @ts-expect-error
      let { transfer } = this.args;
      transfer.setProperties({ note: undefined, vatAmount: undefined, vatRate: undefined });
      transfer.labels = [];
    }

    this._onCheckedStatusUpdate();
  }

  _onCheckedStatusUpdate() {
    // @ts-expect-error
    this.args.onSelect?.(this.isActive);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::TransferForm::BookkeepingSelector': typeof TransfersSepaTransferFormBookkeepingSelectorComponent;
  }
}
