/* import __COLOCATED_TEMPLATE__ from './text.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import type { FormFieldArgs } from 'qonto/components/transfers/international-out/dynamic-form/field/arguments';
import isFunction from 'qonto/utils/is-function';

interface Signature {
  Args: FormFieldArgs;
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutDynamicFormFieldTextComponent extends Component<Signature> {
  get #shouldRefreshOnChange(): boolean {
    const { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onFocusOut(): void {
    const { field, onFocusOut, onRefresh } = this.args;

    onFocusOut(field.key);

    if (this.#shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::Text': typeof TransfersInternationalOutDynamicFormFieldTextComponent;
  }
}
