/* import __COLOCATED_TEMPLATE__ from './update-password-form.hbs'; */
import Component from '@glimmer/component';

interface UpdatePasswordFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class UpdatePasswordForm extends Component<UpdatePasswordFormSignature> {
  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    // if the store is destorying or destroyed we can skip cleanup
    // otherwise we will get an error in ember data 4.7 in integration tests:
    // "A record in a disconnected state cannot utilize the store."
    // @ts-expect-error
    if (this.args.user.isDestroying || this.args.user.isDestroyed) return;

    // @ts-expect-error
    this.args.user.rollbackAttributes();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UpdatePasswordForm: typeof UpdatePasswordForm;
  }
}
