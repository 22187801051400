/* import __COLOCATED_TEMPLATE__ from './account-limit-reached.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AccountLimitReachedSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AccountLimitReached = templateOnlyComponent<AccountLimitReachedSignature>();

export default AccountLimitReached;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::AccountLimitReached': typeof AccountLimitReached;
  }
}
