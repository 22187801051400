/* import __COLOCATED_TEMPLATE__ from './fees.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

interface TransactionsSidebarHeaderBaseFeesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSidebarHeaderBaseFees extends Component<TransactionsSidebarHeaderBaseFeesSignature> {
  amount = Amount;

  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  Counterparty = Counterparty;
  Date = Date;
  type = Type;

  get showAccountName() {
    // @ts-expect-error
    let { showAccountName, highlightedItem: transaction } = this.args;

    return showAccountName || transaction.subject.get('isSDDCollectionFee');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::Fees': typeof TransactionsSidebarHeaderBaseFees;
  }
}
