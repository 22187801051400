export default {
  "row": "qlf",
  "col": "qlK",
  "header": "qlG",
  "block": "qlr",
  "col-5": "qlb",
  "col-6": "qly",
  "col-9": "qlU",
  "col-12": "qlj",
  "hide": "qlW"
};
