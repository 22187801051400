export default {
  "item": "qRc",
  "checkbox": "qRq",
  "details": "qRZ",
  "flex": "qRR",
  "header": "qRQ qRR",
  "flex-no-gap": "qRe qRR",
  "file-name": "qRB",
  "label": "qRE body-2",
  "self-icon": "qRd",
  "einvoice-icon": "qRI",
  "type-value": "qRt",
  "with-separator": "qRM",
  "dimmed": "qRP"
};
