/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface TabPageItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TabPageItem extends Component<TabPageItemSignature> {
  @service declare keyboardFocus: Services['keyboardFocus'];

  @tracked focused = false;

  // @ts-expect-error
  @action setFocused(focused) {
    this.focused = focused;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TabsPage::Item': typeof TabPageItem;
  }
}
