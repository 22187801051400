export default {
  "supplier-selector-wrapper": "qqN",
  "select-supplier": "qqv",
  "dropdown": "qqo",
  "iban": "qqn",
  "card": "qqi body-2",
  "card-content": "qqA",
  "add-btn": "qqY btn btn--square btn--icon-only",
  "archived-card": "qqh",
  "title": "qqf body-1",
  "archived-title": "qqK",
  "edit-supplier-tooltip": "qqG",
  "add-supplier": "qqr btn btn--tertiary",
  "error": "qqb caption",
  "form-title": "qqy title2",
  "dropdown-menu": "qqU",
  "menu-item": "qqj body-2 dropdown-overlay",
  "disabled": "qqW",
  "iban-error": "qqu"
};
