/* import __COLOCATED_TEMPLATE__ from './notes.hbs'; */
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TextAreaField } from '@repo/design-system-kit';

interface TransactionsSidebarNotesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransactionsSidebarNotesComponent extends Component<TransactionsSidebarNotesSignature> {
  textAreaField = TextAreaField;

  DEBOUNCE_MS = 500;

  @service declare segment: Services['segment'];
  @service declare store: Services['store'];

  get message() {
    // @ts-expect-error
    return this.args.transaction?.note || '';
  }

  @action
  // @ts-expect-error
  updateNoteValue({ value, id }) {
    // @ts-expect-error
    let { transaction } = this.args;
    this.segment.track('transaction_analytic_additional_information_entered', {
      // @ts-expect-error
      source: this.args.type,
    });
    if (id !== transaction.id) {
      transaction = this.store.peekRecord('transaction', id);
    }
    // @ts-expect-error
    this.args.saveTransaction(transaction, 'note', value);
  }

  @action
  // @ts-expect-error
  debouncedUpdateNoteValue(value) {
    // @ts-expect-error
    debounce(this, this.updateNoteValue, { value, id: this.args.transaction.id }, this.DEBOUNCE_MS);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Notes': typeof TransactionsSidebarNotesComponent;
  }
}
