/* import __COLOCATED_TEMPLATE__ from './virtual-card.hbs'; */
import Component from '@glimmer/component';

import { FlatAssets } from '@repo/cards-kit/assets';

interface VirtualCardSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class VirtualCard extends Component<VirtualCardSignature> {
  virtualCardAsset = FlatAssets.CardVirtualDefault2017Flat;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Cell::VirtualCard': typeof VirtualCard;
  }
}
