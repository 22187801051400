/* import __COLOCATED_TEMPLATE__ from './items.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { defaultValues } from 'qonto/models/receivable-invoice/item';

interface SelfBillingFormItemsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class SelfBillingFormItemsComponent extends Component<SelfBillingFormItemsSignature> {
  @action
  // @ts-expect-error
  addItem(invoice) {
    // @ts-expect-error
    invoice.items.createRecord(defaultValues(this, invoice.items[0].vatRate));
  }

  @action
  // @ts-expect-error
  deleteItem(item) {
    if (item.isNew) {
      item.deleteRecord();
    } else {
      item.unloadRecord();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::Form::Items': typeof SelfBillingFormItemsComponent;
  }
}
