/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

// @ts-expect-error
import { ensureSafeComponent } from '@embroider/util';
import { Tag } from '@repo/design-system-kit';

import { REQUEST_STATUS_COLORS, type StatusType } from 'qonto/constants/requests';

interface TransfersRequestTableRequesterRowSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
    isSidebarOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class TransfersRequestTableRequesterRowComponent extends Component<TransfersRequestTableRequesterRowSignature> {
  tag = Tag;

  get status() {
    // @ts-expect-error
    let { status, displayedStatus } = this.args.item;

    return {
      displayedStatus,
      color: REQUEST_STATUS_COLORS[status as StatusType],
    };
  }

  get requestComponentName() {
    // @ts-expect-error
    let type = dasherize(this.args.item.requestType);
    return ensureSafeComponent(`request/table/cell/${type}`, this);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::RequestTable::Requester::Row': typeof TransfersRequestTableRequesterRowComponent;
  }
}
