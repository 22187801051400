/* import __COLOCATED_TEMPLATE__ from './supplier-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import italianProvinces from 'qonto/constants/it-provinces';

const DEFAULT_VAT_OPTIONS = {
  FR: [undefined, 0, 2.1, 5.5, 10, 20],
  IT: [undefined, 0, 4, 5, 10, 22],
  ES: [undefined, 0, 4, 10, 21],
  DE: [undefined, 0, 7, 19],
  AT: [undefined, 0, 7, 10, 13, 19, 20],
  BE: [undefined, 0, 6, 12, 21],
  NL: [undefined, 0, 9, 21],
  PT: [undefined, 0, 6, 13, 23],
};

interface SupplierModalSignature {
  // The arguments accepted by the component
  Args: {
    data: {
      supplier: {
        iban: string;
      };
      invoiceCurrency: string;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierModal extends Component<SupplierModalSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare router: Services['router'];

  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  get vatOptions() {
    let organizationCountryCode = this.organizationManager.organization.legalCountry;
    // @ts-expect-error
    return DEFAULT_VAT_OPTIONS[organizationCountryCode] || DEFAULT_VAT_OPTIONS.FR;
  }

  get errorMessages() {
    // @ts-expect-error
    return this.args.data.supplier.errors.messages;
  }

  get hasSupplierNameError() {
    // @ts-expect-error
    return this.errorMessages && this.args.data.supplier.errors.has('/supplier/name');
  }

  get hasSupplierIbanError() {
    // @ts-expect-error
    return this.errorMessages && this.args.data.supplier.errors.has('/supplier/iban');
  }

  get hasSupplierEmailError() {
    // @ts-expect-error
    return this.errorMessages && this.args.data.supplier.errors.has('/supplier/email');
  }

  get countryCode() {
    // @ts-expect-error
    let { countryCode } = this.args.data.supplier;

    return countryCode || this.organizationManager.organization.legalCountry;
  }

  @cached
  get provinces() {
    // @ts-expect-error
    return italianProvinces.map(key => ({
      key,
      label: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  get selectedProvince() {
    // @ts-expect-error
    return this.provinces.find(province => province.key === this.args.data.supplier.provinceCode);
  }

  get showMissingIbanDisclaimer() {
    if (!variation('feature--boolean-improve-invoice-payment-flows')) return false;
    let missingIban = !this.args.data.supplier.iban;
    let invoiceCurrencyIsEUR = this.args.data.invoiceCurrency === 'EUR';
    return missingIban && invoiceCurrencyIsEUR;
  }

  /**
   * This function is required in order to reset or keep the selected
   * defaultVatRate in case the user switches the supplier country
   * after having already set the defaultVatRate.
   */
  // @ts-expect-error
  updateDefaultVat(country) {
    let vatCountries = Object.keys(DEFAULT_VAT_OPTIONS);

    // Remove FR from array
    vatCountries.shift();

    let isFrenchToUnknownCountry = this.countryCode === 'FR' && !vatCountries.includes(country);
    let isUnknownCountryToFrench = !vatCountries.includes(this.countryCode) && country === 'FR';

    if (!isFrenchToUnknownCountry && !isUnknownCountryToFrench) {
      this.selectDefaultVatRate({ value: undefined });
    }
  }

  @action
  close() {
    this.modals.open('popup/confirmation', {
      title: this.intl.t('supplier-invoices.supplier-creation.exit-modal.title'),
      description: this.intl.t('supplier-invoices.supplier-creation.exit-modal.subtitle'),
      cancel: this.intl.t('supplier-invoices.supplier-creation.exit-modal.cancel.cta'),
      confirm: this.intl.t('supplier-invoices.supplier-creation.exit-modal.leave.cta'),
      confirmTask: this.closeTask,
    });
  }

  closeTask = dropTask(async close => {
    // @ts-expect-error
    this.args.data.supplier.rollbackAttributes();
    close();
    // @ts-expect-error
    if (this.args.data.referrerSupplierInvoiceId) {
      await this.router.transitionTo(
        'supplier-invoices.show',
        // @ts-expect-error
        this.args.data.referrerSupplierInvoiceId
      );
      return;
    }

    await this.router.transitionTo('suppliers.index');
  });

  @action
  // @ts-expect-error
  updateSupplierName(name) {
    // @ts-expect-error
    this.args.data.supplier.name = name;
    // @ts-expect-error
    this.args.data.supplier.errors.remove('/supplier/name');
  }

  @action
  // @ts-expect-error
  updateEmailAddress(email) {
    // @ts-expect-error
    this.args.data.supplier.email = email;
    // @ts-expect-error
    this.args.data.supplier.errors.remove('/supplier/email');
  }

  @action
  // @ts-expect-error
  updateIban(iban) {
    this.args.data.supplier.iban = iban;
    // @ts-expect-error
    this.args.data.supplier.errors.remove('/supplier/iban');
  }

  @action
  // @ts-expect-error
  updateCountryCode(country) {
    let { supplier } = this.args.data;
    // @ts-expect-error
    supplier.countryCode = country;
  }

  @action
  // @ts-expect-error
  selectDefaultVatRate({ value }) {
    // @ts-expect-error
    this.args.data.supplier.defaultVatRate = value;
  }

  @action
  // @ts-expect-error
  chipText(value) {
    if (typeof value === 'undefined') {
      return this.intl.t('supplier-invoices.supplier-creation.vat-rate.choice-chip.none');
    }

    return String(value).concat('%');
  }

  @action
  // @ts-expect-error
  updateProvince({ key }) {
    // @ts-expect-error
    this.args.data.supplier.provinceCode = key;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Suppliers::SupplierModal': typeof SupplierModal;
  }
}
