/* import __COLOCATED_TEMPLATE__ from './renewal-poi-required-restricted.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

interface TopbarRenewalPoiRequiredRestrictedSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopbarRenewalPoiRequiredRestrictedComponent extends Component<TopbarRenewalPoiRequiredRestrictedSignature> {
  topBanner = TopBanner;

  @service declare router: Services['router'];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::RenewalPoiRequiredRestricted': typeof TopbarRenewalPoiRequiredRestrictedComponent;
  }
}
