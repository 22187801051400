/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */

import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { and, reads } from 'macro-decorators';

import { FINANCING_TRANSFER_DECLINED_REASON } from 'qonto/constants/financing';
import { ROLES } from 'qonto/constants/membership';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import {
  FIXED_AMOUNT_TYPES,
  OPERATION_TYPES,
  STATUS,
  TRANSFER_DECLINED_REASONS,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import { formatMoney } from 'qonto/helpers/format/money';
import type TransferModel from 'qonto/models/transfer';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';

interface TransferSidebarHeaderSignature {
  // The arguments accepted by the component
  Args: {
    isUserInitiator?: boolean;
    isUserKycSubmittedAndPending?: boolean;
    transfer: TransferModel;
    shouldShowTimeline?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransferSidebarHeaderComponent extends Component<TransferSidebarHeaderSignature> {
  statusAvatar = StatusAvatar;

  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare abilities: Services['abilities'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  @service declare router: Services['router'];
  @service declare featuresManager: Services['featuresManager'];

  @reads('organizationManager.organization.remuneratedAccounts')
  // @ts-expect-error
  remuneratedAccounts;

  @reads('args.transfer.initiator')
  // @ts-expect-error
  membership;

  @and(
    'args.transfer.bankAccount.name',
    'args.transfer.bankAccount.organization.hasMultipleBankAccounts'
  )
  // @ts-expect-error
  showAccountName;

  Disclaimer: typeof Disclaimer.Block = Disclaimer.Block;

  get isPastTransfer() {
    let { status, transaction } = this.args.transfer;

    const pastTransferStatus = [
      STATUS.ACCOUNT_CANCELED,
      STATUS.BENEFICIARY_ACCOUNT_CANCELED,
      STATUS.CANCELED,
      STATUS.COMPLETED,
      STATUS.DECLINED,
    ] as const;

    return pastTransferStatus.includes(status) && transaction;
  }

  get nextRecursionDate() {
    let { scheduled, isDeclined, isCanceled, isStanding } = this.args.transfer;

    if (scheduled && !isDeclined && !isCanceled && isStanding) {
      return this.args.transfer.nextRecursionDate;
    }
  }

  get isRemuneratedBeneficiary() {
    return this.remuneratedAccounts?.some(
      // @ts-expect-error
      remuneratedAccount => remuneratedAccount.id === this.args.transfer.creditBankAccountId
    );
  }

  get isPersonalInfoProvided() {
    return this.organizationManager.membership.personalInfoProvided;
  }

  get transferHeaderTypeWording() {
    const {
      status,
      operationType,
      isInternationalOut,
      isInternationalScheduledOneOff,
      isInternationalRecurring,
    } = this.args.transfer;

    switch (status) {
      case STATUS.PROCESSING:
      case STATUS.PENDING_REVIEW:
      case STATUS.PENDING_SEIZURE:
        return this.intl.t(
          isInternationalOut
            ? 'transfers.sidebar.header.international-out.in_progress'
            : 'transfers.sidebar.header.in_progress'
        );

      case STATUS.KYB_ONHOLD:
        return this.intl.t(
          isInternationalOut
            ? 'transfers.sidebar.header.international-out.pending'
            : 'transfers.sidebar.header.pending'
        );

      case STATUS.PENDING:
      case STATUS.STANDING_PROCESSING:
        if (isInternationalOut) {
          if (isInternationalScheduledOneOff) {
            return this.intl.t('transfers.sidebar.header.international-out.scheduled');
          } else if (isInternationalRecurring) {
            return this.intl.t('transfers.sidebar.header.international-out.recurring');
          } else {
            return this.intl.t('transfers.sidebar.header.international-out.pending');
          }
        }

        if (
          [
            SCHEDULE_OPERATION_TYPES.WEEKLY,
            SCHEDULE_OPERATION_TYPES.MONTHLY,
            SCHEDULE_OPERATION_TYPES.QUARTERLY,
            SCHEDULE_OPERATION_TYPES.HALF_YEARLY,
            SCHEDULE_OPERATION_TYPES.YEARLY,
          ].includes(operationType)
        ) {
          return this.intl.t('transfers.sidebar.header.recurring');
        }

        if (operationType === SCHEDULE_OPERATION_TYPES.SCHEDULED) {
          return this.intl.t('transfers.sidebar.header.scheduled');
        }

        return this.intl.t('transfers.sidebar.header.pending');

      case STATUS.COMPLETED:
        return this.intl.t(
          isInternationalOut
            ? 'transfers.sidebar.header.international-out.completed'
            : 'transfers.sidebar.header.completed'
        );

      case STATUS.DECLINED:
      case STATUS.BENEFICIARY_ACCOUNT_CANCELED:
        return this.intl.t(
          isInternationalOut
            ? 'transfers.sidebar.header.international-out.declined'
            : 'transfers.sidebar.header.declined'
        );

      case STATUS.CANCELED:
      case STATUS.ACCOUNT_CANCELED:
        return this.intl.t(
          isInternationalOut
            ? 'transfers.sidebar.header.international-out.canceled'
            : 'transfers.sidebar.header.canceled'
        );

      default:
        if (operationType === SCHEDULE_OPERATION_TYPES.SCHEDULED) {
          return this.intl.t('transfers.sidebar.header.scheduled');
        }

        return this.intl.t('transfers.sidebar.header.transfer');
    }
  }

  get statusClassName() {
    let { status } = this.args.transfer;
    let classNames = {
      declined: 'error',
      canceled: 'error',
      completed: 'success',
    };

    return classNames[status as keyof typeof classNames];
  }

  get shouldDisplayRepeatTransfer() {
    return this.abilities.can('create transfer') && this.args.transfer.isRepeatable;
  }

  get hasValidDeclinedReason() {
    let declinedReason = this.args.transfer.declinedReason;
    return declinedReason && Object.values(TRANSFER_DECLINED_REASONS).includes(declinedReason);
  }

  get declinedReason() {
    switch (this.args.transfer.declinedReason) {
      case TRANSFER_DECLINED_REASONS.ACCOUNT_UNREACHABLE:
        return this.intl.t('transfers.declined-reasons.unreachable-account');

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_ALREADY_USED_BY_ANOTHER:
        return this.intl.t('transfers.declined-reasons.beneficiary-already-used-by-another', {
          // @ts-expect-error
          organizationSlug: this.organizationSlug,
          htmlSafe: true,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(4359541),
        });

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_BIC_INVALID:
        return this.intl.t('transfers.declined-reasons.beneficiary-bic-invalid');

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_IBAN_INVALID:
        return this.intl.t('transfers.declined-reasons.beneficiary-iban-invalid');

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_CANCELED:
      case TRANSFER_DECLINED_REASONS.INST_BENEFICIARY_NOT_SEPA:
      case TRANSFER_DECLINED_REASONS.INVALID_BENEFICIARY_DATA:
        return this.intl.t('transfers.declined-reasons.invalid-beneficiary');

      case TRANSFER_DECLINED_REASONS.INST_BENEFICIARY_INVALID:
        return this.intl.t('transfers.declined-reasons.instant-invalid-beneficiary');

      case TRANSFER_DECLINED_REASONS.BENEFICIARY_NOT_SEPA:
        return this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.body', {
          // @ts-expect-error
          transferFaqLink: htmlSafe(
            `<a
              href="${this.zendeskLocalization.getLocalizedArticle(4359546)}"
              target="_blank"
              rel="noopener noreferrer"
              data-test-transfer-sidebar-declined-reason-link
            >${this.intl.t('transfers.declined-reasons.beneficiary-not-sepa.link-text')}</a>`
          ),
          htmlSafe: true,
        });

      case TRANSFER_DECLINED_REASONS.CREDITOR_TIMEOUT:
      case TRANSFER_DECLINED_REASONS.FORMAT_REJECTED:
      case TRANSFER_DECLINED_REASONS.PAYMENT_FAILED:
      case TRANSFER_DECLINED_REASONS.PROCESSING_ERROR:
        return this.intl.t('transfers.declined-reasons.processing-error');

      case TRANSFER_DECLINED_REASONS.OPERATIONAL_ERROR:
      case TRANSFER_DECLINED_REASONS.DISPOSITION_ERROR:
      case TRANSFER_DECLINED_REASONS.SCREENING_REJECTED:
        return this.intl.t('transfers.declined-reasons.regulatory-error');
      case TRANSFER_DECLINED_REASONS.INST_PROCESSING_ERROR:
        return this.intl.t('transfers.declined-reasons.account-type-not-supported');

      case TRANSFER_DECLINED_REASONS.FX_BENEFICIARY_INVALID:
        return this.intl.t('transfers.declined-reasons.fx-beneficiary-invalid');

      case TRANSFER_DECLINED_REASONS.INCOHERENT_KYC_LEVEL:
        return this.intl.t('transfers.declined-reasons.incoherent-kyc-level', {
          // @ts-expect-error
          organizationSlug: this.organizationSlug,
          htmlSafe: true,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(4359541),
        });

      case TRANSFER_DECLINED_REASONS.INSUFFICIENT_FUNDS:
        if (this.organizationManager.membership.role === ROLES.MANAGER) {
          return this.intl.t('transfers.declined-reasons.manager.insufficient-funds.body', {
            // @ts-expect-error
            topUpFaqLink: htmlSafe(
              `<a
                href="${this.zendeskLocalization.getLocalizedArticle(4359537)}"
                target="_blank"
                rel="noopener noreferrer"
                data-test-transfer-sidebar-declined-reason-link
              >${this.intl.t(
                'transfers.declined-reasons.manager.insufficient-funds.link-text'
              )}</a>`
            ),
            htmlSafe: true,
          });
        }
        return this.intl.t('transfers.declined-reasons.insufficient-funds', {
          htmlSafe: true,
        });

      case TRANSFER_DECLINED_REASONS.KYC_LEVEL_REFUSED:
        return this.intl.t('transfers.declined-reasons.kyc-level-refused', {
          // @ts-expect-error
          organizationSlug: this.organizationSlug,
          htmlSafe: true,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(4359541),
        });

      case TRANSFER_DECLINED_REASONS.USER_ASSETS_FROZEN:
        return this.intl.t('transfers.declined-reasons.user-assets-frozen', {
          // @ts-expect-error
          organizationSlug: this.organizationSlug,
          htmlSafe: true,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(4359541),
        });
    }
  }

  get disclaimerLevel() {
    let { isUserInitiator, isUserKycSubmittedAndPending } = this.args;

    if (isUserInitiator) {
      return isUserKycSubmittedAndPending ? 'info' : 'warning';
    }

    return 'info';
  }

  get shouldDisplayAmount() {
    let { transfer } = this.args;
    let { amountCurrency, localAmountCurrency } = transfer;

    return (
      amountCurrency !== localAmountCurrency &&
      transfer.get('beneficiary') &&
      transfer.get('beneficiary.fx')
    );
  }

  get shouldDisplayFinancingNotCompliantDisclaimer() {
    return this.args.transfer.declinedReason === FINANCING_TRANSFER_DECLINED_REASON;
  }

  get shouldDisplayStandardTransferDisclaimer() {
    if (this.args.shouldShowTimeline) {
      return false;
    }

    const { instant, status, scheduledDate, beneficiary } = this.args.transfer;
    const isQontoToQonto = beneficiary?.get('qontoBankAccount');

    const supportedStatuses = [STATUS.COMPLETED, STATUS.PROCESSING];

    if (instant || !supportedStatuses.includes(status) || isQontoToQonto) {
      return false;
    }

    if (status === STATUS.COMPLETED) {
      const transferDate = dayjs(scheduledDate);
      return dayjs().diff(transferDate, 'day') <= 5;
    }

    return true;
  }

  get internationalFrequencyValue() {
    const {
      isInternationalRecurringWeekly,
      isInternationalRecurringMonthly,
      isInternationalRecurringQuarterly,
      isInternationalRecurringHalfYearly,
      isInternationalRecurringYearly,
    } = this.args.transfer;

    if (isInternationalRecurringWeekly) {
      return this.intl.t('transfers.frequency.standing_weekly');
    }

    if (isInternationalRecurringMonthly) {
      return this.intl.t('transfers.frequency.standing_monthly');
    }

    if (isInternationalRecurringQuarterly) {
      return this.intl.t('transfers.frequency.standing_quarterly');
    }

    if (isInternationalRecurringHalfYearly) {
      return this.intl.t('transfers.frequency.standing_half_yearly');
    }

    if (isInternationalRecurringYearly) {
      return this.intl.t('transfers.frequency.standing_yearly');
    }
  }

  get fixedAmountLabel() {
    if (this.args.transfer.fixedAmount === FIXED_AMOUNT_TYPES.AMOUNT) {
      return this.intl.t('labels.you-send');
    } else if (this.args.transfer.fixedAmount === FIXED_AMOUNT_TYPES.LOCAL_AMOUNT) {
      return this.intl.t('labels.beneficiary-receives');
    }
  }

  get fixedAmountText() {
    if (this.args.transfer.fixedAmount === FIXED_AMOUNT_TYPES.AMOUNT) {
      return formatMoney(this.args.transfer.amount, {
        intl: this.intl,
        currency: this.args.transfer.amountCurrency,
      });
    } else if (this.args.transfer.fixedAmount === FIXED_AMOUNT_TYPES.LOCAL_AMOUNT) {
      return formatMoney(this.args.transfer.localAmount, {
        intl: this.intl,
        currency: this.args.transfer.localAmountCurrency,
      });
    }
  }

  @action
  repeatTransfer() {
    let { transfer } = this.args;
    this.segment.track('transfer-side-panel_repeat-transfer_clicked');

    if (transfer.get('beneficiary') && !transfer.get('beneficiary.hidden')) {
      return this.flowLinkManager.replaceWith({
        name: 'sepa-transfer',
        stepId: 'details',
        queryParams: {
          repeatedTransferId: this.args.transfer.id,
          origin: TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER,
        },
      });
    } else {
      this.toastFlashMessages.toastError(this.intl.t('transfers.repeat.sepa.error.toast'));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TransferSidebar::Header': typeof TransferSidebarHeaderComponent;
  }
}
