/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface UserActionsCardSignature {
  // The arguments accepted by the component
  Args: {
    isDisabled?: boolean;
    isDismissible?: boolean;
    isPrimary?: boolean;
    isRequired?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class UserActionsCardComponent extends Component<UserActionsCardSignature> {
  badgeStatus = BadgeStatus;

  constructor(owner: unknown, args: UserActionsCardSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.args.onDidConstruct?.();
  }

  get showCta() {
    // @ts-expect-error
    return this.args.cta && !this.args.isDisabled;
  }

  get showDismissButton() {
    return (
      // @ts-expect-error
      !this.isPrimaryCard && this.showCta && this.args.isDismissible && this.args.onDismissClick
    );
  }

  get isPrimaryCard() {
    // @ts-expect-error
    return this.args.isPrimary && this.args.titleText;
  }

  handleCtaClickTask = dropTask(async () => {
    // The cta.onClick comes from the product-discovery service configuration
    // @ts-expect-error
    await this.args.cta.onClick?.();
    // @ts-expect-error
    this.args.onCtaClick?.();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UserActions::Card': typeof UserActionsCardComponent;
  }
}
