/* import __COLOCATED_TEMPLATE__ from './transfer-form.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface InternalTransferFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InternalTransferForm extends Component<InternalTransferFormSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('organization.activeSortedRemuneratedAndCurrentAccounts') activeAccounts;

  @tracked enableTransferValidations = false;
  @tracked scheduled = false;

  submitButtonId = `${guidFor(this)}-submit`;

  constructor(owner: unknown, args: InternalTransferFormSignature['Args']) {
    super(owner, args);

    this.segment.track('transfer_internal_create_page_loaded');
  }

  get isEditingTransfer() {
    // @ts-expect-error
    return Boolean(this.args.transfer.id);
  }

  get filteredAccounts() {
    return this.activeAccounts.filter(
      // @ts-expect-error
      account => account.id !== this.args.transfer.bankAccount?.id && !account.isRemunerated
    );
  }

  get selectedAccount() {
    return this.activeAccounts.find(
      // @ts-expect-error
      account =>
        // @ts-expect-error
        account.id === this.args.transfer.beneficiary.get('creditBankAccountId') ||
        // @ts-expect-error
        account.id === this.args.transfer.creditBankAccountId
    );
  }

  get title() {
    // @ts-expect-error
    return this.args.transfer.isNew
      ? this.intl.t('transfers.internal.title')
      : this.intl.t('transfers.edit.title');
  }

  submitTransferTask = dropTask(async transfer => {
    this.enableTransferValidations = false; // Hide errors
    // @ts-expect-error
    // eslint-disable-next-line ember/no-array-prototype-extensions
    this.args.transfer.errors.clear(); // Clearing previous BE apiAction errors

    let { validations: transferValidation } = await transfer.validate();
    this.enableTransferValidations = true; // Show errors

    if (transferValidation.isValid) {
      try {
        // @ts-expect-error
        await this.args.saveTransfer(transfer);
      } catch {
        // don't display toast for validation errors
        if (transfer.isValid) {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        }
      }
    }

    this._scrollIfError();
    this._trackOnTransferSubmission(transfer);
  });

  @action
  // @ts-expect-error
  selectAccountToDebit(account) {
    // Trigger loading state task coming from the parent controller for the accounts sidebar
    // @ts-expect-error
    this.args.handleSelectAccount?.();

    // @ts-expect-error
    this.args.transfer.set('bankAccount', account);

    // reset iban validation errors
    // @ts-expect-error
    if (this.args.transfer.validations.attrs.iban.isInvalid) {
      // @ts-expect-error
      let { iban } = this.args.transfer;

      // to reset the transfer iban validations we need to reset
      // the iban attribute but we also want to keep the previous
      // value so the form field remains filled with the same iban
      // @ts-expect-error
      this.args.transfer.set('iban', null);
      // @ts-expect-error
      this.args.transfer.set('iban', iban);
    }
  }

  @action
  // @ts-expect-error
  scrollToSelector({ elementId, offsetTop }) {
    next(() => scrollIntoView(`#${elementId}`, { offsetTop }));
  }

  _scrollIfError() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  // @ts-expect-error
  _trackOnTransferSubmission(transfer) {
    let { reference, amount } = transfer.validations.attrs;

    // BE errors are not yet reflected on the validations at this point
    // getting them directly
    let validAmount = amount.isValid && !transfer.errors.has('amount');
    let submissionTrackingEvent = transfer.isNew
      ? 'transfer_internal_create_submitted'
      : 'transfer_edit_submitted';

    let options = {
      amount: validAmount,
      reference: reference.isValid,
    };

    this.segment.track(submissionTrackingEvent, options);
    this._trackSelectedTransferOptions(transfer);
  }

  // @ts-expect-error
  _trackSelectedTransferOptions(transfer) {
    if (transfer.wasScheduled) {
      let scheduleProperties = {
        ...(transfer.isStandingMonthly && { monthly: true }),
        ...(transfer.isStandingWeekly && { weekly: true }),
      };
      this.segment.track('transfer_toggle_schedule', scheduleProperties);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Internal::TransferForm': typeof InternalTransferForm;
  }
}
