/* import __COLOCATED_TEMPLATE__ from './file-preview.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersInternationalOutModalsFilePreviewComponent extends Component<Signature> {
  @action
  // @ts-expect-error
  onRemove(file) {
    // @ts-expect-error
    this.args.data.onRemove(file);
    // @ts-expect-error
    this.args.close('confirm');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Modals::FilePreview': typeof TransfersInternationalOutModalsFilePreviewComponent;
  }
}
