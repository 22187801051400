/* import __COLOCATED_TEMPLATE__ from './scan.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ScanSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Scan = templateOnlyComponent<ScanSignature>();

export default Scan;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Scan: typeof Scan;
  }
}
