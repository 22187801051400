/* import __COLOCATED_TEMPLATE__ from './cards-banner.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CardsBannerComponent } from 'qonto/react/components/topbar/defense-mode';
interface TopbarCardsBannerSignature {
  Args: {
    close: () => void;
  };
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class TopbarCardsBannerComponent extends Component<TopbarCardsBannerSignature> {
  cardsBannerComponent = CardsBannerComponent;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::CardsBanner': typeof TopbarCardsBannerComponent;
  }
}
