/* import __COLOCATED_TEMPLATE__ from './transfer.hbs'; */
import Component from '@glimmer/component';

interface TaskTableCellTransferSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TaskTableCellTransferComponent extends Component<TaskTableCellTransferSignature> {
  get icon() {
    // @ts-expect-error
    let { request } = this.args;

    if (request.once) {
      return 'feature_transfer_direct-m';
    } else if (request.standing) {
      return 'feature_transfer_recurring-m';
    } else {
      return 'feature_transfer_scheduled-m';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Cell::Transfer': typeof TaskTableCellTransferComponent;
  }
}
