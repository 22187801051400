/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Modal = templateOnlyComponent<ModalSignature>();

export default Modal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::CompanyProfile::Modal': typeof Modal;
  }
}
