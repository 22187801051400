/* import __COLOCATED_TEMPLATE__ from './virtual-renew.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface TopbarCardsVirtualRenewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopbarCardsVirtualRenewComponent extends Component<TopbarCardsVirtualRenewSignature> {
  topBanner = TopBanner;

  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  @action
  close() {
    // @ts-expect-error
    safeLocalStorage.setItem('virtual-renewed-banner-dismissed', true);
    // @ts-expect-error
    this.args.close();
  }

  @action
  handleRenewLinkClick() {
    this.segment.track('virtual_cards_renewal_top_banner_redirection');
    this.close();
  }

  @action
  handleCloseClick() {
    this.segment.track('virtual_cards_renewal_top_banner_close');
    this.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::Cards::VirtualRenew': typeof TopbarCardsVirtualRenewComponent;
  }
}
