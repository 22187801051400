/* import __COLOCATED_TEMPLATE__ from './quick-actions.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, SkeletonLoader } from '@repo/design-system-kit';
import { dropTask, restartableTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { PAY_LATER_FLOW_ORIGIN } from 'qonto/constants/financing';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import {
  GENERIC_IBAN,
  INVOICE_OR_CREDIT_NOTE_TEXT,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import {
  isCurrentStepToApprove,
  isCurrentStepToPay,
  userIsCurrent, // @ts-expect-error
} from 'qonto/utils/approval-workflow-state';
// @ts-expect-error
import { isIbanFromSepaZone } from 'qonto/utils/beneficiaries';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import { SPEND_LIMITS_WARNINGS } from 'qonto/utils/extract-confirmation-response';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface SupplierInvoicesQuickActionsSignature {
  // The arguments accepted by the component
  Args: {
    isEligibleToInternationalTransfers?: boolean;
    isSwift?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierInvoicesQuickActionsComponent extends Component<SupplierInvoicesQuickActionsSignature> {
  badge = Badge;
  skeletonLoaderLine = SkeletonLoader.Line;

  @service declare abilities: Services['abilities'];
  @service declare beneficiariesManager: Services['beneficiariesManager'];
  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare supplierInvoicesManager: Services['supplierInvoicesManager'];
  @tracked approvalWorkflowEstimate = null;

  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  get isInbox() {
    // @ts-expect-error
    return [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice?.status);
  }

  get canInteractWithInvoice() {
    // @ts-expect-error
    let { status } = this.args.invoice;

    return [
      INVOICE_STATUSES.toReview,
      INVOICE_STATUSES.toPay,
      INVOICE_STATUSES.awaitingPayment,
      INVOICE_STATUSES.toApprove,
    ].includes(status);
  }

  get isSwift() {
    // @ts-expect-error
    let { totalAmount } = this.args.invoice;
    return totalAmount && totalAmount?.currency !== CURRENCIES.EUR;
  }

  get canUpdateInvoice() {
    return (
      this.abilities.can('update supplierInvoice') &&
      this.allowedStatusesForUpdates.invoices.includes(
        // @ts-expect-error
        this.args.invoice?.status
      )
    );
  }

  get showArchiveAction() {
    return (
      [
        INVOICE_STATUSES.toReview,
        INVOICE_STATUSES.toApprove,
        INVOICE_STATUSES.awaitingPayment,
        // @ts-expect-error
      ].includes(this.args.invoice?.status) && this.abilities.can('update supplier-invoice')
    );
  }

  get showUnarchiveAction() {
    return (
      // @ts-expect-error
      this.args.invoice?.status === INVOICE_STATUSES.archived &&
      this.abilities.can('update supplier-invoice')
    );
  }

  get showDeleteQuickAction() {
    return (
      // @ts-expect-error
      (this.canUpdateInvoice || this.args.invoice?.status === INVOICE_STATUSES.rejected) &&
      this.abilities.can('delete supplierInvoice') &&
      // @ts-expect-error
      !this.args.hasSelfInvoice
    );
  }

  get showPayLaterAction() {
    // @ts-expect-error
    let { invoice, isPayLaterEligible } = this.args;
    let hasTotalAmount = Boolean(invoice?.totalAmount);
    let isDefaultCurrency = invoice?.totalAmount?.currency === CURRENCIES.EUR;

    return (
      this.abilities.can('view pay later toggle in financing') &&
      isPayLaterEligible &&
      (!hasTotalAmount || isDefaultCurrency)
    );
  }

  get payLaterActionDisabled() {
    return this.abilities.cannot('create pay later transfer in financing');
  }

  get canUpdateInvoiceStatus() {
    // @ts-expect-error
    let { dueDate, supplierName, status, totalAmount } = this.args.invoice || {};

    return (
      status === INVOICE_STATUSES.paid || Boolean(dueDate && supplierName && totalAmount?.value)
    );
  }

  get isSwiftTransferButNotEligible() {
    return this.isSwift && !this.args.isEligibleToInternationalTransfers;
  }

  get isTransferActionDisabled() {
    return (
      this.canUpdateInvoiceStatus &&
      // @ts-expect-error
      !this.args.invoice?.hasDuplicates &&
      !this.isSwiftTransferButNotEligible
    );
  }

  get isPayByTransferQuickActionDisabled() {
    return (
      this.checkTransferLimitsTask.isRunning ||
      this.isPayByTransferDisabled ||
      // @ts-expect-error
      this.args.invoice?.hasDuplicates ||
      this.isSwiftTransferButNotEligible
    );
  }

  get isRequestTransferQuickActionDisabled() {
    return (
      this.checkTransferLimitsTask.isRunning ||
      !this.canUpdateInvoiceStatus ||
      // @ts-expect-error
      this.args.invoice?.hasDuplicates
    );
  }

  get isCreditNote() {
    // @ts-expect-error
    return this.args.invoice?.isCreditNote || false;
  }

  get allowedStatusesForUpdates() {
    return {
      invoices: [
        INVOICE_STATUSES.toReview,
        INVOICE_STATUSES.toPay,
        INVOICE_STATUSES.paid,
        INVOICE_STATUSES.toApprove,
        INVOICE_STATUSES.awaitingPayment,
      ],
      creditNotes: [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay],
    };
  }

  get showActionForCreditNotes() {
    return (
      this.abilities.can('update supplierInvoice') &&
      // @ts-expect-error
      this.allowedStatusesForUpdates.creditNotes.includes(this.args.invoice?.status)
    );
  }

  get hasToApproveStatus() {
    // @ts-expect-error
    return this.args.invoice?.status === INVOICE_STATUSES.toApprove;
  }

  get hasAwaitingPaymentStatus() {
    // @ts-expect-error
    return this.args.invoice?.status === INVOICE_STATUSES.awaitingPayment;
  }

  get isAwaitingForApproval() {
    return (
      this.hasToApproveStatus ||
      (this.approvalWorkflowEstimate && isCurrentStepToApprove(this.approvalWorkflowEstimate))
    );
  }

  get isNextInLineForApproval() {
    let { membership } = this.organizationManager;
    return (
      this.hasToApproveStatus ||
      (this.approvalWorkflowEstimate && userIsCurrent(this.approvalWorkflowEstimate, membership.id))
    );
  }

  get isAwaitingForPayment() {
    if (this.hasAwaitingPaymentStatus || !this.approvalWorkflowEstimate) {
      return true;
    }

    return isCurrentStepToPay(this.approvalWorkflowEstimate);
  }

  get isNextInLineForPayment() {
    let { membership } = this.organizationManager;
    return (
      this.hasAwaitingPaymentStatus ||
      (this.approvalWorkflowEstimate &&
        userIsCurrent(this.approvalWorkflowEstimate, membership?.id))
    );
  }

  get showRejectAction() {
    // @ts-expect-error
    let { invoice } = this.args;
    let { membership } = this.organizationManager;

    if ([INVOICE_STATUSES.toApprove, INVOICE_STATUSES.awaitingPayment].includes(invoice.status)) {
      return invoice.approvalWorkflow?.approverIds?.includes(membership.id);
    }

    return this.abilities.can('update supplier-invoice');
  }

  get showPayButton() {
    return (this.canPayByTransfer && this.isNextInLineForPayment) || this.showPayButtonAsFallback;
  }

  get showPayButtonAsFallback() {
    // @ts-expect-error
    let { invoice } = this.args;
    return (
      invoice.status === INVOICE_STATUSES.toReview &&
      !this.fetchApprovalWorkflowEstimateTask.lastSuccessful?.value
    );
  }

  get canEditInvoice() {
    let editableStatuses = [
      INVOICE_STATUSES.toReview,
      INVOICE_STATUSES.toPay,
      INVOICE_STATUSES.toApprove,
      INVOICE_STATUSES.awaitingPayment,
    ];

    return (
      this.abilities.can('update supplierInvoice') &&
      editableStatuses.includes(
        // @ts-expect-error
        this.args.invoice?.status
      )
    );
  }

  get showEditInvoiceAction() {
    // @ts-expect-error
    let { frenchEInvoicing = false, isGermanEInvoice = false } = this.args.invoice || {};
    return frenchEInvoicing || isGermanEInvoice
      ? false
      : this.isCreditNote
        ? this.showActionForCreditNotes
        : this.canEditInvoice;
  }

  get matchTransactionCopy() {
    // @ts-expect-error
    let isPaid = this.args.invoice.status === INVOICE_STATUSES.paid;

    return (this.isCreditNote && this.showActionForCreditNotes) || isPaid
      ? this.intl.t('supplier-invoices.cta.match-transaction')
      : this.intl.t('supplier-invoices.cta.mark-as-paid');
  }

  get editInvoiceActionCopy() {
    return this.isCreditNote
      ? this.intl.t('supplier-invoices.cta.edit-credit-note-details')
      : this.intl.t('supplier-invoices.cta.edit-details');
  }

  get hasTransferWarnings() {
    return this.checkTransferLimitsTask.lastSuccessful?.value;
  }

  get canPayByTransfer() {
    return this.abilities.can('create transfer') && !this.hasTransferWarnings;
  }

  get payByTransferLabel() {
    return this.intl.t('supplier-invoices.quick-actions.pay');
  }

  get hasRequestTransferAbility() {
    return this.abilities.can('request transfer supplierInvoice');
  }

  get isPayByTransferDisabled() {
    return (
      !this.canPayByTransfer || this.isSwiftTransferButNotEligible || !this.canUpdateInvoiceStatus
    );
  }

  get payByTransferTooltipText() {
    if (this.isSwiftTransferButNotEligible) {
      return this.intl.t('supplier-invoices.cta.international-out-unavailable');
    }

    if (this.isPayByTransferDisabled) {
      return this.intl.t('supplier-invoices.preview.quick-actions-pay-by-transfer-tooltip');
    }

    // @ts-expect-error
    if (this.args.invoice?.hasDuplicates) {
      return this.intl.t(
        'supplier-invoices.table.duplicates.quick-actions.pay-by-transfer-not-allowed-tooltip'
      );
    }
  }

  get requestTransferTooltipText() {
    if (!this.canUpdateInvoiceStatus) {
      return this.isSwift
        ? this.intl.t('supplier-invoices.preview.quick-actions-swift-request-transfer-tooltip')
        : this.intl.t('supplier-invoices.preview.quick-actions-request-transfer-tooltip');
    }

    // @ts-expect-error
    if (this.args.invoice?.hasDuplicates) {
      return this.intl.t(
        'supplier-invoices.table.duplicates.quick-actions.request-transfer-not-allowed-tooltip'
      );
    }
  }

  get showApprovalWorkflowInteractionActions() {
    return this.showMakeATransferAction;
  }

  get showMakeATransferAction() {
    return !this.isCreditNote;
  }

  get canEstimateApprovalWorkflow() {
    // @ts-expect-error
    return this.args.invoice.status === INVOICE_STATUSES.toReview;
  }

  @action
  openAttachmentLinkModal() {
    // @ts-expect-error
    let { invoice, trackingEventOptions } = this.args;

    this.segment.track('supplier-invoices_link-invoice_clicked', {
      page: invoice.status,
      origin_type: this.isCreditNote
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
      ...(trackingEventOptions || {}),
      is_einvoice: invoice?.isElectronicInvoice ?? false,
    });

    this.modals.open(
      'attachments/attachments-suggested/modal',
      {
        creditNote: invoice,
        // @ts-expect-error
        onClose: close => close(),
        hasSupplierInvoices: true,
        transaction: {},
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }

  @action
  // @ts-expect-error
  toggleMenu(showMenu) {
    // @ts-expect-error
    this.args.onToggleMenu?.(showMenu);

    if (showMenu) {
      if (this.canEstimateApprovalWorkflow) {
        this.fetchApprovalWorkflowEstimateTask.perform().catch(ignoreCancelation);
      }

      if (!this.hasTransferWarnings) {
        this.checkTransferLimitsTask
          .perform()
          .catch(ignoreCancelation)
          .catch(error => {
            let errorInfo = ErrorInfo.for(error);
            if (errorInfo.shouldSendToSentry) {
              // @ts-expect-error
              this.sentry.withScope(scope => {
                if (errorInfo.httpStatus === 422 && error.errors?.length) {
                  // @ts-expect-error
                  error.errors.forEach((e, i) => {
                    scope.setContext(`error_${i}`, e);
                  });
                }

                this.sentry.captureException(error);
              });
            }
          });
      }
    }
  }

  @action
  openInvoiceEdition() {
    this.segment.track('supplier-invoices_edit_clicked', {
      // @ts-expect-error
      page: this.args.invoice.status,
      origin_type: this.isCreditNote
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
      // @ts-expect-error
      ...(this.args.trackingEventOptions || {}),
    });
    // @ts-expect-error
    this.router.transitionTo('supplier-invoices.show', this.args.invoice.id, {
      queryParams: { edit: true },
    });
  }

  triggerDeleteInvoiceTask = dropTask(async () => {
    // @ts-expect-error
    let { invoice } = this.args;

    this.segment.track('supplier-invoices_delete_clicked', {
      page: invoice.status,
      action_type: 'single_item',
      is_non_financial_document: invoice.isAttachmentNonFinancial,
      origin_type: this.isCreditNote
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
      // @ts-expect-error
      ...(this.args.trackingEventOptions || {}),
    });

    await this.modals.open('popup/destructive', {
      title: this.intl.t('supplier-invoices.delete-modal.title'),
      description: this.intl.t('supplier-invoices.delete-modal.subtitle'),
      confirm: this.intl.t('btn.delete'),
      cancel: this.intl.t('btn.cancel'),
      // @ts-expect-error
      confirmTask: this.supplierInvoicesManager.confirmDeleteInvoiceTask,
      // @ts-expect-error
      invoice: this.args.invoice,
      // @ts-expect-error
      onDeleteInvoice: this.args.onDeleteInvoice,
    });
  });

  downloadInvoiceAttachmentTask = dropTask(async () => {
    // @ts-expect-error
    let attachment = await this._fetchAttachment(this.args.invoice);

    this.segment.track('supplier-invoices_download_clicked', {
      // @ts-expect-error
      page: this.args.invoice.status,
      // @ts-expect-error
      ...(this.args.trackingEventOptions || {}),
    });
    window.open(attachment.downloadUrl);
  });

  markAsPaidTask = dropTask(async () => {
    // @ts-expect-error
    let attachment = await this._fetchAttachment(this.args.invoice);
    let feedback =
      // @ts-expect-error
      this.args.invoice.status === INVOICE_STATUSES.paid ||
      (this.isCreditNote && this.showActionForCreditNotes)
        ? this.intl.t('supplier-invoices.success-toast.matched-transaction')
        : this.intl.t('supplier-invoices.success-toast.mark-as-paid');

    this.segment.track('supplier-invoices_mark-as-paid_clicked', {
      // @ts-expect-error
      page: this.args.invoice.status,
      origin_type: this.isCreditNote
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
      // @ts-expect-error
      ...(this.args.trackingEventOptions || {}),
    });
    if (variation('feature--boolean-match-transaction-modal-migration')) {
      this.router.transitionTo('supplier-invoices.list.match-transaction', {
        queryParams: {
          // @ts-expect-error
          invoice_id: this.args.invoice.id,
          origin: 'supplier-invoices.list',
        },
      });
    } else {
      this.modals.open('supplier-invoices/transactions-modal', {
        isFullScreenModal: true,
        attachment,
        // @ts-expect-error
        invoice: this.args.invoice,
        onCloseModal: () => {
          this.toastFlashMessages.toastSuccess(feedback);
          this.router.transitionTo('supplier-invoices.index');
        },
      });
    }
  });

  checkTransferLimitsTask = restartableTask(async () => {
    if (
      !this.canPayByTransfer ||
      this.isPayByTransferDisabled ||
      !this.isInbox ||
      this.isSwift ||
      // @ts-expect-error
      !parseFloat(this.args.invoice.totalAmount?.value) > 0
    )
      return;

    // @ts-expect-error
    let { invoiceNumber, supplierName, totalAmount } = this.args.invoice;
    let { value, currency } = totalAmount;
    let amountDetails = this.isSwift
      ? {
          localAmountCurrency: currency,
          localAmount: value,
        }
      : {
          amountCurrency: currency,
          amount: value,
        };

    let transfer = this.store.createRecord('transfer', {
      bankAccount: this.organization.mainAccount,
      organization: this.organization,
      name: supplierName || 'name',
      iban: GENERIC_IBAN,
      activityTag: 'other_expense',
      reference: invoiceNumber || 'reference',
      ...amountDetails,
    });

    let { warnings } = await transfer.confirm();

    // @ts-expect-error
    return warnings?.some(warning => SPEND_LIMITS_WARNINGS.includes(warning));
  });

  fetchApprovalWorkflowEstimateTask = dropTask(async () => {
    try {
      // @ts-expect-error
      let response = await this.supplierInvoicesManager.fetchApprovalWorkflowEstimateTask.perform(
        // @ts-expect-error
        this.args.invoice
      );
      this.approvalWorkflowEstimate = response?.approvalWorkflowState;
      return response;
    } catch (error) {
      this.errors.handleError(error);
      this.sentry.captureException(error);
    }
  });

  approveInvoiceTask = dropTask(async () => {
    this.segment.track('supplier-invoices_approve-button_clicked', {
      origin: 'quick_actions',
    });
    // @ts-expect-error
    let { invoice } = this.args;
    let { status } = invoice;

    let response;
    let lastStep = false;
    if (status === INVOICE_STATUSES.toApprove) {
      // @ts-expect-error
      response = await this.supplierInvoicesManager.approveInvoiceTask.perform(
        // @ts-expect-error
        this.args.invoice
      );
      lastStep = response.requestSupplierInvoice.lastStep;
    } else if (status === INVOICE_STATUSES.toReview) {
      response = await this.assignApprovalWorkflowTask.perform('approve');
      lastStep = response.supplierInvoice.status === INVOICE_STATUSES.awaitingPayment;
    }

    let toastMessage = lastStep
      ? this.intl.t('supplier-invoices.toast.success.approved-to-pay')
      : this.intl.t('supplier-invoices.toast.success.approved-to-approve');

    this.toastFlashMessages.toastSuccess(toastMessage);

    // @ts-expect-error
    this.args.reloadData();
  });

  assignApprovalWorkflowButtonTask = dropTask(async (action: 'moveToToApprove' | 'moveToToPay') => {
    let response = await this.assignApprovalWorkflowTask.linked().perform();

    if (action === 'moveToToApprove') {
      this.segment.track('supplier-invoices_move-to-to-approve-button_clicked', {
        origin: 'quick_actions',
      });
    } else {
      this.segment.track('supplier-invoices_move-to-to-pay-button_clicked', {
        origin: 'quick_actions',
      });
    }

    if (response?.supplierInvoice) {
      let toastMessage =
        response.supplierInvoice.status === INVOICE_STATUSES.toApprove
          ? this.intl.t('supplier-invoices.action-toast.moved-to-to-approve')
          : this.intl.t('supplier-invoices.action-toast.moved-to-to-pay');

      this.toastFlashMessages.toastSuccess(toastMessage);

      // @ts-expect-error
      this.args.reloadData();
    }
  });

  assignApprovalWorkflowTask = dropTask(
    async (action?: 'approve' | 'reject', declinedNote?: string) => {
      // @ts-expect-error
      return await this.supplierInvoicesManager.assignApprovalWorkflowTask.perform(
        // @ts-expect-error
        this.args.invoice,
        action,
        declinedNote
      );
    }
  );

  rejectInvoiceTask = dropTask(async (close, declinedNote) => {
    this.segment.track('supplier-invoices_reject-button_clicked', {
      origin: 'rejection_modal',
    });

    try {
      // @ts-expect-error
      let { invoice } = this.args;
      let response;
      if ([INVOICE_STATUSES.toApprove, INVOICE_STATUSES.awaitingPayment].includes(invoice.status)) {
        // @ts-expect-error
        response = await this.supplierInvoicesManager.rejectInvoiceTask.perform(
          invoice,
          declinedNote
        );
      } else if (invoice.status === INVOICE_STATUSES.toReview) {
        response = await this.assignApprovalWorkflowTask.perform('reject', declinedNote);
      }

      if (response?.supplierInvoice?.status === INVOICE_STATUSES.rejected) {
        this.toastFlashMessages.toastSuccess(this.intl.t('supplier-invoices.toast.rejected'));
      }
    } catch (error) {
      this.errors.handleError(error);
    } finally {
      close();
      // @ts-expect-error
      this.args.reloadData();
    }
  });

  payByTransferTask = dropTask(async () => {
    // @ts-expect-error
    let { invoice = {} } = this.args;

    let queryParams = {
      origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
      supplierInvoiceId: invoice.id,
    };

    this.segment.track('supplier-invoices_pay-button_clicked', {
      origin: 'quick_actions',
    });

    if (invoice.status === INVOICE_STATUSES.toReview) {
      await this.assignApprovalWorkflowTask.linked().perform();
    }

    let legacyIban = invoice.iban;
    let supplierIban = invoice.supplierSnapshot?.iban || legacyIban;

    let supplierIbanIsNotSepa = Boolean(supplierIban && !isIbanFromSepaZone(supplierIban));
    let isFx = supplierIbanIsNotSepa || this.isSwift;

    if (isFx) {
      return this.flowLinkManager.transitionTo({
        name: 'international-out',
        stepId: 'quote',
        queryParams,
      });
    }

    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: variation('feature--boolean-improve-invoice-payment-flows')
        ? 'payment-details'
        : 'invoice',
      queryParams,
    });
  });

  @action
  payLaterTransfer() {
    // @ts-expect-error
    let { id } = this.args.invoice || {};

    let queryParams = { origin: PAY_LATER_FLOW_ORIGIN.SUPPLIER_INVOICES, invoiceId: id };

    this.segment.track('pay-later_supplier-invoices-financing_clicked');

    this.flowLinkManager.transitionTo({
      name: 'pay-later-transfer',
      stepId: variation('feature--boolean-improve-invoice-payment-flows')
        ? 'payment-details'
        : 'invoice',
      queryParams,
    });
  }

  @action
  requestTransfer() {
    this.segment.track('supplier-invoices_request-transfer_clicked');
    this.router.transitionTo('requests.transfers.new', {
      queryParams: {
        // @ts-expect-error
        supplierInvoiceId: this.args.invoice.id,
      },
    });
  }

  @action
  // @ts-expect-error
  archive(id) {
    this.segment.track('supplier-invoices_archive_clicked', {
      origin: 'invoices_list',
      action_type: 'single_item',
      origin_type: this.isCreditNote
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
      // @ts-expect-error
      ...(this.args.trackingEventOptions || {}),
    });
    this.modals.open('supplier-invoices/archive-popup', {
      id,
    });
  }

  @action
  // @ts-expect-error
  reject(id) {
    this.segment.track('supplier-invoices_reject-button_clicked', {
      origin: 'quick_actions',
    });
    this.modals.open('supplier-invoices/modals/decline-invoice', {
      supplierInvoiceId: id,
      confirmTask: this.rejectInvoiceTask,
    });
  }

  @action
  // @ts-expect-error
  unarchive(id) {
    this.segment.track('supplier-invoices_unarchive_clicked', { origin: 'invoices_list' });
    this.modals.open('supplier-invoices/unarchive-popup', { id });
  }

  // @ts-expect-error
  async _fetchAttachment(invoice) {
    let attachmentId = invoice.belongsTo('attachment').id();
    return await this.store.findRecord('attachment', attachmentId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::QuickActions': typeof SupplierInvoicesQuickActionsComponent;
  }
}
