export default {
  "wrapper": "qXv",
  "ember-power-select-dropdown": "qXo",
  "status": "qXn body-1",
  "error": "qXi",
  "success": "qXA",
  "type-container": "qXY",
  "type-close": "qXh",
  "date": "qXf",
  "close-icon": "qXK",
  "container": "qXG",
  "container-picto": "qXr",
  "container-picto-status": "qXb",
  "general": "qXy",
  "general-amount": "qXU",
  "unprocessed": "qXj",
  "fx-rate": "qXW",
  "general-counterparty": "qXu body-2",
  "infobox": "qCS",
  "account-infobox": "qCc qCS",
  "initiator-infobox": "qCq qCS",
  "transaction-infobox": "qCZ qCS",
  "repeat-infobox": "qCR qCS",
  "item": "qCQ body-2",
  "eye-icon": "qCe",
  "repeat-transfer-action": "qCB",
  "disclaimer": "qCE",
  "start-verification-link": "qCd",
  "financing-not-compliant-disclaimer": "qCI"
};
