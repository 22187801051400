/* import __COLOCATED_TEMPLATE__ from './deactivation-btns.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface SubscriptionDeactivationBtnsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionDeactivationBtnsComponent extends Component<SubscriptionDeactivationBtnsSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare abilities: Services['abilities'];
  @service declare modals: Services['modals'];

  get organization() {
    return this.organizationManager.organization;
  }

  cancelDeactivationTask = dropTask(async close => {
    this.segment.track('account_closing_cancel_closing_confirmed');
    try {
      await this.organization.cancelDeactivation();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('subscription.account-closing.cancel-closing.closing-cancelled')
      );
      // @ts-expect-error
      this.args.onDeactivationCanceled?.();
      await close();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  checkDeactivationRequirementsTask = dropTask(async () => {
    try {
      let { valid, provisionalDueDate, accountAuthorizedBalanceZero } =
        await this.organization.checkDeactivationRequirements();
      return { valid, dueDate: provisionalDueDate, accountAuthorizedBalanceZero };
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return { valid: false, dueDate: null };
    }
  });

  startDeactivationTask = dropTask(
    waitFor(async () => {
      if (this.abilities.cannot('deactivate organization')) {
        this.modals.open('subscription/close/unauthorized-modal');
        return;
      }

      this.segment.track('account_closing_start_button_clicked');
      let {
        valid: isValid,
        dueDate: provisionalDueDate,
        accountAuthorizedBalanceZero,
      } = await this.checkDeactivationRequirementsTask.perform();

      if (isValid) {
        return this.flowLinkManager.transitionTo({
          name: 'subscription-close',
          stepId: 'introduction',

          queryParams: {
            // @ts-expect-error
            origin: this.args.origin,
            provisionalDueDate,
            accountAuthorizedBalanceZero,
          },
        });
      } else {
        this.modals.open('subscription/close/contact-cs-modal');
        this.segment.track('account_closing_error_modal_displayed');
      }
    })
  );

  showCancelDeactivationModalTask = dropTask(async () => {
    this.segment.track('account_closing_cancel_closing_clicked');
    await this.modals.open('popup/confirmation', {
      title: this.intl.t('subscription.account-closing.cancel-closing.title'),
      description: this.intl.t('subscription.account-closing.cancel-closing.description'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.confirm'),
      confirmTask: this.cancelDeactivationTask,
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::DeactivationBtns': typeof SubscriptionDeactivationBtnsComponent;
  }
}
