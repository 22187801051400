export default {
  "custom-export-loading": "qIC",
  "title": "qIk",
  "description": "qIJ",
  "section": "qIs",
  "placeholder-avatar": "qIF",
  "_with-avatar": "qIg",
  "_with-list": "qID",
  "list-item": "qIV",
  "radio": "qIL",
  "header": "qIT",
  "header-line": "qIz"
};
