export default {
  "label-select": "cul",
  "puce": "cuX",
  "puce-wrapper": "cuC",
  "puce-container": "cuk",
  "readonly": "cuJ",
  "label-display": "cus",
  "label-list-name": "cuF body-1",
  "label-list-value": "cug body-2"
};
