/* import __COLOCATED_TEMPLATE__ from './step-state.hbs'; */
import Component from '@glimmer/component';

interface StepStateSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
    isSuccessStep?: boolean;
    isRejectedStep?: boolean;
    isLastStep?: boolean;
    isCurrentStep?: boolean;
    isStepCompleted?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class StepState extends Component<StepStateSignature> {
  get shouldDisplayResolutionIcon() {
    // Don't show resolution icon when loading
    return !this.args.isLoading && (this.args.isSuccessStep || this.args.isRejectedStep);
  }
  get resolutionStepIcon() {
    let { isRejectedStep, isSuccessStep } = this.args;

    if (isSuccessStep) {
      return {
        icon: 'icon_checkmark_rounded_filled',
      };
    }
    if (isRejectedStep) {
      return {
        icon: 'icon_cross_rounded_filled',
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Timeline::StepState': typeof StepState;
  }
}
