/* import __COLOCATED_TEMPLATE__ from './supplier-invoice.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SupplierInvoiceSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SupplierInvoice = templateOnlyComponent<SupplierInvoiceSignature>();

export default SupplierInvoice;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Cell::SupplierInvoice': typeof SupplierInvoice;
  }
}
