/* import __COLOCATED_TEMPLATE__ from './pdf-preview.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { DOCUMENT_TYPE } from 'qonto/constants/self-billing';

interface SelfBillingPdfPreviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SelfBillingPdfPreviewComponent extends Component<SelfBillingPdfPreviewSignature> {
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];

  get displayLanguage() {
    // @ts-expect-error
    let { invoice } = this.args;

    return invoice.isNew ? this.localeManager.locale : invoice.locale;
  }

  get documentTypeNote() {
    let documentTypes = {
      [DOCUMENT_TYPE.TD17]: this.intl.t('self-billing.pdf-preview.document-type.td17', {
        language: this.displayLanguage,
      }),
      [DOCUMENT_TYPE.TD18]: this.intl.t('self-billing.pdf-preview.document-type.td18', {
        language: this.displayLanguage,
      }),
      [DOCUMENT_TYPE.TD19]: this.intl.t('self-billing.pdf-preview.document-type.td19', {
        language: this.displayLanguage,
      }),
    };
    // @ts-expect-error
    return documentTypes[this.args.invoice?.documentType];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::PdfPreview': typeof SelfBillingPdfPreviewComponent;
  }
}
