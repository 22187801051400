/* import __COLOCATED_TEMPLATE__ from './loading.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LoadingSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Loading = templateOnlyComponent<LoadingSignature>();

export default Loading;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stepper::Timeline::Loading': typeof Loading;
  }
}
