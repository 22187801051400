/* import __COLOCATED_TEMPLATE__ from './presets.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, waitForQueue } from 'ember-concurrency';

// @ts-expect-error
import { MAX_SEARCH_PRESETS } from 'qonto/constants/search-presets';
// @ts-expect-error
import { defaultPagination } from 'qonto/routes/transactions/index/controller';
// @ts-expect-error
import { getFilterGroupExpressions } from 'qonto/utils/search-preset';

// @ts-expect-error
function resetPresetPagination(preset) {
  preset.query.pagination = defaultPagination;
  return preset;
}

interface PresetsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PresetsComponent extends Component<PresetsSignature> {
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  @tracked showCreateForm = false;
  @tracked showEditForm = false;
  // @ts-expect-error
  @tracked currentPreset;
  // @ts-expect-error
  @tracked _formData;
  // @ts-expect-error
  @tracked dropDownState;

  elementId = guidFor(this);
  presetButtonId = `${this.elementId}-presets-button`;
  dropdownBodyId = `${this.elementId}-dropdown-body`;

  get formData() {
    // @ts-expect-error
    let cachedFormData = this.args.cachedFormData.find(({ id }) => id === this._formData.id);
    return cachedFormData || this._formData;
  }

  get selectedPresetName() {
    // @ts-expect-error
    let { name } = this.args.selectedPreset;

    let translations = {
      missing_receipts: this.intl.t('transactions.filters.presets.items.missing_receipts'),
      settled_last_month: this.intl.t('transactions.filters.presets.items.settled_last_month'),
      to_verify: this.intl.t('transactions.filters.presets.items.to_verify'),
    };

    // @ts-expect-error
    return translations[name] || name;
  }

  get selectedPresetCount() {
    // @ts-expect-error
    let { matchesCount } = this.args.selectedPreset;
    return matchesCount.toString().length >= 4 ? '+999' : matchesCount;
  }

  // @ts-expect-error
  closeOnEscape(dropdown, e) {
    if (e.key === 'Escape') {
      dropdown.actions.close();
    }
  }

  @action
  // @ts-expect-error
  registerDropdownState(dropDownState) {
    if (dropDownState) {
      this.dropDownState = dropDownState;
    }
  }

  onLoadTask = dropTask(async () => {
    await waitForQueue('afterRender');
    // @ts-expect-error
    this.focusedOptionElement?.focus();
  });

  // @ts-expect-error
  canAdd(presets) {
    return presets?.length < MAX_SEARCH_PRESETS;
  }

  savePresetTask = dropTask(async preset => {
    try {
      // We need to reset the pagination to default because
      // the filtered results might change from the time the custom view was created.
      // See https://getqonto.atlassian.net/browse/BUG-6346
      let presetWithDefaultPagination = resetPresetPagination(preset);

      await presetWithDefaultPagination.save();

      // @ts-expect-error
      this.args.refreshCache(this.formData);
      // @ts-expect-error
      this.args.applyFilters(preset.query, preset.id);
      // @ts-expect-error
      this.args.onSelect(preset, this.elementId);

      this._cleanDropdownState();

      this.toastFlashMessages.toastInfo(this.intl.t('transactions.success-toast.view-created'));
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  // @ts-expect-error
  refreshCache(filterGroup, name) {
    let formData = {
      id: this.formData.id,
      name,
      // @ts-expect-error
      query: { ...this.args.queryOptions, filter_group: filterGroup },
    };

    // @ts-expect-error
    this.args.refreshCache(formData, true);
  }

  @action
  cancelFilters() {
    // @ts-expect-error
    this.args.refreshCache(this.formData);
    this._cleanDropdownState();
  }

  @action
  // @ts-expect-error
  onSelect(preset) {
    let { id, name: view_name } = preset;
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    let filterGroupsExpressions = [preset.query.filter_group];

    let filtersProperties = getFilterGroupExpressions(
      filterGroupsExpressions,
      this.organizationManager.organization.labelLists
    );

    // We need to reset the pagination to default because
    // the filtered results might change from the time the custom view was created.
    // See https://getqonto.atlassian.net/browse/BUG-6346
    let presetWithDefaultPagination = resetPresetPagination(preset);

    this.dropDownState.actions.close();

    this.segment.track('history_view_label_clicked', {
      view_name,
      price_plan,
      role,
      filter_types: filtersProperties,
    });

    // @ts-expect-error
    this.args.applyFilters(presetWithDefaultPagination.query, id);
    // @ts-expect-error
    this.args.onSelect(presetWithDefaultPagination, this.elementId);
  }

  @action
  cancelSelection() {
    // @ts-expect-error
    this.args.applyFilters();
    // @ts-expect-error
    this.args.onSelect();
  }

  @action
  // @ts-expect-error
  onEdit(preset) {
    let { id, name, query } = preset;

    this.currentPreset = preset;
    this._formData = { id, name, query };
    this.showEditForm = true;
  }

  @action
  // @ts-expect-error
  editPreset(filterGroup, name) {
    this.currentPreset.query.filter_group = filterGroup;
    this.currentPreset.name = name;

    return this.savePresetTask.perform(this.currentPreset);
  }

  @action
  // @ts-expect-error
  addPreset(presets) {
    if (!presets) return;

    if (this.canAdd(presets)) {
      this._formData = { id: this.elementId };
      this.showCreateForm = true;
    }
  }

  @action
  // @ts-expect-error
  createPreset(filterGroup, name) {
    let preset = this.store.createRecord('search-preset', {
      name,
      // @ts-expect-error
      query: { ...this.args.queryOptions, filter_group: filterGroup },
      organization: this.organizationManager.organization,
    });

    return this.savePresetTask.perform(preset);
  }

  deletePresetTask = dropTask(async preset => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('transactions.delete-view-confirmation-modal.title'),
      description: this.intl.t('transactions.delete-view-confirmation-modal.body'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      preset,
      confirmTask: this.deleteConfirmTask,
    });
  });

  deleteConfirmTask = dropTask(async (close, { preset }) => {
    try {
      await preset.destroyRecord();

      // @ts-expect-error
      if (preset.id === this.args.selectedPreset?.id) {
        // @ts-expect-error
        this.args.clearFilters();
      }

      this.toastFlashMessages.toastInfo(this.intl.t('transactions.success-toast.view-deleted'));
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
    await close();
  });

  _cleanDropdownState() {
    this.showCreateForm = false;
    this.showEditForm = false;
    this.currentPreset = undefined;
    this._formData = undefined;
    this.dropDownState.actions.close();
  }

  // @ts-expect-error
  getOptionIds = presets => presets?.map(this.getOptionId).join(' ');

  // @ts-expect-error
  getOptionId = ({ id }) => `${this.elementId}-preset-${id}`;

  @action
  // @ts-expect-error
  onTriggerKeyDown(event) {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        this.dropDownState.actions.open();
        break;
    }
  }

  @action
  // @ts-expect-error
  onOptionKeyDown(event) {
    let { focusedOptionElement } = this;
    let focusedIndex = Number(
      // @ts-expect-error
      focusedOptionElement?.closest('[data-option-index]')?.dataset.optionIndex
    );

    switch (event.key) {
      case 'ArrowDown':
        // @ts-expect-error
        this.dropdownElement
          .querySelector(`[data-option-index="${focusedIndex + 1}"] [role=option]`)
          // @ts-expect-error
          ?.focus();
        break;
      case 'ArrowUp':
        // @ts-expect-error
        this.dropdownElement
          .querySelector(`[data-option-index="${focusedIndex - 1}"] [role=option]`)
          // @ts-expect-error
          ?.focus();
        break;
      case 'Escape':
        this.dropDownState.actions.close();
        break;
    }
  }

  get dropdownElement() {
    return document.getElementById(`ember-basic-dropdown-content-${this.dropDownState.uniqueId}`);
  }

  get focusedOptionElement() {
    return (
      this.dropdownElement?.querySelector('[role=option]:focus') ||
      this.dropdownElement?.querySelector('[role=option]:hover') ||
      this.dropdownElement?.querySelector('[role=option][aria-selected=true]') ||
      this.dropdownElement?.querySelector('[data-option-index="0"] [role=option]')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Presets': typeof PresetsComponent;
  }
}
