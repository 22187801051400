/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface PresetsListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PresetsListComponent extends Component<PresetsListSignature> {
  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];

  constructor(owner: unknown, args: PresetsListSignature['Args']) {
    super(owner, args);
    this.queryPresets();
  }

  @action
  queryPresets() {
    this.queryPresetsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      });
  }

  queryPresetsTask = dropTask(async () => {
    let presets = await this.store.query('search-preset', {
      organization_id: this.organizationManager.organization.id,
    });
    // @ts-expect-error
    this.args.onLoad?.();
    return presets;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Presets::List': typeof PresetsListComponent;
  }
}
