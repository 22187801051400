export default {
  "modal": "qdD",
  "section": "qdV",
  "header": "qdL",
  "title": "qdT",
  "separator-title": "qdz",
  "icon": "qda",
  "formats": "qdH",
  "columns": "qdO",
  "column-list": "qdm",
  "draggable-column": "qdp",
  "column-field": "qdx",
  "column-selector": "qdw",
  "column-with-error": "qdN",
  "handle": "qdv",
  "label-color": "qdo",
  "disclaimer": "qdn"
};
