export default {
  "vat-lines": "qga",
  "vat-line": "qgH",
  "vat-input": "qgO",
  "vat-rate": "qgm",
  "vat-option-button": "qgp",
  "disabled": "qgx",
  "vat-option-tooltip": "qgw",
  "remove-label": "qgN",
  "remove-button": "qgv",
  "vat-total": "qgo",
  "vat-total-amount": "qgn",
  "add-vat-line": "qgi",
  "with-vat-lines": "qgA"
};
