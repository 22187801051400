/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Header } from 'qonto/react/components/transfers/table/header';
import { TableItem } from 'qonto/react/components/transfers/table/item';
import { Placeholder } from 'qonto/react/components/transfers/table/placeholder';

interface TransferTableSignature {
  // The arguments accepted by the component
  Args: {
    isSidepanelOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransferTableComponent extends Component<TransferTableSignature> {
  header = Header;
  placeholder = Placeholder;
  item = TableItem;

  @service declare segment: Services['segment'];

  @action
  // @ts-expect-error
  handleClick(id) {
    // @ts-expect-error
    this.args.highlightItem(id);
    this.segment.track('transfer_list_open_side_panel');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Table': typeof TransferTableComponent;
  }
}
