/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

interface InputSignature {
  // The arguments accepted by the component
  Args: {
    onClick?: () => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class Input extends Component<InputSignature> {
  guid = guidFor(this);

  @action
  // @ts-expect-error
  handleKeypressOpenFileBrowser(e) {
    e.target.click();
  }

  @action
  handleClick(): void {
    this.args.onClick?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Uploader::Input': typeof Input;
  }
}
