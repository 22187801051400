export default {
  "container": "qFJ",
  "container-column": "qFs",
  "content-wrapper": "qFF",
  "media-wrapper": "qFg",
  "content": "qFD",
  "title": "qFV",
  "subtitle": "qFL",
  "content-slot": "qFT",
  "sticky-panel": "qFz",
  "sticky-actions": "qFa"
};
