/* import __COLOCATED_TEMPLATE__ from './smart-picture.hbs'; */
import Component from '@glimmer/component';

interface SmartPictureSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLImageElement;
}

/**
 * Creates a Picture component with default Fallback to PNG format
 */
export default class SmartPictureComponent extends Component<SmartPictureSignature> {
  get fallback() {
    // @ts-expect-error
    return this.args.fallback || this.defaultFallback;
  }

  // By default, we serve the PNG with the same name-location
  get defaultFallback() {
    // @ts-expect-error
    return this.args.src.replace(/\.[^/.]+$/, '').concat('.png');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SmartPicture: typeof SmartPictureComponent;
  }
}
