/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { get } from '@ember/object';
import Component from '@glimmer/component';

// @ts-expect-error
import { removeDuplicatesByKey } from 'qonto/utils/remove-duplicates';

interface SidebarLabelsItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SidebarLabelsItemComponent extends Component<SidebarLabelsItemSignature> {
  // This computation is used to retrieve all labels
  // belonging to the appropriate args.labelList.
  // We use as well the args.updatedLabels to populate the inputs
  // depending on the state of args.updatedLabels. We use args.selectedLabels (base)
  // or args.updatedLabels if any actions has been taken by the user
  // The usage of args.updatedLabels is only for bulk-actions, where the selection doesn't
  // save the custom labels instantly.
  get labels() {
    // We have several places in the app where we use custom labels, but not with the transaction model
    // (When creating a transfer for example)
    // In this case, we just want to return the argument selectedLabels.
    // @ts-expect-error
    if (!this.args.transactionsNumber) {
      // @ts-expect-error
      return this.args.selectedLabels;
    }

    // This part is only for bulk-actions
    // When we have updated labels (not saved yet), we want to remove potential duplicates
    // and return the label array for this specific item input.
    // @ts-expect-error
    if (this.args.updatedLabels?.length) {
      // @ts-expect-error
      let updatedLabelsArray = this.args.updatedLabels.filter(item => {
        // @ts-expect-error
        return item.labelList.id === this.args.labelList.id;
      });
      updatedLabelsArray = removeDuplicatesByKey(updatedLabelsArray, 'id');

      if (updatedLabelsArray.length) {
        return updatedLabelsArray;
      }
    }
    // @ts-expect-error
    let labelsArray = this.args.selectedLabels.filter(item => {
      // @ts-expect-error
      return item.labelList.id === this.args.labelList.id;
    });

    // labelsArray length should always be equal to the transactionsNumber
    // Case :
    // - Highlight a single transaction => Having more than 2 labels selected for 1 item should not be possible.
    // - Bulk select 2 transactions :
    //    - We have 1 with a selected label for this item and one with no selection =>
    //      We want to return an empty array
    //    - We have two selected labels, even if they are not equal =>
    //      We want to proceed
    // @ts-expect-error
    if (labelsArray.length !== this.args.transactionsNumber) {
      return [];
    }

    let uniqLabelArray = removeDuplicatesByKey(labelsArray, 'id');

    // This uniqLabelArray length should always equal 1.
    // If this is for a highlighted item in the transaction history page:
    // - Having more than 2 labels selected for 1 item should not be possible.
    // If this is for bulk-actions :
    // - If we select two transactions but they have different labels selected for an item,
    // we want to return an empty array.
    // - If we select two transactions and they have the same label selected for an item,
    // we want to proceed and display it.
    if (uniqLabelArray.length !== 1) {
      return [];
    }

    return uniqLabelArray;
  }

  get selectedLabel() {
    // @ts-expect-error
    return this.labels?.find(item => get(item, 'labelList') === this.args.labelList);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SidebarLabels::Item': typeof SidebarLabelsItemComponent;
  }
}
