export default {
  "row": "qlP",
  "item-bulk": "qll",
  "active": "qlX",
  "disabled": "qlC",
  "cell": "qlk body-2",
  "not-disabled": "qlJ",
  "item-amount-disabled": "qls",
  "item-amount-credit": "qlF",
  "no-padding": "qlg",
  "cell-content": "qlD",
  "centered-cell": "qlV",
  "item-counterparty-icon": "qlL mr-16",
  "avatar": "qlT",
  "item-counterparty-name": "qlz",
  "item-counterparty-name-status": "qla",
  "disputing-chargeback": "qlH",
  "item-counterparty-name-text": "qlO",
  "item-amount": "qlm body-1",
  "item-amount-foreign": "qlp",
  "attachment-icon": "qlx",
  "item-method": "qlw",
  "bank-account-avatar": "qlN",
  "attachment-status-dot": "qlv",
  "attached": "qlo",
  "missing": "qln",
  "item-attachment": "qli",
  "compact": "qlA",
  "upsell-badge": "qlY",
  "fadein-item": "qlh"
};
