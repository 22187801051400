/* import __COLOCATED_TEMPLATE__ from './amount-currency-field.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Currency } from 'qonto/react/components/transfers/international-out/currency';
import { type CurrenciesGroup, type EnhancedCurrency, groupCurrencies } from 'qonto/utils/currency';

interface Signature {
  Args: {
    amount: number | null;
    currency: string;
    currencies: EnhancedCurrency[];
    label: string;
    placeholder?: string;
    numberOfDecimal?: number;
    searchField: string;
    renderInPlace?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    onAmountUpdate: (amount: number | null) => void;
    onCurrencyUpdate: (code: string) => void;
  };
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutAmountCurrencyFieldComponent extends Component<Signature> {
  @service declare intl: Services['intl'];

  currency = Currency;

  get currencies(): EnhancedCurrency[] | CurrenciesGroup<EnhancedCurrency>[] {
    let allCurrencies = this.args.currencies;

    let [popularCurrencies, otherCurrencies] = groupCurrencies(allCurrencies);

    return popularCurrencies?.length && otherCurrencies?.length
      ? [
          {
            groupName: this.intl.t('international-out.quote.currencies.popular-label'),
            options: popularCurrencies,
          },
          {
            groupName: this.intl.t('international-out.quote.currencies.other-label'),
            options: otherCurrencies,
          },
        ]
      : allCurrencies;
  }

  get hasMultipleCurrencies(): boolean {
    return this.args.currencies.length > 1;
  }

  get selectedCurrency(): EnhancedCurrency | null {
    return this.args.currencies.find(({ code }) => code === this.args.currency) ?? null;
  }

  @action
  onCurrencyUpdate({ code }: EnhancedCurrency): void {
    this.args.onCurrencyUpdate(code);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::AmountCurrencyField': typeof TransfersInternationalOutAmountCurrencyFieldComponent;
  }
}
