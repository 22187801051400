export default {
  "review": "cUa",
  "review-content": "cUH",
  "review-parameters": "cUO",
  "row": "cUm",
  "row__border": "cUp",
  "label": "cUx",
  "value": "cUw",
  "error": "cUN",
  "tooltip-icon": "cUv",
  "terms-link": "cUo"
};
