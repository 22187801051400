export default {
  "modal": "cjs",
  "container": "cjF",
  "form-container": "cjg",
  "preview-container": "cjD",
  "preview-content": "cjV",
  "switch-container": "cjL",
  "supplier-invoice-preview": "cjT",
  "header": "cjz",
  "footer": "cja",
  "form-content": "cjH",
  "form-content-section": "cjO",
  "form-disclaimer": "cjm",
  "hidden": "cjp"
};
