/* import __COLOCATED_TEMPLATE__ from './transfer-status-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { rawTimeout } from 'ember-concurrency';

import {
  MULTI_TRANSFER_CREATED_EVENT,
  MULTI_TRANSFER_FAILURE_EVENT,
  MULTI_TRANSFER_INTERNAL_SERVER_EVENT,
  MULTI_TRANSFER_LIMITS_EXCEEDED_EVENT,
  MULTI_TRANSFER_TIMING,
} from 'qonto/constants/transfers';
import { Processing } from 'qonto/react/components/transfers/sepa/multi-transfer/processing/processing';
import { Timeout } from 'qonto/react/components/transfers/sepa/multi-transfer/timeout/timeout';

interface TransferStatusModalSignature {
  Args: {
    close: () => void;
    data?: {
      transferId?: string;
      title?: string;
      onClose?: () => void;
    };
  };
}

export default class TransferStatusModalComponent extends Component<TransferStatusModalSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  processing = Processing;
  timeout = Timeout;

  @tracked isTimeout = false;

  constructor(owner: unknown, args: TransferStatusModalSignature['Args']) {
    super(owner, args);
    this.setupModalResolveOrTimeout();
  }

  async setupModalResolveOrTimeout() {
    let [websocketEvent] = await Promise.all([
      Promise.race([
        // @ts-expect-error -- Property 'waitForEventTask' does not exist on type 'Service'
        this.notifierManager.waitForEventTask.perform(MULTI_TRANSFER_CREATED_EVENT),
        // @ts-expect-error -- Property 'waitForEventTask' does not exist on type 'Service'
        this.notifierManager.waitForEventTask.perform(MULTI_TRANSFER_FAILURE_EVENT),
        // @ts-expect-error -- Property 'waitForEventTask' does not exist on type 'Service'
        this.notifierManager.waitForEventTask.perform(MULTI_TRANSFER_LIMITS_EXCEEDED_EVENT),
        // @ts-expect-error -- Property 'waitForEventTask' does not exist on type 'Service'
        this.notifierManager.waitForEventTask.perform(MULTI_TRANSFER_INTERNAL_SERVER_EVENT),
        rawTimeout(MULTI_TRANSFER_TIMING.WEBSOCKET_TIMEOUT_MS),
      ]),
      // Wait for the modal to be displayed for at least 2s
      rawTimeout(MULTI_TRANSFER_TIMING.MINIMAL_DISPLAY_TIME_MS),
    ]);

    switch (websocketEvent?.event) {
      case MULTI_TRANSFER_CREATED_EVENT:
        this._handleTransferCreated();
        break;
      case MULTI_TRANSFER_FAILURE_EVENT:
      case MULTI_TRANSFER_INTERNAL_SERVER_EVENT:
        this._handleServerError();
        break;
      case MULTI_TRANSFER_LIMITS_EXCEEDED_EVENT:
        this._handleTransferLimitsExceeded();
        break;
      default:
        this.isTimeout = true;
        break;
    }
  }

  _handleTransferCreated() {
    this.segment.track('app_multi_transfers_file_submitted');
    this.args.close();
    this.router.transitionTo('transfers.index');
    this.toastFlashMessages.toastInfo(this.intl.t('toasts.multi_transfer_created'));
  }

  _handleTransferLimitsExceeded() {
    this.args.close();
    this.toastFlashMessages.toastError(
      this.intl.t('requests.transfers.modals.confirm_multi_transfer.errors.insufficient-funds')
    );
  }

  _handleServerError() {
    this.args.close();
    this.router.transitionTo('transfers.pending');
    this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
  }

  @action
  onCloseTimeoutModal() {
    this.args.close();
    this.router.transitionTo('transfers.pending');
    this.segment.track('transfer-sepa-bulk_timeout-modal_dismissed');
  }
}
