export default {
  "details": "qcV",
  "details-header": "qcL",
  "details-header-amount": "qcT",
  "details-content": "qcz",
  "details-footer": "qca",
  "invoice-status-container": "qcH body-2",
  "invoice-dates": "qcO",
  "overdue": "qcm",
  "details-list": "qcp",
  "details-list-item": "qcx",
  "details-list-item-label": "qcw body-2",
  "details-list-item-value": "qcN",
  "border-top": "qcv",
  "description": "qco",
  "description-value": "qcn",
  "edit-button": "qci body-2",
  "edit-icon": "qcA",
  "badge-union": "qcY",
  "edit-description": "qch",
  "amount-disclaimer": "qcf",
  "strike-amount": "qcK"
};
