/* import __COLOCATED_TEMPLATE__ from './financing-income.hbs'; */
import Component from '@glimmer/component';

import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

interface TransactionsSidebarHeaderFinancingIncomeSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSidebarHeaderFinancingIncome extends Component<TransactionsSidebarHeaderFinancingIncomeSignature> {
  amount = Amount;
  Counterparty = Counterparty;
  Date = Date;
  type = Type;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::FinancingIncome': typeof TransactionsSidebarHeaderFinancingIncome;
  }
}
