export default {
  "header-cell": "qeZ",
  "first-col": "qeR",
  "mid-col": "qeQ",
  "outstanding-col": "qee",
  "header-content": "qeB caption-bold",
  "active": "qeE",
  "quick-actions": "qed",
  "empty": "qeI"
};
