export default {
  "card": "qFr",
  "card-primary": "qFb",
  "content": "qFy",
  "caption-text": "qFU",
  "content-primary": "qFj",
  "disabled": "qFW",
  "illustration": "qFu",
  "illustration-primary": "qgS",
  "illustration-disabled": "qgc",
  "cta": "qgq",
  "dismiss-button": "qgZ",
  "buttons": "qgR",
  "required-badge": "qgQ"
};
