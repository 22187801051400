export default {
  "bank-account-option": "qEc",
  "row": "qEq",
  "active": "qEZ",
  "animated": "qER",
  "fadein-item": "qEQ",
  "cell": "qEe body-2",
  "cell-content": "qEB",
  "subtitle": "qEE caption",
  "no-padding": "qEd",
  "empty": "qEI",
  "note": "qEt",
  "cell-content-amount": "qEM body-1",
  "cell-content-receipt": "qEP body-1",
  "cell-quick-actions": "qEl",
  "account-selector": "qEX",
  "cell-type": "qEC"
};
