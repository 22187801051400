/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { AmountField, Disclaimer } from '@repo/design-system-kit';

import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { calculateVatAmount } from 'qonto/utils/transfers';

interface TransfersSepaPayLaterDetailsSignature {
  // The arguments accepted by the component
  Args: {
    isMainAccountNotSelected?: boolean;
    isValidationEnabled?: boolean;
    isSchedulingOrRecurrenceEnabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransfersSepaPayLaterDetailsComponent extends Component<TransfersSepaPayLaterDetailsSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  amountField = AmountField;

  @service declare featuresManager: Services['featuresManager'];
  @service declare abilities: Services['abilities'];

  operationTypes = Object.values(SCHEDULE_OPERATION_TYPES);

  get organization() {
    // @ts-expect-error
    return this.args.sepaTransfer.get('organization');
  }

  get showActivityTag() {
    return !this.abilities.can('assign category');
  }

  @action
  // @ts-expect-error
  onAmountChange(amountValue) {
    this._updateVatAmount();
    // @ts-expect-error
    this.args.sepaTransfer.set('amount', amountValue);
    // @ts-expect-error
    this.args.disableValidation();
  }

  @action
  // @ts-expect-error
  updateReference(reference) {
    // @ts-expect-error
    this.args.sepaTransfer.reference = reference;
    // @ts-expect-error
    this.args.disableValidation();
  }

  _updateVatAmount() {
    // @ts-expect-error
    let { sepaTransfer } = this.args;

    if (!sepaTransfer) return;

    let { vatRate, amount } = sepaTransfer;

    if (vatRate > 0) {
      // @ts-expect-error
      this.args.sepaTransfer.vatAmount = calculateVatAmount(amount, vatRate);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::PayLater::Details': typeof TransfersSepaPayLaterDetailsComponent;
  }
}
