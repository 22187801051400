/* import __COLOCATED_TEMPLATE__ from './credit-note-details.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';
import Component from '@glimmer/component';

interface CreditNoteDetailsSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class CreditNoteDetailsComponent extends Component<CreditNoteDetailsSignature> {
  get invoiceNumber() {
    // @ts-expect-error
    const { invoiceNumber } = this.args.creditNote || {};
    return invoiceNumber || '-';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::CreditNoteDetails': typeof CreditNoteDetailsComponent;
  }
}
