/* import __COLOCATED_TEMPLATE__ from './rename-team.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { TEAM_NAME_MIN_LENGTH } from 'qonto/constants/teams';

interface RenameTeamModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RenameTeamModal extends Component<RenameTeamModalSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @tracked name = this.args.data.team.name;
  @tracked error = null;

  renameTeamTask = dropTask(async () => {
    // @ts-expect-error
    let { close, data } = this.args;
    let { team } = data;

    // @ts-expect-error
    this.error = this.validateName();

    if (this.error) return;

    // @ts-expect-error
    team.name = this.value.trim();

    try {
      await team.save();
    } catch (error) {
      team.rollbackAttributes();
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
      throw error;
    }

    let successMessage = this.intl.t('teams.rename.toast-success');
    this.toastFlashMessages.toastSuccess(successMessage);
    this.segment.track('teams_rename_success');

    close();
  });

  @action
  cancel() {
    if (this.renameTeamTask.isRunning) {
      return;
    }
    // @ts-expect-error
    this.args.close();
  }

  @action
  // @ts-expect-error
  updateField(value) {
    // @ts-expect-error
    this.value = value;
    this.error = null;
  }

  validateName() {
    // @ts-expect-error
    return this.value?.trim().length >= TEAM_NAME_MIN_LENGTH
      ? null
      : this.intl.t('validations.errors.tooShort', { min: TEAM_NAME_MIN_LENGTH });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Teams::Modal::RenameTeam': typeof RenameTeamModal;
  }
}
