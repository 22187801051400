/* import __COLOCATED_TEMPLATE__ from './ubo.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface SavingsAccountsStepsAboutSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SavingsAccountsStepsAboutComponent extends Component<SavingsAccountsStepsAboutSignature> {
  @service declare segment: Services['segment'];

  get isValid() {
    // @ts-expect-error
    let { tinErrors, countryErrors } = this.args.context;
    return (
      (!tinErrors || Object.keys(tinErrors).length === 0) &&
      (!countryErrors || Object.keys(countryErrors).length === 0)
    );
  }

  @action
  transitionToNext() {
    // @ts-expect-error
    this.args.context.displayUboErrors();
    if (this.isValid) {
      this.segment.track('savings_ubo_cta');
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::Steps::Ubo': typeof SavingsAccountsStepsAboutComponent;
  }
}
