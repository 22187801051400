/* import __COLOCATED_TEMPLATE__ from './wizard-stub-step.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface WizardStubStepSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const WizardStubStep = templateOnlyComponent<WizardStubStepSignature>();

export default WizardStubStep;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    WizardStubStep: typeof WizardStubStep;
  }
}
