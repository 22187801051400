/* import __COLOCATED_TEMPLATE__ from './total-amount.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { PayableAmountTooltip } from 'qonto/react/components/supplier-invoices/payable-amount-tooltip';

interface SupplierInvoicesTableRowCellTotalAmountSignature {
  // The arguments accepted by the component
  Args: {
    cellContent: {
      isCreditNote: boolean;
      missing: boolean;
      title: string;
      payableAmount: string | number;
      totalAmountCreditNotes: string;
      creditNotesCount: number;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableCellElement;
}

export default class SupplierInvoicesTableRowCellTotalAmountComponent extends Component<SupplierInvoicesTableRowCellTotalAmountSignature> {
  PayableAmountTooltip = PayableAmountTooltip;

  @service declare intl: Services['intl'];

  get shouldUseCreditNoteAmountColor() {
    let { cellContent } = this.args;
    return cellContent.isCreditNote;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table::Row::Cell::TotalAmount': typeof SupplierInvoicesTableRowCellTotalAmountComponent;
  }
}
