/* import __COLOCATED_TEMPLATE__ from './beneficiary.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DEFAULT_LOCALE } from '@repo/shared-config/app/constants/locales';
import { dropTask } from 'ember-concurrency';
import type { ValueOf } from 'type-fest';

import { State } from 'qonto/react/components/transfers/international-out/state';
import type { Beneficiary, BeneficiaryAccountDetail } from 'qonto/services/international-out/types';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import maybeT from 'qonto/utils/maybe-t';

export const DEFAULT_LOADING_OPTIONS = {
  count: 10,
  widths: {
    value: { md: 70 },
    label: { sm: 110, md: 170 },
  },
} as const;

interface Placeholder {
  labelWidthInPx: ValueOf<typeof DEFAULT_LOADING_OPTIONS.widths.label>;
  valueWidthInPx: ValueOf<typeof DEFAULT_LOADING_OPTIONS.widths.value>;
}

interface AccountDetailView {
  label: BeneficiaryAccountDetail['title'];
  value: BeneficiaryAccountDetail['value'];
}

interface Signature {
  Args: {
    data: {
      beneficiary: Pick<Beneficiary, 'id'>;
    };
  };
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutModalsBeneficiaryComponent extends Component<Signature> {
  state = State;

  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];

  @tracked beneficiaryDetails: AccountDetailView[] = [];
  @tracked isError = false;

  placeholders: Placeholder[] = Array.from(
    { length: DEFAULT_LOADING_OPTIONS.count },
    (_, index) => {
      let { label, value } = DEFAULT_LOADING_OPTIONS.widths;
      return {
        labelWidthInPx: index % 2 === 1 ? label.sm : label.md,
        valueWidthInPx: value.md,
      };
    }
  );

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.loadBeneficiaryDetailsTask.perform().catch(ignoreCancelation);
  }

  loadBeneficiaryDetailsTask = dropTask(async (): Promise<void> => {
    this.isError = false;

    try {
      this.beneficiaryDetails = (
        await this.internationalOutManager.getBeneficiaryDetails(this.args.data.beneficiary.id)
      ).map(this.#serializeAccountDetail);
    } catch {
      this.isError = true;
    }
  });

  #serializeAccountDetail = ({
    key,
    title,
    value,
  }: BeneficiaryAccountDetail): AccountDetailView => {
    return {
      label: maybeT(
        {
          key: `international-out.dynamic-form.label.${key}`,
          fallback: title,
        },
        this.intl,
        this.localeManager.locale ?? DEFAULT_LOCALE
      ),
      value,
    };
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Modals::Beneficiary': typeof TransfersInternationalOutModalsBeneficiaryComponent;
  }
}
