export default {
  "container": "qgM",
  "cards-container": "qgP",
  "card": "qgl",
  "expanded": "qgX",
  "hidden": "qgC",
  "collapsed": "qgk",
  "with-expand-collapse-animation": "qgJ",
  "with-dismiss-animation": "qgs",
  "has-one-card": "qgF",
  "dismissing": "qgg",
  "with-hover-animation": "qgD",
  "toggle-button": "qgV",
  "toggle-container": "qgL",
  "toggle-button-icon": "qgT",
  "has-two-cards": "qgz"
};
