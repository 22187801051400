/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

// @ts-expect-error
import styles from './item.module.css';

interface StatemetsTablePlaceholderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class StatemetsTablePlaceholder extends Component<StatemetsTablePlaceholderSignature> {
  styles = styles;
  placeholderLine = SkeletonLoader.Line;
  placeholderBlock = SkeletonLoader.Block;
  placeholderHeader = SkeletonLoader.Header;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Statements::Table::Placeholder': typeof StatemetsTablePlaceholder;
  }
}
