/* import __COLOCATED_TEMPLATE__ from './flash-card.hbs'; */
import Component from '@glimmer/component';

import { FlatAssets } from '@repo/cards-kit/assets';

interface FlashCardSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class FlashCard extends Component<FlashCardSignature> {
  flashCardAsset = FlatAssets.CardFlashDefault2021Flat;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Cell::FlashCard': typeof FlashCard;
  }
}
