/* import __COLOCATED_TEMPLATE__ from './sidebar-info-list.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarInfoListSignature {
  Element: HTMLDListElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SidebarInfoList = templateOnlyComponent<SidebarInfoListSignature>();

export default SidebarInfoList;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SidebarInfoList: typeof SidebarInfoList;
  }
}
