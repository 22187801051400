export default {
  "row": "qEk",
  "icon": "qEJ",
  "cell": "qEs",
  "cell-date": "qEF",
  "cell-amount": "qEg",
  "cell-status": "qED",
  "cell-content": "qEV",
  "align-right": "qEL"
};
