/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { StatusTag } from 'qonto/react/components/supplier-invoices/table/status-tag';

interface SupplierInvoicesTableCellSignature {
  // The arguments accepted by the component
  Args: {
    cell: any;
    key: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableCellElement;
}

export default class SupplierInvoicesTableCellComponent extends Component<SupplierInvoicesTableCellSignature> {
  Badge = BadgeStatus;
  statusTag = StatusTag;

  @service declare intl: Services['intl'];

  get isStatus() {
    return Object.values(INVOICE_STATUSES).includes(this.args.cell);
  }

  get isNotCreditNote() {
    return !this.args.cell?.isCreditNote;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table::Row::Cell': typeof SupplierInvoicesTableCellComponent;
  }
}
