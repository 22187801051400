/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Past } from 'qonto/react/tasks/components/table/header/past';
import { Pending } from 'qonto/react/tasks/components/table/header/pending';

interface TasksTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TasksTableComponent extends Component<TasksTableSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];

  pastHeader = Past;
  pendingHeader = Pending;

  get emptyStateSubtitle() {
    if (this.abilities.can('read ebics request')) {
      // @ts-expect-error
      return this.args.type === 'pending'
        ? this.intl.t('tasks.tabs.to-approve.empty-state.subtitle-micro')
        : this.intl.t('tasks.tabs.completed.empty-state.subtitle-micro');
    } else {
      // @ts-expect-error
      return this.args.type === 'pending'
        ? this.intl.t('tasks.tabs.to-approve.empty-state.subtitle')
        : this.intl.t('tasks.tabs.completed.empty-state.subtitle');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table': typeof TasksTableComponent;
  }
}
