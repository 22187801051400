export default {
  "cell": "qQC",
  "cell-label-container": "qQk",
  "cell-label": "qQJ",
  "overflow": "qQs",
  "missing": "qQF",
  "credit-note-amount": "qQg",
  "payable-amount": "qQD",
  "amount": "qQV"
};
