/* import __COLOCATED_TEMPLATE__ from './uploader.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

interface TransfersSepaPayByInvoiceUploaderSignature {
  // The arguments accepted by the component
  Args: {
    isPayLaterOptionAvailable?: boolean;
    isPayLaterLimitationsVisible?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransfersSepaPayByInvoiceUploaderComponent extends Component<TransfersSepaPayByInvoiceUploaderSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare payByInvoiceUploadManager: Services['payByInvoiceUploadManager'];
  // @ts-expect-error
  @reads('payByInvoiceUploadManager.files') files;

  // @ts-expect-error
  formattedMaxSize = formatFileSize(this.intl, this.payByInvoiceUploadManager.maxSize);

  constructor(owner: unknown, args: TransfersSepaPayByInvoiceUploaderSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.payByInvoiceUploadManager.registerCallback({
      onUploadFinished: this.onUploadFinish,
      onUploadErrors: this.onUploadError,
    });

    this.firstRenderCheck();
  }

  get maybeInvoice() {
    // @ts-expect-error
    return this.args.invoice || this.files[0] || null;
  }

  get invoiceId() {
    return this.maybeInvoice?.id || this.maybeInvoice?.invoiceId || null;
  }

  get dropzoneDescription() {
    return this.organizationManager.organization.legalCountry === 'DE'
      ? this.intl.t('labels.upload-message-with-xml', { maxSize: this.formattedMaxSize })
      : this.intl.t('transfers.sepa.invoice-uploader.description', {
          maxSize: this.formattedMaxSize,
        });
  }

  @action
  firstRenderCheck() {
    this.onUploadFinish();
    this.onUploadError();
  }

  @action
  onUploadFinish() {
    if (this.maybeInvoice?.attachment) {
      // @ts-expect-error
      this.args.onInvoiceUploaded(this.invoiceId);
    }
  }

  @action
  onUploadError() {
    if (this.maybeInvoice?.hasErrors) {
      // @ts-expect-error
      this.args.onUploadError(this.maybeInvoice.errors);
    }
  }

  @action
  onCancel() {
    if (this.invoiceId) {
      // @ts-expect-error
      this.args.onRemoveFile(this.invoiceId);
    }
    // @ts-expect-error
    this.payByInvoiceUploadManager.resetState();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::PayByInvoice::Uploader': typeof TransfersSepaPayByInvoiceUploaderComponent;
  }
}
