export default {
  "container": "qME",
  "header": "qMd",
  "title": "qMI caption-bold",
  "search-icon": "qMt",
  "text": "qMM body-2",
  "trigger": "qMP",
  "loading-state": "qMl",
  "dropzone-placeholder-wrapper": "qMX",
  "dropzone-placeholder": "qMC",
  "file-loading": "qMk",
  "search-qonto-cta": "qMJ",
  "search-qonto-cta-content": "qMs",
  "tooltip-wrapper": "qMF",
  "invoices-tooltip": "qMg"
};
