/* import __COLOCATED_TEMPLATE__ from './table-error-state.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface TableErrorStateSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const TableErrorState = templateOnlyComponent<TableErrorStateSignature>();

export default TableErrorState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TableErrorState: typeof TableErrorState;
  }
}
