export default {
  "display-block": "qsY",
  "pay-later-tooltip": "qsh",
  "disabled": "qsf",
  "stepper": "qsK",
  "installments": "qsG",
  "flex-items": "qsr",
  "day-label": "qsb body-2",
  "installment-amount": "qsy body-1",
  "spacing": "qsU",
  "info-icon": "qsj",
  "detail": "qsW",
  "detail-value": "qsu",
  "extra": "qFS",
  "extra-value": "qFc",
  "badge": "qFq",
  "title": "qFZ",
  "link": "qFR"
};
