export default {
  "transfer-form": "qCV",
  "transfer-form-title": "qCL",
  "transfer-form-subtitle": "qCT",
  "form": "qCz",
  "form-footer": "qCa",
  "footer-btn": "qCH",
  "header": "qCO",
  "main-settings": "qCm",
  "additional-settings": "qCp",
  "form-container": "qCx"
};
