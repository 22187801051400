/* import __COLOCATED_TEMPLATE__ from './beneficiary.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Checkbox } from '@repo/design-system-kit';

interface TransferMultiBeneficiarySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransferMultiBeneficiary extends Component<TransferMultiBeneficiarySignature> {
  checkbox = Checkbox;

  @service declare modals: Services['modals'];
  @service declare attachmentsManager: Services['attachmentsManager'];

  @tracked isEmailDirty = false;

  amountField = AmountField;
  _memoizedIsForceOpened = false;

  get activityTag() {
    // @ts-expect-error
    return this.args.transfer.activityTagCode || this.args.transfer.activityTag;
  }

  get displayWarning() {
    // @ts-expect-error
    let error = this.args.transfer.validations.error;
    if (error) {
      let isEmailErrorDisplayed = error.type === 'format' && error.attribute === 'email';
      return isEmailErrorDisplayed ? this.isEmailDirty : true;
    }
    return false;
  }

  @action
  // @ts-expect-error
  onAmountChange(amountValue) {
    // @ts-expect-error
    this.args.transfer.set('amount', amountValue);
  }

  // @ts-expect-error
  @action addAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.addAttachment(false, this.args.transfer, file);
  }

  // @ts-expect-error
  @action removeAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.removeAttachment(false, this.args.transfer, file);
  }

  // @ts-expect-error
  @action cancelUploadAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.cancelUploadAttachment(this.args.transfer, file);
  }

  // @ts-expect-error
  @action showAttachment(selectedFile) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile,
      // @ts-expect-error
      model: this.args.transfer,
      download: null,
    });
  }

  get isForceOpened() {
    let result = false;

    // @ts-expect-error
    if (this.args.transfer.get('didValidate')) {
      // @ts-expect-error
      if (this.args.transfer.validations.errors.length) result = true;
      // @ts-expect-error
      if (this.args.transfer.validations.isValidating) result = this._memoizedIsForceOpened;
    }

    // eslint-disable-next-line ember/no-side-effects
    this._memoizedIsForceOpened = result;

    return result;
  }

  @action
  // @ts-expect-error
  handleEmailFocusOut(event) {
    this.isEmailDirty = Boolean(event.target.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TransferMulti::Beneficiary': typeof TransferMultiBeneficiary;
  }
}
