/* import __COLOCATED_TEMPLATE__ from './update-password-modal.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface UpdatePasswordModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class UpdatePasswordModal extends Component<UpdatePasswordModalSignature> {
  disclaimer: typeof Disclaimer.Block = Disclaimer.Block;

  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  handleConfirmTask = dropTask(async () => {
    try {
      // @ts-expect-error
      await this.args.data.user.changePassword();

      let message = this.intl.t('toasts.update_password_successful');
      this.toastFlashMessages.toastSuccess(message);
      this.segment.track('change_password_completed');
      // @ts-expect-error
      this.args.close();
    } catch (error) {
      if (hasMFAError(error)) {
        throw error;
      }
      if (!(error instanceof InvalidError)) {
        this.errors.handleError(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UpdatePasswordModal: typeof UpdatePasswordModal;
  }
}
