/* import __COLOCATED_TEMPLATE__ from './supplier-name.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import {
  FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY,
  GERMAN_E_INVOICES_ARTICLE_FOR_DE,
  GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { StatusTag } from 'qonto/react/components/supplier-invoices/table/status-tag';
import { Avatar } from 'qonto/react/components/suppliers/table/item/avatar';

interface CellContent {
  id: string;
  status: string;
  title: string;
  subtitle: string;
  missing: boolean;
  isEInvoice: boolean;
  hasSelfInvoice: boolean;
  hasDuplicates: boolean;
  isAttachmentNonFinancial: boolean;
  isCreditNote: boolean;
}

interface SupplierInvoicesTableRowCellSupplierNameSignature {
  // The arguments accepted by the component
  Args: {
    cellContent: CellContent;
    firstEInvoiceId: string;
    invoice: any;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableCellElement;
}

export default class SupplierInvoicesTableRowCellSupplierNameComponent extends Component<SupplierInvoicesTableRowCellSupplierNameSignature> {
  avatar = Avatar;
  InstructionalTooltip = InstructionalTooltip;
  statusTag = StatusTag;

  @service declare intl: Services['intl'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare localeManager: Services['localeManager'];

  @tracked isFirstFrenchEinvoiceTooltipDismissed = false;
  @tracked hasDismissedTooltipForFirstGermanEInvoice = false;

  get organizationLegalCountry() {
    return this.organizationManager.organization?.legalCountry;
  }

  get shouldDisplayFirstEinvoiceTooltip() {
    let { firstEInvoiceId, cellContent } = this.args;
    return firstEInvoiceId === cellContent?.id;
  }

  get showTooltipOrEInvoiceLabel() {
    let { isEInvoice, hasSelfInvoice } = this.args.cellContent;
    return isEInvoice || hasSelfInvoice;
  }

  get einvoicingFAQLink() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        return this.zendeskLocalization.getLocalizedArticle('fr-einvoice');
      case 'DE':
        return this.localeManager.locale === 'de'
          ? GERMAN_E_INVOICES_ARTICLE_FOR_DE
          : GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS;
      default:
        return '';
    }
  }

  get toolTipTextContent() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        return {
          title: this.intl.t('supplier-invoices.first-einvoice-card.title'),
          description: this.intl.t('supplier-invoices.first-einvoice-card.subtitle.body'),
          linkText: this.intl.t(
            'supplier-invoices.instructional-tooltip.einvoicing-disclaimer.link'
          ),
          faqSource: '',
        };
      case 'DE':
        return {
          title: this.intl.t('supplier-invoices.table.instructional-tooltip.first-einvoice.title'),
          description: this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.body'
          ),
          linkText: this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.link'
          ),
          faqSource: 'first-einvoice',
        };
      default:
        return {};
    }
  }

  get isInstructionalToolTipDismissed() {
    if (this.organizationLegalCountry === 'FR') {
      return this.isFirstFrenchEinvoiceTooltipDismissed;
    } else if (this.organizationLegalCountry === 'DE') {
      return this.hasDismissedTooltipForFirstGermanEInvoice;
    } else {
      return false;
    }
  }

  get tooltipWarningDuplicateMessage() {
    let { cellContent } = this.args;
    if (cellContent.isCreditNote) {
      return this.intl.t('supplier-invoices.table.preview.credit-notes.duplicate-tooltip');
    }
    return this.intl.t('supplier-invoices.table.preview.duplicate-tooltip');
  }

  @action
  handleFirstFrenchEinvoiceTooltipClose() {
    this.isFirstFrenchEinvoiceTooltipDismissed = true;
    // @ts-expect-error
    safeLocalStorage.setItem(FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY, true);
  }

  @action
  handleCloseFirstGermanEInvoiceTooltip() {
    this.hasDismissedTooltipForFirstGermanEInvoice = true;
    // @ts-expect-error
    safeLocalStorage.setItem(FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY, true);
  }

  @action
  handleInstructionalTooltipClosure() {
    if (this.organizationLegalCountry === 'FR') {
      this.handleFirstFrenchEinvoiceTooltipClose();
    } else if (this.organizationLegalCountry === 'DE') {
      this.handleCloseFirstGermanEInvoiceTooltip();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table::Row::Cell::SupplierName': typeof SupplierInvoicesTableRowCellSupplierNameComponent;
  }
}
