/* import __COLOCATED_TEMPLATE__ from './attachments.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, Disclaimer, SkeletonLoader } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import { and, not, reads } from 'macro-decorators';

import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { ErrorState } from 'qonto/react/components/transactions/sidebar/details/attachments/error-state';

// @ts-expect-error
import styles from './attachments.module.css';

const MAX_ATTACHMENTS = 20;
const MAX_ATTACHMENTS_SKELETON_COUNT = 3;

interface TransactionsSiderbarDetailsAttachmentSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
    isError?: boolean;
    isInvoiceTooltipDismissed?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransactionsSiderbarDetailsAttachment extends Component<TransactionsSiderbarDetailsAttachmentSignature> {
  styles = styles;
  errorState = ErrorState;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  button = Button;
  placeholderBlock = SkeletonLoader.Block;
  placeholderLine = SkeletonLoader.Line;
  @service declare abilities: Services['abilities'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare store: Services['store'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @not('args.highlightedItem.isTax') isNotTax;
  // @ts-expect-error
  @not('args.highlightedItem.isFee') isNotFee;
  // @ts-expect-error
  @not('args.highlightedItem.isBiller') isNotBiller;
  // @ts-expect-error
  @not('args.highlightedItem.isPayLater') isNotPayLater;
  // @ts-expect-error
  @not('args.highlightedItem.isRibaPayment') isNotRibaPayment;
  // @ts-expect-error
  @not('args.highlightedItem.hasUndeletableAttachment') hasNoUndeletableAttachment;
  // @ts-expect-error
  @not('args.highlightedItem.isNrcPayment') isNotNrcPayment;
  // @ts-expect-error
  @not('args.highlightedItem.isPagoPaPayment') isNotPagoPaPayment;
  // @ts-expect-error
  @and('isNotFee', 'isNotTax', 'isNotPagoPaPayment', 'isNotRibaPayment') showMenu;
  // @ts-expect-error
  @reads('args.highlightedItem.debit') isOutgoingTransfer;

  // @ts-expect-error
  @tracked transactionWithNewAttachment;
  instructionalTooltip = InstructionalTooltip;

  get hasNoAttachments() {
    // @ts-expect-error
    return !this.args.highlightedItem.attachmentIds?.length;
  }

  get showReceiptRemindersDisclaimer() {
    return (
      this.abilities.can('view ReceiptReminder') &&
      this.isNotFee &&
      this.isNotPagoPaPayment &&
      this.isOutgoingTransfer
    );
  }

  get showFindOnQontoCta() {
    return (
      // @ts-expect-error
      this.args.highlightedItem.attachmentIds.length < MAX_ATTACHMENTS &&
      this.abilities.can('read receivable-invoice') &&
      this.abilities.can('access supplier-invoice') &&
      this.abilities.can('view supplier-invoice') &&
      this.isNotPagoPaPayment &&
      this.isNotBiller &&
      this.isNotNrcPayment
    );
  }

  get hasAutoAttachments() {
    // @ts-expect-error
    let { attachmentIds, automaticallyAddedAttachmentIds } = this.args.highlightedItem;
    return (
      this.abilities.can('access email forward attachment') &&
      // @ts-expect-error
      automaticallyAddedAttachmentIds?.some(id => attachmentIds?.includes(id))
    );
  }

  get showAttachmentRequestButton() {
    return (
      // @ts-expect-error
      this.abilities.can('request attachments in transaction', this.args.highlightedItem) &&
      this.hasNoAttachments &&
      this.isNotPagoPaPayment
    );
  }

  get showArPromoCard() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;

    let isTransactionIncome = transaction.isSwiftIncome || transaction.isIncome;
    let transactionHasNewAttachment = this.transactionWithNewAttachment === transaction.id;

    // @ts-expect-error
    return isTransactionIncome && transactionHasNewAttachment && this.args.hasNoQuoteAndInvoice;
  }

  get hasCardAcquirerPayout() {
    return (
      // @ts-expect-error
      this.args.highlightedItem.isTapToPay && // @ts-expect-error
      this.args.highlightedItem.attachments.some(
        // @ts-expect-error
        attachment => attachment.attachmentType === 'card_acquirer_payout'
      )
    );
  }

  get filesLoadingSkeletonCount() {
    // @ts-expect-error
    let { attachmentIds } = this.args.highlightedItem;
    let count = Math.min(attachmentIds?.length || 0, MAX_ATTACHMENTS_SKELETON_COUNT);
    return Array.from({ length: count }, (_, i) => i);
  }

  get hasImproveXSectionFeatureEnabled() {
    return this.abilities.can('viewImproveXSectionFeature attachment');
  }

  get isDebit() {
    // @ts-expect-error
    return this.args.highlightedItem.side === 'debit';
  }

  get isInternalTransfer() {
    // @ts-expect-error
    let transaction = this.args.highlightedItem;

    if (!transaction.isTransfer) {
      return false;
    }

    let transfer = transaction.get('subject');
    if (transfer) {
      return Boolean(transfer.get('creditBankAccountId'));
    }

    return false;
  }

  get canViewQuickActions() {
    return (
      this.hasImproveXSectionFeatureEnabled &&
      this.isNotBiller &&
      this.isNotPagoPaPayment &&
      this.isNotNrcPayment &&
      this.isNotPayLater &&
      !this.isInternalTransfer
    );
  }

  get attachmentDropdownProps() {
    return {
      isDebit: this.isDebit,
      isVisible: this.canViewQuickActions,
      handleDelete: this.handleDeleteAttachment,
      // @ts-expect-error
      handleSaveInInvoices: this.args.saveAttachmentAsInvoice,
      // @ts-expect-error
      handleOpenInInvoices: file => this.args.openInInvoices(file.attachmentId, this.isDebit),
    };
  }

  @action
  handleFindInvoices() {
    // @ts-expect-error
    this.args.handleShowSuggestedAttachments();
    this.segment.track('find_on_qonto_button_clicked');
  }

  @action
  // @ts-expect-error
  handleAddAttachment(file) {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;

    // @ts-expect-error
    this.args.saveAttachment(transaction, file, () => {
      this.transactionWithNewAttachment = transaction.id;
    });
  }

  // @ts-expect-error
  @action handleDeleteAttachment(file, isInvoice = false) {
    let trackerEvent = isInvoice
      ? 'transaction-details_unmatch_clicked'
      : 'transaction-details_delete_clicked';
    this.segment.track(trackerEvent);
    // @ts-expect-error
    return this.args.removeAttachment(this.args.highlightedItem, file, isInvoice);
  }

  markAttachmentAsLostTask = dropTask(
    waitFor(async dropdown => {
      // @ts-expect-error
      this.args.highlightedItem.attachmentLost = true;
      dropdown.actions.close();

      try {
        // @ts-expect-error
        await this.args.highlightedItem.markAttachmentAsLost();
      } catch {
        // @ts-expect-error
        this.args.highlightedItem.attachmentLost = false;
      }
    })
  );

  markAttachmentAsFoundTask = dropTask(
    waitFor(async dropdown => {
      // @ts-expect-error
      this.args.highlightedItem.attachmentLost = false;
      dropdown.actions.close();

      try {
        // @ts-expect-error
        await this.args.highlightedItem.unmarkAttachmentAsLost();
      } catch {
        // @ts-expect-error
        this.args.highlightedItem.attachmentLost = true;
      }
    })
  );

  markAttachmentAsRequiredTask = task(
    waitFor(async dropdown => {
      // @ts-expect-error
      this.args.highlightedItem.attachmentRequired = true;
      dropdown.actions.close();

      try {
        // @ts-expect-error
        await this.args.highlightedItem.markAttachmentAsRequired();
      } catch {
        // @ts-expect-error
        this.args.highlightedItem.attachmentRequired = false;
      }
    })
  );

  markAttachmentAsNotRequiredTask = task(
    waitFor(async dropdown => {
      // @ts-expect-error
      this.args.highlightedItem.attachmentRequired = false;
      dropdown.actions.close();

      try {
        // @ts-expect-error
        await this.args.highlightedItem.unmarkAttachmentAsRequired();
      } catch {
        // @ts-expect-error
        this.args.highlightedItem.attachmentRequired = true;
      }
    })
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Details::Attachments': typeof TransactionsSiderbarDetailsAttachment;
  }
}
