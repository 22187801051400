/* import __COLOCATED_TEMPLATE__ from './vat-accounting.hbs'; */
import Component from '@glimmer/component';

import { VatAccountingStatus } from 'qonto/react/components/vat-accounting/status/vat-accounting-status';

interface VatAccountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class VatAccountComponent extends Component<VatAccountSignature> {
  VatAccountingStatus = VatAccountingStatus;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VatAccounting: typeof VatAccountComponent;
  }
}
