/* import __COLOCATED_TEMPLATE__ from './bookkeeping-status.hbs'; */
import { assert } from '@ember/debug';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

interface TransactionsBookkeepingStatusSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransactionsBookkeepingStatusComponent extends Component<TransactionsBookkeepingStatusSignature> {
  badgeStatus = BadgeStatus;

  @service declare intl: Services['intl'];

  constructor(owner: unknown, args: TransactionsBookkeepingStatusSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    assert('@transaction is required', this.args.transaction);
  }

  get level() {
    // @ts-expect-error
    let transaction = this.args.transaction;
    if (transaction.bookkeepingStatus) {
      return transaction.bookkeepingStatus === 'verified' ? 'validated' : 'in-progress';
    } else {
      return transaction.qualifiedForAccounting ? 'validated' : 'waiting';
    }
  }

  get text() {
    // @ts-expect-error
    let transaction = this.args.transaction;
    if (transaction.bookkeepingStatus) {
      return transaction.bookkeepingStatus === 'verified'
        ? this.intl.t('bookkeeping.transactions.details-page.status.verified')
        : this.intl.t('bookkeeping.transactions.details-page.status.to-verify');
    } else {
      return transaction.qualifiedForAccounting
        ? this.intl.t('transactions.sidebar.bookkeeping.badge.verified')
        : this.intl.t('transactions.sidebar.bookkeeping.badge.to-be-verified');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::BookkeepingStatus': typeof TransactionsBookkeepingStatusComponent;
  }
}
