/* import __COLOCATED_TEMPLATE__ from './sticky-panel.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface StickyPanelSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const StickyPanel = templateOnlyComponent<StickyPanelSignature>();

export default StickyPanel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    StickyPanel: typeof StickyPanel;
  }
}
