/* import __COLOCATED_TEMPLATE__ from './country-trigger.hbs'; */
import Component from '@glimmer/component';

import type { FormFieldWithOptions } from 'qonto/components/transfers/international-out/dynamic-form/field/arguments';
import { Country } from 'qonto/react/components/transfers/international-out/country';

interface Signature {
  Args: {
    selected?: FormFieldWithOptions['valuesAllowed'][number]['name'];
    countryCode?: FormFieldWithOptions['valuesAllowed'][number]['key'];
    placeholder?: string;
  };
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutDynamicFormFieldSelectCountryTriggerComponent extends Component<Signature> {
  country = Country;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::Select::CountryTrigger': typeof TransfersInternationalOutDynamicFormFieldSelectCountryTriggerComponent;
  }
}
