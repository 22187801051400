export default {
  "fileupload": "qFw",
  "file": "qFN",
  "label": "qFv",
  "zone": "qFo",
  "error": "qFn",
  "hide": "qFi",
  "dropzone": "qFA",
  "hidden": "qFY",
  "upload-icon": "qFh",
  "dropping": "qFf",
  "bounce2": "qFK",
  "error-message": "qFG"
};
