/* import __COLOCATED_TEMPLATE__ from './upload-power-of-attorney.hbs'; */
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

// @ts-expect-error
import splitText from 'qonto/utils/split-text';

interface DeFreelancersFlexTopbarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DeFreelancersFlexTopbarComponent extends Component<DeFreelancersFlexTopbarSignature> {
  topBanner = TopBanner;

  get splitMessage() {
    // @ts-expect-error
    let text = this.args.content.message;
    return splitText(text);
  }

  get content() {
    return {
      // @ts-expect-error
      type: this.args.content.type,
      message: null,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::UploadPowerOfAttorney': typeof DeFreelancersFlexTopbarComponent;
  }
}
