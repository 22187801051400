/* import __COLOCATED_TEMPLATE__ from './sidebar-info-box.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarInfoBoxSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SidebarInfoBox = templateOnlyComponent<SidebarInfoBoxSignature>();

export default SidebarInfoBox;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SidebarInfoBox: typeof SidebarInfoBox;
  }
}
