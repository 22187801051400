import { action } from '@ember/object';
import Component from '@glimmer/component';

interface InputSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class Input extends Component<InputSignature> {
  @action
  // @ts-expect-error
  onInput(event) {
    // @ts-expect-error
    this.args.handleInput(event.target.value);
  }

  @action
  onFocus() {
    // @ts-expect-error
    this.args.select.actions.open();
    // @ts-expect-error
    if (this.args.value) {
      // @ts-expect-error
      this.args.handleInput(this.args.value);
    }
  }

  @action
  onKeyPress() {
    // @ts-expect-error
    this.args.select.actions.open();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::EditForm::SelectSupplier::Input': typeof Input;
  }
}
