/* import __COLOCATED_TEMPLATE__ from './related-invoice.hbs'; */
import Component from '@glimmer/component';

import { StatusTag } from 'qonto/react/components/supplier-invoices/status-tag';

interface SupplierInvoiceRelatedInvoiceSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SupplierInvoiceRelatedInvoiceComponent extends Component<SupplierInvoiceRelatedInvoiceSignature> {
  statusTag = StatusTag;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::RelatedInvoice': typeof SupplierInvoiceRelatedInvoiceComponent;
  }
}
