/* import __COLOCATED_TEMPLATE__ from './confirmation.hbs'; */
import { assert } from '@ember/debug';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { allSettled, dropTask, task, type TaskForAsyncTaskFunction } from 'ember-concurrency';

import type { DataContext } from 'qonto/routes/flows/setup/transfers/international-out/data-context';
import type { Confirmation } from 'qonto/services/international-out/types';
import { formatAmountToNumber } from 'qonto/utils/amount';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

export const MISSING_DATA_CONTEXT_ERROR_MESSAGE = 'A data context object must be provided';

interface Signature {
  Args: {
    context: DataContext & {
      beneficiary: NonNullable<DataContext['beneficiary']>;
      fees: NonNullable<DataContext['fees']>;
      quote: NonNullable<DataContext['quote']>;
      targetAccount: NonNullable<DataContext['targetAccount']>;
    };
  };
  Blocks: {
    default: [
      data: {
        confirmTask: TaskForAsyncTaskFunction<unknown, () => Promise<boolean>>;
      },
    ];
  };
}

export default class TransfersInternationalOutProvidersConfirmationComponent extends Component<Signature> {
  @service declare abilities: Services['abilities'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    assert(MISSING_DATA_CONTEXT_ERROR_MESSAGE, this.#dataContext);
  }

  get #dataContext(): Signature['Args']['context'] {
    return this.args.context;
  }

  confirmTask = dropTask(async (): Promise<boolean> => {
    let [budgetName, confirmationResult] = await allSettled([
      this.getBudgetNameTask.perform(),
      this.getConfirmationResultTask.perform(),
    ]);

    if (budgetName.state === 'fulfilled') {
      this.#dataContext.budgetName = budgetName.value;
    }

    if (confirmationResult.state === 'fulfilled') {
      this.#dataContext.confirmationResult = confirmationResult.value;
      return Boolean(confirmationResult.value);
    }

    return false;
  });

  getBudgetNameTask = task(async (): Promise<string | null> => {
    if (this.abilities.cannot('read budget')) {
      return null;
    }

    try {
      // @ts-expect-error
      let [searchResult] = await this.store.adapterFor('budget').search({
        initiatorId: this.organizationManager.membership.membershipId,
        scheduledDate: this.#dataContext.quote.estimatedDelivery,
      });

      return searchResult?.budget.name || null;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return null;
    }
  });

  getConfirmationResultTask = task(async (): Promise<Confirmation | null> => {
    let { bankAccount, beneficiary, fees, quote } = this.#dataContext;

    try {
      return await this.internationalOutManager.confirmTransfer({
        amount: {
          value: formatAmountToNumber({
            amount: quote.sourceAmount + fees.total,
            precision: getAllowedDecimalPlaces(quote.sourceCurrency),
          }),
          currency: quote.sourceCurrency,
        },
        bankAccountId: bankAccount.id,
        beneficiaryId: beneficiary.id,
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      return null;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Providers::Confirmation': typeof TransfersInternationalOutProvidersConfirmationComponent;
  }
}
