/* import __COLOCATED_TEMPLATE__ from './transfers-banner.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TransfersBannerComponent } from 'qonto/react/components/topbar/defense-mode';
interface TopbarTransfersBannerSignature {
  Args: {
    close: () => void;
  };
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class TopbarTransfersBannerComponent extends Component<TopbarTransfersBannerSignature> {
  transferBannerComponent = TransfersBannerComponent;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::TransfersBanner': typeof TopbarTransfersBannerComponent;
  }
}
