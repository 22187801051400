/* import __COLOCATED_TEMPLATE__ from './kyc-in-app-waiting-document.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

interface KycInAppWaitingDocumentTopbarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class KycInAppWaitingDocumentTopbarComponent extends Component<KycInAppWaitingDocumentTopbarSignature> {
  topBanner = TopBanner;

  @service declare intl: Services['intl'];
  @service declare router: Services['router'];

  get text() {
    return this.intl.t('kyc.banner.fourthline.failed-identity-verification.body', {
      kycFlowLink: this.intl.t('kyc.banner.fourthline.failed-identity-verification.link'),
    });
  }

  get content() {
    return {
      type: 'warning',
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::KycInAppWaitingDocument': typeof KycInAppWaitingDocumentTopbarComponent;
  }
}
