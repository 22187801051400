/* import __COLOCATED_TEMPLATE__ from './credit-note-details.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CreditNoteDetailsSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const CreditNoteDetails = templateOnlyComponent<CreditNoteDetailsSignature>();

export default CreditNoteDetails;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Experiments::CreditNoteDetails': typeof CreditNoteDetails;
  }
}
