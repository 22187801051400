/* import __COLOCATED_TEMPLATE__ from './cancel-closure.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface CancelClosureTopBarSignature {
  Args: {
    content: {
      message: string;
    };
    close: () => void;
  };
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class CancelClosureTopBar extends Component<CancelClosureTopBarSignature> {
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  topBanner = TopBanner;

  constructor(owner: unknown, args: CancelClosureTopBarSignature['Args']) {
    super(owner, args);
    this.segment.track('account-closing_status-banner_displayed', {
      Status: 'Cancel closure',
    });
  }

  cancelDeactivationTask = dropTask(async close => {
    this.segment.track('account_closing_cancel_closing_confirmed');
    try {
      await this.organizationManager.organization.cancelDeactivation();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('subscription.account-closing.cancel-closing.closing-cancelled')
      );
      await close();
      this.args.close();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  showCancelDeactivationModalTask = dropTask(async () => {
    this.segment.track('account_closing_cancel_closing_clicked');
    await this.modals.open('popup/confirmation', {
      title: this.intl.t('subscription.account-closing.cancel-closing.title'),
      description: this.intl.t('subscription.account-closing.cancel-closing.description'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.confirm'),
      confirmTask: this.cancelDeactivationTask,
    });
  });
}
