export default {
  "row": "qBi",
  "active": "qBA",
  "dot": "qBY",
  "red": "qBh",
  "green": "qBf",
  "yellow": "qBK",
  "align-right": "qBG",
  "animated": "qBr",
  "fadein-item": "qBb",
  "cell": "qBy body-2",
  "cell-content": "qBU",
  "amount": "qBj body-1",
  "subtitle": "qBW caption",
  "status": "qBu",
  "cell-amount": "qES"
};
