/* import __COLOCATED_TEMPLATE__ from './invoice-preview.hbs'; */
import Component from '@glimmer/component';

import type { Attachment } from 'qonto/react/models/transaction';

interface Invoice {
  attachment: Attachment;
  displayAttachment?: Attachment | null;
  isAttachmentNonFinancial: boolean;
  isGermanEInvoice: boolean;
}

interface Signature {
  Args: {
    context: {
      invoice?: Invoice | null;
    };
    isTopAligned?: boolean;
    withDisclaimer?: boolean;
    withStickyPanel?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class TransfersSepaInvoicePreviewProvider extends Component<Signature> {
  get isTopAligned(): boolean {
    return this.args.isTopAligned ?? false;
  }

  get withDisclaimer(): boolean {
    return this.args.withDisclaimer ?? false;
  }

  get withStickyPanel(): boolean {
    return this.args.withStickyPanel ?? false;
  }

  get invoiceAttachmentFile(): Invoice['attachment']['file'] | null {
    return (
      this.args.context.invoice?.displayAttachment?.file ??
      this.args.context.invoice?.attachment.file ??
      null
    );
  }

  get isPdfPreviewWithNoToolbar(): boolean {
    return (
      Boolean(this.args.context.invoice?.attachment.id) &&
      Boolean(this.args.context.invoice?.displayAttachment?.id) &&
      this.args.context.invoice?.attachment.id !== this.args.context.invoice?.displayAttachment?.id
    );
  }

  get isInvalidGermanEInvoice(): boolean {
    return (
      Boolean(this.args.context.invoice?.isAttachmentNonFinancial) &&
      Boolean(this.args.context.invoice?.isGermanEInvoice)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InvoicePreview': typeof TransfersSepaInvoicePreviewProvider;
  }
}
