/* import __COLOCATED_TEMPLATE__ from './fetch-transactions.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { DEFAULT_SEARCH_INCLUDES } from 'qonto/constants/transactions';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FetchTransactionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FetchTransactionsComponent extends Component<FetchTransactionsSignature> {
  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];

  constructor(owner: unknown, args: FetchTransactionsSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.fetchTransactions();
  }

  @action
  // @ts-expect-error
  fetchTransactions(searchQuery) {
    this.fetchTransactionsTask
      .perform(searchQuery)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      });
  }

  fetchTransactionsTask = task(this, { restartable: true }, async (searchQuery = '') => {
    await timeout(DEBOUNCE_MS);

    // @ts-expect-error
    let { transactions } = await this.store.adapterFor('transaction').search({
      includes: DEFAULT_SEARCH_INCLUDES,
      // @ts-expect-error
      filter_group: this.args.filterGroup,
      sort: { property: 'emitted_at', direction: 'desc' },
      pagination: { page: 1, per_page: 20 },
      search: searchQuery,
      organization_id: this.organizationManager.organization.id,
    });

    return transactions;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Invoices::FetchTransactions': typeof FetchTransactionsComponent;
  }
}
