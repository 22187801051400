/* import __COLOCATED_TEMPLATE__ from './top-banner.hbs'; */
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

interface TopBannerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopBannerComponent extends Component<TopBannerSignature> {
  topBanner = TopBanner;

  get text() {
    // @ts-expect-error
    return String(this.args.content.message);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TopBanner: typeof TopBannerComponent;
  }
}
