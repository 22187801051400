/* import __COLOCATED_TEMPLATE__ from './confirmation.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { assert } from '@ember/debug';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import {
  getRequestTransferOptions,
  prepareLimitsData,
  reassignAttachmentsBetweenModels,
} from 'qonto/utils/transfers';

export const MISSING_DATA_CONTEXT_ERROR_MESSAGE = 'A data context object must be provided';

interface TransfersSepaConfirmationProviderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersSepaConfirmationProvider extends Component<TransfersSepaConfirmationProviderSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];

  constructor(owner: unknown, args: TransfersSepaConfirmationProviderSignature['Args']) {
    super(owner, args);
    assert(MISSING_DATA_CONTEXT_ERROR_MESSAGE, this.dataContext);
  }

  /**
   * Returns the data context of the flow the provider is used in.
   */
  get dataContext() {
    // @ts-expect-error
    return this.args.context;
  }

  /**
   * Confirms a SEPA transfer and handles the confirmation result.
   *
   * @param {Transfer} transfer - The transfer to be confirmed.
   * @returns {Promise<Object>} A promise that resolves to the confirmation result.
   */
  confirmTransferTask = task(async transfer => {
    try {
      let confirmationResult = await transfer.confirm();
      this._handleTransferConfirmationResult(confirmationResult, transfer);
      return confirmationResult;
    } catch (error) {
      let errorMessage = this._getErrorMessageFromError(error);
      this.toastFlashMessages.toastError(errorMessage);
      let errorInfo = ErrorInfo.for(error);
      // @ts-expect-error
      if (error.status !== 422 && errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  /**
   * Converts a transfer object into a request transfer object.
   *
   * @param {Transfer} transfer - The transfer object to be converted.
   * @returns {RequestTransfer} The converted request transfer object.
   */
  // @ts-expect-error
  _convertTransferToRequest(transfer) {
    let options = getRequestTransferOptions(transfer);
    let requestTransferRecord = this.store.createRecord('request-transfer', options);
    reassignAttachmentsBetweenModels(transfer, requestTransferRecord);

    return requestTransferRecord;
  }

  /**
   * Retrieves the error message from the provided error object.
   * Default is a generic server error message.
   *
   * @param {Error} error - The error object.
   * @returns {string} The error message.
   */
  // @ts-expect-error
  _getErrorMessageFromError(error) {
    let errorMessage = this.intl.t('toasts.errors.server_error');

    // @ts-expect-error
    if (error instanceof InvalidError && error.errors) {
      // @ts-expect-error
      let [invalidError] = error.errors;
      if (invalidError?.detail) {
        errorMessage = invalidError.detail;
      }
    }

    return errorMessage;
  }

  /**
   * Handles the transfer confirmation result by updating the context and taking appropriate actions
   * if the transfer amount exceeeds the transfer spending limits.
   *
   * @param {Object} confirmationResult - The confirmation result object.
   * @param {Transfer} submittedTransfer - The submitted transfer object.
   */
  // @ts-expect-error
  _handleTransferConfirmationResult(confirmationResult, submittedTransfer) {
    let { dataContext } = this;
    let { transferRequest } = dataContext;

    dataContext.confirmationResult = confirmationResult;

    if (transferRequest) {
      this._resetTransferRequest();
    }

    let isAboveSpendingLimits = this._isTransferAmountAboveSpendingLimits(confirmationResult);

    if (isAboveSpendingLimits) {
      this._setTransferRequestFromTransfer(submittedTransfer);
    }
  }

  /**
   * Checks if the transfer amount is above the spending limits based on the transfer confirmation result.
   *
   * @param {Object} confirmationResult - The transfer confirmation result object.
   * @returns {boolean} True if the transfer amount is above the spending limits, false otherwise.
   */
  // @ts-expect-error
  _isTransferAmountAboveSpendingLimits({ spendLimits, warnings }) {
    let { isAboveLimits } = prepareLimitsData(warnings, spendLimits);
    return isAboveLimits;
  }

  /**
   * Unload transfer request record and set context attribute to default null value.
   */
  _resetTransferRequest() {
    let { dataContext } = this;
    dataContext.transferRequest?.unloadRecord();
    dataContext.transferRequest = null;
  }

  /**
   * Creates and sets a transfer request object in the data context based on the provided transfer object.
   *
   * @param {Transfer} transfer - The transfer object to set the transfer request from.
   */
  // @ts-expect-error
  _setTransferRequestFromTransfer(transfer) {
    this.dataContext.transferRequest = this._convertTransferToRequest(transfer);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::Providers::Confirmation': typeof TransfersSepaConfirmationProvider;
  }
}
