/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BaseSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Base = templateOnlyComponent<BaseSignature>();

export default Base;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::Base': typeof Base;
  }
}
