/* import __COLOCATED_TEMPLATE__ from './empty-timeline-section.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

interface EmptyTimelineSectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class EmptyTimelineSection extends Component<EmptyTimelineSectionSignature> {
  @service declare intl: Services['intl'];

  badge = Badge;

  get newBadgeProps() {
    return {
      type: 'newFeature',
      text: this.intl.t('supplier-invoices.edit.modal.approval-workflows.badge'),
      'data-test-empty-timeline-section-new-badge': '',
    };
  }

  get shouldDisplayNewBadge() {
    return variation('feature--boolean-approval-workflows-empty-timeline-badge');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::EditForm::EmptyTimelineSection': typeof EmptyTimelineSection;
  }
}
