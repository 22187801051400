/* import __COLOCATED_TEMPLATE__ from './kyc-kyb-pending-update.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const KYCB_UPDATE_BANNER_STORAGE_KEY = 'PERIODIC_KYBC_UPDATE_TOPBANNER_DISMISSED';

interface KycKybPendingUpdateTopBarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class KycKybPendingUpdateTopBar extends Component<KycKybPendingUpdateTopBarSignature> {
  topBanner = TopBanner;

  @action close() {
    // @ts-expect-error
    let { updateID } = this.args.content;

    safeLocalStorage.setItem(KYCB_UPDATE_BANNER_STORAGE_KEY, updateID);
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::KycKybPendingUpdate': typeof KycKybPendingUpdateTopBar;
  }
}
