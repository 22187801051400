export default {
  "form": "qqP",
  "form-title": "qql title2",
  "row": "qqX",
  "col": "qqC",
  "actions": "qqk",
  "buttons": "qqJ",
  "collapsible-timeline": "qqs",
  "link": "qqF",
  "timeline-status-label": "qqg",
  "section": "qqD",
  "einvoicing-disclaimer": "qqV",
  "disclaimer-section": "qqL qqD",
  "related-documents-card-margin-top": "qqT",
  "my-24": "qqz"
};
