/* import __COLOCATED_TEMPLATE__ from './static-mandate-mockup.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface StaticMandateMockupSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const StaticMandateMockup = templateOnlyComponent<StaticMandateMockupSignature>();

export default StaticMandateMockup;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    StaticMandateMockup: typeof StaticMandateMockup;
  }
}
