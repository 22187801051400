/* import __COLOCATED_TEMPLATE__ from './show-invoice-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ShowInvoiceModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ShowInvoiceModalComponent extends Component<ShowInvoiceModalSignature> {
  @service declare router: Services['router'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get title() {
    return this.intl.t('self-billing.view-modal.title', {
      // @ts-expect-error
      selfInvoiceNumber: this.args.data.selfInvoice.number,
    });
  }

  @action
  goBack() {
    this.segment.track('self-invoice_options_escape');
    this.router.transitionTo(
      'supplier-invoices.show',
      // @ts-expect-error
      this.args.data.selfInvoice.belongsTo('supplierInvoice').id()
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::ShowInvoiceModal': typeof ShowInvoiceModalComponent;
  }
}
