/* import __COLOCATED_TEMPLATE__ from './picto.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PictoSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Picto = templateOnlyComponent<PictoSignature>();

export default Picto;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::Picto': typeof Picto;
  }
}
