/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';

interface SuppliersTableHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class SuppliersTableHeaderComponent extends Component<SuppliersTableHeaderSignature> {
  get isUnarchived() {
    // @ts-expect-error
    return this.args.status === SUPPLIER_STATUSES.UNARCHIVED;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Suppliers::Table::Header': typeof SuppliersTableHeaderComponent;
  }
}
