export default {
  "progress-container": "qEA",
  "line-container": "qEY",
  "dashed-line": "qEh",
  "solid-line": "qEf",
  "circle-container": "qEK",
  "circle": "qEG",
  "current-step-dot": "qEr",
  "success-icon": "qEb",
  "rejected-icon": "qEy"
};
