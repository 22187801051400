/* import __COLOCATED_TEMPLATE__ from './membership.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MembershipSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Membership = templateOnlyComponent<MembershipSignature>();

export default Membership;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SidebarInfoBox::Row::Membership': typeof Membership;
  }
}
