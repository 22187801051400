/* import __COLOCATED_TEMPLATE__ from './org-deactivation-suspended-expired-due-diligence.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

interface orgDeactivationSuspendedExpiredDueDiligenceTopbarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class orgDeactivationSuspendedExpiredDueDiligenceTopbarComponent extends Component<orgDeactivationSuspendedExpiredDueDiligenceTopbarSignature> {
  topBanner = TopBanner;

  @service declare intl: Services['intl'];
  @service declare router: Services['router'];

  get intro() {
    return this.intl.t('subscription.account-closing.suspended-failed-kyb-update.intro');
  }

  get cta() {
    return this.intl.t('subscription.account-closing.suspended-failed-kyb-update.cta');
  }

  get outro() {
    return this.intl.t('subscription.account-closing.suspended-failed-kyb-update.outro');
  }

  get content() {
    return {
      type: 'warning',
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::OrgDeactivationSuspendedExpiredDueDiligence': typeof orgDeactivationSuspendedExpiredDueDiligenceTopbarComponent;
  }
}
