/* import __COLOCATED_TEMPLATE__ from './optional-nationality.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface OptionalNationalitySignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const OptionalNationality = templateOnlyComponent<OptionalNationalitySignature>();

export default OptionalNationality;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Select::OptionalNationality': typeof OptionalNationality;
  }
}
