/* import __COLOCATED_TEMPLATE__ from './financing-installment.hbs'; */
import Component from '@glimmer/component';

import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

interface TransactionsSidebarHeaderFinancingInstallmentSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSidebarHeaderFinancingInstallment extends Component<TransactionsSidebarHeaderFinancingInstallmentSignature> {
  Counterparty = Counterparty;
  Date = Date;
  type = Type;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::FinancingInstallment': typeof TransactionsSidebarHeaderFinancingInstallment;
  }
}
