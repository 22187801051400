export default {
  "header-cell": "qkO",
  "empty": "qkm",
  "header-type": "qkp",
  "header-requester": "qkx",
  "header-amount": "qkw",
  "header-approval": "qkN",
  "header-content": "qkv caption-bold",
  "active": "qko",
  "row-compact": "qkn",
  "row-sidebar": "qki",
  "hidden": "qkA",
  "animated": "qkY",
  "fadein-item": "qkh",
  "animated-cell": "qkf"
};
