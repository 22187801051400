/* import __COLOCATED_TEMPLATE__ from './unarchive-popup.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface UnarchivePopupSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class UnarchivePopup extends Component<UnarchivePopupSignature> {
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  unarchiveTask = dropTask(async close => {
    // @ts-expect-error
    let { supplier } = this.args.data;
    try {
      await supplier.unarchive();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-list.unarchived-suplier.success-toast')
      );
      this.segment.track('unarchive-supplier_submit', { result: 'success' });
      this.router.transitionTo('suppliers', {
        queryParams: {
          status: SUPPLIER_STATUSES.UNARCHIVED,
          highlighted: undefined,
        },
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.segment.track('unarchive-supplier_submit', { result: 'error' });
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      this.router.transitionTo('suppliers');
    } finally {
      close();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Suppliers::UnarchivePopup': typeof UnarchivePopup;
  }
}
