/* import __COLOCATED_TEMPLATE__ from './information.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

interface SavingsAccountsStepsInformationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SavingsAccountsStepsInformationComponent extends Component<SavingsAccountsStepsInformationSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @tracked sourceOfFundsError = null;
  @tracked fundOriginOtherError = null;

  get sourceOfFundsOptions() {
    return [
      {
        code: 'asset_disposal',
        label: this.intl.t('accounts.savings.creation.information.choice1'),
      },
      { code: 'cash_excess', label: this.intl.t('accounts.savings.creation.information.choice2') },
      { code: 'other', label: this.intl.t('accounts.savings.creation.information.choice3') },
    ];
  }

  @action
  // @ts-expect-error
  handleUpdateSourceOfFundsOption(value) {
    // @ts-expect-error
    this.sourceOfFundsError = false;
    // @ts-expect-error
    this.args.context.updateSourceOfFundsOption(value);
  }

  @action
  // @ts-expect-error
  handleUpdateFundOriginOther(value) {
    // @ts-expect-error
    this.fundOriginOtherError = false;
    // @ts-expect-error
    this.args.context.updateFundOriginOther(value);
  }

  handleNextTask = dropTask(async () => {
    // @ts-expect-error
    if (!this.args.context.sourceOfFunds) {
      // @ts-expect-error
      this.sourceOfFundsError = true;
      // @ts-expect-error
    } else if (this.args.context.sourceOfFunds === 'other' && !this.args.context.fundOriginOther) {
      // @ts-expect-error
      this.fundOriginOtherError = true;
    } else {
      try {
        // @ts-expect-error
        await this.args.context.createProcedure();
        this.segment.track('savings_source_funds_cta');
        // @ts-expect-error
        this.args.transitionToNext();
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::Steps::Information': typeof SavingsAccountsStepsInformationComponent;
  }
}
