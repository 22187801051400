/* import __COLOCATED_TEMPLATE__ from './uploader.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { apiBaseURL } from 'qonto/constants/hosts';
import isFunction from 'qonto/utils/is-function';
// @ts-expect-error
import pushPayload from 'qonto/utils/store-push-payload';

const DEFAULT_MAX_SIZE = 5 * 1e6; // 5 MB
const DEFAULT_UPLOAD_URL = 'v3/files';

interface UploaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class UploaderComponent extends Component<UploaderSignature> {
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare store: Services['store'];
  @service declare sentry: Services['sentry'];

  @tracked hiddenDropZone = false;
  // @ts-expect-error
  @tracked errors = this.args.errors ?? new TrackedArray();

  constructor(owner: unknown, args: UploaderSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    if (this.args.errors) {
      assert(
        'if provided, @errors must be a TrackedArray',
        // @ts-expect-error
        this.args.errors instanceof TrackedArray
      );
    }

    // @ts-expect-error
    assert('You must pass @files with a valid array of Files', Array.isArray(this.args.files));
    assert(
      'You must pass @onFileUploaded with a callback function called when each file is uploaded',
      // @ts-expect-error
      isFunction(this.args.onFileUploaded)
    );
    // @ts-expect-error
    if (this.args.uploadOptions?.model) {
      // @ts-expect-error
      if (!['attachment', 'file'].includes(this.args.uploadOptions?.model)) {
        throw new Error('The @uploadOptions.model accepts only `file` or `attachment` models');
      }
    }

    // @ts-expect-error
    this.hiddenDropZone = this.args.files?.length >= this.args.filesLimit;
  }

  get queueName() {
    // @ts-expect-error
    return this.args.queueName ?? guidFor(this);
  }

  get maxSize() {
    // @ts-expect-error
    return this.args.maxSize ?? DEFAULT_MAX_SIZE;
  }

  get text() {
    // @ts-expect-error
    return this.args.dropZoneLabel ?? this.defaultUploaderText;
  }

  get defaultUploaderText() {
    // @ts-expect-error
    return this.args.filesLimit === 1
      ? this.intl.t('join-team.about-you.add-poi.drop-zone.label', {
          maxSize: formatFileSize(this.intl, this.maxSize),
        })
      : this.intl.t('labels.upload-message', {
          maxSize: formatFileSize(this.intl, this.maxSize),
        });
  }

  get accept() {
    // @ts-expect-error
    return this.args.extensions ?? '.pdf,.jpg,.jpeg,.png,.gif';
  }

  get uploadUrl() {
    return this.uploadOptions?.url
      ? `${apiBaseURL}/${this.uploadOptions.url}`
      : `${apiBaseURL}/${DEFAULT_UPLOAD_URL}`;
  }

  get onFileUploadedHideDropzone() {
    // @ts-expect-error
    return this.args.onFileUploadedHideDropzone;
  }

  get callEndpoint() {
    // if callEndpoint is explicitly set, use it, otherwise default to true
    // @ts-expect-error
    if (this.args?.uploadOptions?.callEndpoint === undefined) {
      return true;
    } else {
      // @ts-expect-error
      return this.args?.uploadOptions?.callEndpoint;
    }
  }

  get uploadOptions() {
    // @ts-expect-error
    return this.args.uploadOptions;
  }

  // @ts-expect-error
  @action onCancelFile(file) {
    this.uploadTask.cancelAll();
    file?.queue?.remove(file);
    this.hiddenDropZone = false;
    // @ts-expect-error
    this.args.onCancelFile?.(file);
  }

  // @ts-expect-error
  handleLocalFileUpload(file) {
    let documentModel = this.store.createRecord('document', { filename: file.name });
    // @ts-expect-error
    this.args.onFileUploaded(file, documentModel);
    file?.queue?.remove(file);
  }

  // @ts-expect-error
  async handleRemoteFileUpload(file) {
    let newFileResponse = await file.upload(this.uploadUrl, {
      withCredentials: true,
      headers: this.networkManager.requestXHeaders,
      ...this.uploadOptions?.payload,
    });
    let payload = await newFileResponse.json();

    let fileModel, attachment;

    if (this.uploadOptions?.model === 'attachment') {
      attachment = pushPayload(this.store, 'attachment', {
        attachments: payload.attachment,
      });
      fileModel = file;
    } else {
      fileModel = pushPayload(this.store, 'file', payload);
    }

    // @ts-expect-error
    this.args.onFileUploaded(fileModel, attachment);
  }

  uploadTask = task(
    waitFor(async file => {
      this.hiddenDropZone = this.onFileUploadedHideDropzone;

      this.errors.length = 0;

      if (this.tooManyFilesToUpload(file)) {
        this.errors.push({
          // @ts-expect-error
          message: this.intl.t('errors.files_limit', { filesLimit: this.args.filesLimit }),
        });

        this.hiddenDropZone = false;
        file.queue.remove(file);
        // @ts-expect-error
        this.args.onFileUploadedErrors?.(this.errors);
        return;
      }

      if (!this._validateFileSize(file)) {
        // @ts-expect-error
        if (this.args.fileTooBigMessage) {
          this.errors.push({
            // @ts-expect-error
            message: this.args.fileTooBigMessage,
          });
        } else {
          this.errors.push({
            file: file.name,
            message: this.intl.t('uploader.file_too_big', {
              maxSize: formatFileSize(this.intl, this.maxSize),
            }),
          });
        }

        this.hiddenDropZone = false;
        file.queue.remove(file);
        // @ts-expect-error
        this.args.onFileUploadedErrors?.(this.errors);
        return;
      }

      try {
        if (!this.callEndpoint) {
          this.handleLocalFileUpload(file);
        } else {
          await this.handleRemoteFileUpload(file);
        }
        // TODO we should probably filter error messages
      } catch (error) {
        this.sentry.captureException(error);
        this.errors.push({ file: file.name, message: this.intl.t('uploader.server_error') });
        file?.queue?.remove(file);
        this.hiddenDropZone = false;
        // @ts-expect-error
        this.args.onFileUploadedErrors?.(error);
      }
    })
  );

  // @ts-expect-error
  _validateFileSize(file) {
    return file.size < this.maxSize;
  }

  // @ts-expect-error
  tooManyFilesToUpload(file) {
    // @ts-expect-error
    let { files, filesLimit } = this.args;

    let shouldUseFilesLimit = Boolean(filesLimit);
    if (!shouldUseFilesLimit) {
      return false;
    }

    let toManyFiles = files.length + file.queue.files.length > filesLimit;

    return toManyFiles;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Uploader: typeof UploaderComponent;
  }
}
