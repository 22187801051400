/* import __COLOCATED_TEMPLATE__ from './credit-notes-preview-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { PdfPreviewCarousel } from '@repo/domain-kit/accounts-payable';
import window from 'ember-window-mock';

import { INVOICE_SOURCES, PREVIEW_TYPES } from 'qonto/constants/supplier-invoice.ts';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { RelatedDocuments } from 'qonto/react/components/supplier-invoices/related-documents';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const MAX_CREDIT_NOTES = 5;

export interface CarouselOptions {
  align?: 'start' | 'center' | 'end';
  watchDrag?: boolean;
}

export default class SupplierInvoicesSuggestedCreditNotesModalComponent extends Component {
  @tracked selectedIndex = 0;

  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];

  spinner = Spinner;
  pdfPreviewCarousel = PdfPreviewCarousel;
  relatedDocuments = RelatedDocuments;

  get selectedCreditNote() {
    return this.creditNotes[this.selectedIndex];
  }

  get invoice() {
    // @ts-expect-error
    if (this.args.data.type === PREVIEW_TYPES.SUGGESTED) return null;
    // @ts-expect-error
    let supplierInvoice = this.args.data.fetchTask.lastSuccessful?.value || {};

    return {
      ...supplierInvoice,
      isCreditNote: true,
      relatedInvoices: [
        {
          id: supplierInvoice.id,
          invoice_number: supplierInvoice?.invoiceNumber,
          total_amount: supplierInvoice?.totalAmount,
          isCreditNote: false,
        },
      ],
    };
  }

  limitToMaxCreditNotes(value: SupplierInvoiceModel[]) {
    return (value ?? []).slice(0, MAX_CREDIT_NOTES);
  }

  get creditNotes() {
    // @ts-expect-error
    if (this.args.data.type === PREVIEW_TYPES.RELATED) {
      return (
        this.limitToMaxCreditNotes(
          // @ts-expect-error
          this.args.data.fetchTask.lastSuccessful?.value?.relatedInvoices
        ) || []
      );
    }
    // @ts-expect-error
    return this.limitToMaxCreditNotes(this.args.data.fetchTask.lastSuccessful?.value);
  }

  get attachmentUrl() {
    return this.selectedCreditNote?.attachment?.file?.fileUrl || '';
  }

  get attachmentType() {
    return this.selectedCreditNote?.attachment?.file?.fileContentType || '';
  }

  get currentCreditNoteOrder() {
    if (!this.selectedCreditNote) {
      return;
    }
    return this.creditNotes.indexOf(this.selectedCreditNote) + 1;
  }

  get isLoading() {
    // @ts-expect-error
    return this.args.data?.fetchTask?.isRunning;
  }

  get isLinkTaskRunning() {
    // @ts-expect-error
    return this.args.data?.linkToInvoiceTask?.isRunning;
  }

  get carouselOptions(): CarouselOptions {
    return {
      align: 'start',
      watchDrag: false,
    };
  }

  get organisationLegalCountry() {
    return this.organizationManager.organization?.legalCountry;
  }

  @action
  onClose() {
    window.history.back();
    // @ts-expect-error
    this.args.close();
  }

  @action
  onLinkCTA() {
    // @ts-expect-error
    let { invoiceId } = this.args.data;
    // @ts-expect-error
    this.args.data.linkToInvoiceTask
      // @ts-expect-error
      .perform(invoiceId, this.selectedCreditNote.id)
      .catch(ignoreCancelation);

    this.router.transitionTo('supplier-invoices.show', invoiceId, {
      queryParams: { reload: true },
    });

    this.toastFlashMessages.toastSuccess(
      this.intl.t('supplier-invoices.invoice-linked-to-credit-note.toast')
    );

    this.segment.track('supplier-invoices_suggested-CN_submitted', {
      is_einvoice: this.selectedCreditNote?.isElectronicInvoice ?? false,
    });
  }

  @action
  handleSlideChange(index: number) {
    this.selectedIndex = index;
  }
}
