/* import __COLOCATED_TEMPLATE__ from './settings-loading.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

interface SettingsLoadingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SettingsLoading extends Component<SettingsLoadingSignature> {
  placeholderLine = SkeletonLoader.Line;
  placeholderBlock = SkeletonLoader.Block;
  placeholderHeader = SkeletonLoader.Header;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SettingsLoading: typeof SettingsLoading;
  }
}
