export default {
  "insight-pill": "qZk",
  "insight-pill-figure": "qZJ",
  "insight-pill-renderer": "qZs",
  "loading": "qZF",
  "counter": "qZg",
  "error": "qZD",
  "insight-pill-label": "qZV",
  "insight-pill-subtitle": "qZL caption",
  "hidden": "qZT",
  "missing-amounts-warning": "qZz",
  "missing-amounts": "qZa"
};
