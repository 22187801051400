export default {
  "header": "qSa",
  "subtitle": "qSH body-2",
  "date": "qSO",
  "subtitle-error": "qSm",
  "card": "qSp",
  "card-header": "qSx",
  "line-placeholder": "qSw",
  "post-closing-list": "qSN",
  "post-closing-container": "qSv",
  "post-closing-list-element": "qSo",
  "free-trial-disclaimer": "qSn",
  "description": "qSi body-2",
  "mw-100": "qSA",
  "addon-overview": "qSY",
  "addons-container": "qSh"
};
