/* import __COLOCATED_TEMPLATE__ from './deactivated-account-blocker-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface AccountBlockerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AccountBlockerComponent extends Component<AccountBlockerSignature> {
  lottiePlayer = LottiePlayer;

  @service declare segment: Services['segment'];

  animationReady = false;

  constructor(owner: unknown, args: AccountBlockerSignature['Args']) {
    super(owner, args);

    this.segment.track('organization_deactivated_displayed');
  }

  @action
  close() {
    // @ts-expect-error
    this.args.close?.();
    // @ts-expect-error
    this.args.data?.onCloseModal?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::DeactivatedAccountBlockerModal': typeof AccountBlockerComponent;
  }
}
