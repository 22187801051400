export default {
  "sidebar-content": "qXL",
  "section": "qXT",
  "header-wrapper": "qXz",
  "transfer-id": "qXa caption",
  "small-padding": "qXH",
  "title": "qXO",
  "subtitle": "qXm",
  "actions": "qXp"
};
