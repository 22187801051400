export default {
  "team-card": "qEp",
  "card-link": "qEx",
  "shadow": "qEw",
  "history-button": "qEN",
  "cards-button": "qEv",
  "menu-item": "qEo",
  "subtitle": "qEn",
  "delete-team": "qEi"
};
