/* import __COLOCATED_TEMPLATE__ from './savings-accounts.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { SORT_BY, STATUS } from 'qonto/constants/savings-account';
// @ts-expect-error
import { sortByKeys } from 'qonto/utils/sort-by-keys';

// @ts-expect-error
function activeOrClosingAccount({ status }) {
  return [STATUS.ACTIVE, STATUS.CLOSING].includes(status);
}

interface SavingsAccountsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SavingsAccountsComponent extends Component<SavingsAccountsSignature> {
  get savingsAccountsBalance() {
    // @ts-expect-error
    return this.args.savingsAccounts.reduce((sum, { amount, closureExpectedAmount, status }) => {
      if (status === STATUS.ACTIVE) {
        return sum + amount;
      } else if (status === STATUS.CLOSING) {
        return sum + closureExpectedAmount;
      }
      return sum;
    }, 0);
  }

  get activeOrClosingAccounts() {
    // @ts-expect-error
    return this.args.savingsAccounts.filter(activeOrClosingAccount);
  }

  get savingsAccountsSorted() {
    // @ts-expect-error
    let savingsAccounts = [...this.args.savingsAccounts];
    return savingsAccounts.sort(sortByKeys(SORT_BY));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SavingsAccounts: typeof SavingsAccountsComponent;
  }
}
