/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface TransferSidebarFooterSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransferSidebarFooter extends Component<TransferSidebarFooterSignature> {
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare abilities: Services['abilities'];
  @service declare segment: Services['segment'];

  showCancelModalTask = dropTask(async () => {
    this.segment.track('transfer_list_open_side_panel_cancel', {
      // @ts-expect-error
      transfer_type: this.args.transfer.isInternationalOut ? 'swift' : 'sepa',
    });

    await this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.modals.cancel-transfer.title'),
      description: this.intl.t('transfers.modals.cancel-transfer.body'),
      cancel: this.intl.t('transfers.modals.cancel-transfer.cancel-button'),
      confirm: this.intl.t('transfers.modals.cancel-transfer.confirm-button'),
      confirmTask: this.cancelTransferTask,
    });
  });

  cancelTransferTask = dropTask(async close => {
    // @ts-expect-error
    await this.args.cancelTransfer.linked().perform(this.args.transfer, close);
  });

  get areBothButtonsVisible() {
    // @ts-expect-error
    let { editable, cancelable } = this.args.transfer;
    let isEditable = this.abilities.can('update transfer') && editable;
    let isCancelable = this.abilities.can('update transfer') && cancelable;

    return isEditable && isCancelable;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TransferSidebar::Footer': typeof TransferSidebarFooter;
  }
}
