/* import __COLOCATED_TEMPLATE__ from './wizard-header.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface WizardHeaderSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const WizardHeader = templateOnlyComponent<WizardHeaderSignature>();

export default WizardHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    WizardHeader: typeof WizardHeader;
  }
}
