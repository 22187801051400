/* import __COLOCATED_TEMPLATE__ from './select-supplier.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { isTesting, macroCondition } from '@embroider/macros';
import { restartableTask, timeout } from 'ember-concurrency';

const DEBOUNCE_TIMEOUT = 200;

interface SelectSupplierSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SelectSupplier extends Component<SelectSupplierSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];

  get suppliers() {
    return this.handleInputTask.lastSuccessful?.value || [];
  }

  @action
  // @ts-expect-error
  onBlur(dropdown) {
    dropdown.actions.close();
  }

  handleInputTask = restartableTask(async value => {
    // @ts-expect-error
    this.args.update(value);

    if (!value) {
      return [];
    }

    if (macroCondition(!isTesting())) {
      await timeout(DEBOUNCE_TIMEOUT);
    }

    let inputValueOption = {
      name: value,
      customOptionMessage: this.intl.t(
        'supplier-invoices.edit.modal.supplier-details.supplier-name.supplier-not-found'
      ),
    };
    try {
      let { organization } = this.organizationManager;
      let suppliers = await this.store.query('beneficiary', {
        organization_id: organization.id,
        query: value,
      });
      return [inputValueOption, ...suppliers.map(({ name, iban, id }) => ({ name, iban, id }))];
    } catch {
      return [inputValueOption];
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::EditForm::SelectSupplier': typeof SelectSupplier;
  }
}
