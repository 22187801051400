export default {
  "row": "qeP",
  "greyed-out": "qel",
  "supplier-name": "qeX",
  "cell": "qeC",
  "supplier": "qek",
  "email-address": "qeJ",
  "outstanding-balance": "qes",
  "quick-actions": "qeF",
  "empty": "qeg",
  "supplier-container": "qeD",
  "grey-background": "qeV"
};
