/* import __COLOCATED_TEMPLATE__ from './searchable-list.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SearchFieldWithDebounce } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { restartableTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import styles from './searchable-list.strict-module.css';

interface SearchableListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SearchableListComponent extends Component<SearchableListSignature> {
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @tracked searchQuery;
  @tracked items = [];
  // @ts-expect-error
  @tracked selectedPeriod;
  searchField = SearchFieldWithDebounce;
  styles = styles;

  constructor(owner: unknown, args: SearchableListSignature['Args']) {
    super(owner, args);
    this.fetchItems();
  }

  get error() {
    return this.fetchItemsTask.last?.error;
  }

  get isLoading() {
    return this.fetchItemsTask.isRunning;
  }

  get isEmpty() {
    return this.items.length === 0;
  }

  @action
  // @ts-expect-error
  updateQuery(searchQuery) {
    this.searchQuery = searchQuery;
    return this.fetchItems();
  }

  @action
  // @ts-expect-error
  onChangePeriod(period) {
    this.selectedPeriod = period;
    // @ts-expect-error
    this.args.changePeriodSelection(period);
    return this.fetchItems();
  }

  @action cancelPeriodSelection() {
    this.selectedPeriod = null;
    // @ts-expect-error
    this.args.cancelPeriodSelection(null);
    return this.fetchItems();
  }

  @action
  fetchItems() {
    this.fetchItemsTask
      .perform(this.searchQuery)
      .catch(ignoreCancelation)
      .catch(error => {
        // We don't need a toast here because the error is handled in the template
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  fetchItemsTask = restartableTask(async (searchQuery = '') => {
    // @ts-expect-error
    this.items = await this.args.fetchItemsTask.linked().perform(searchQuery);
  });

  get maxDate() {
    return dayjs().toDate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SearchableList: typeof SearchableListComponent;
  }
}
