/* import __COLOCATED_TEMPLATE__ from './membership-info.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MembershipInfoSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const MembershipInfo = templateOnlyComponent<MembershipInfoSignature>();

export default MembershipInfo;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::MembershipInfo': typeof MembershipInfo;
  }
}
