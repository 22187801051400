/* import __COLOCATED_TEMPLATE__ from './kyc-in-app.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';
import dayjs from 'dayjs';

interface KycInAppTopbarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class KycInAppTopbarComponent extends Component<KycInAppTopbarSignature> {
  topBanner = TopBanner;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  get isInFirst3DaysPeriod() {
    let today = dayjs();
    return (
      Math.floor(today.diff(this.organizationManager.membership.createdAt, 'hours') / 24) + 1 < 3
    );
  }

  get text() {
    if (this.isInFirst3DaysPeriod) {
      return this.intl.t('kyc.banner.unsubmitted.first-nudge.body', {
        kycFlowLink: this.intl.t('kyc.banner.unsubmitted.first-nudge.link-text'),
      });
    }

    return this.intl.t('kyc.banner.unsubmitted.second-nudge.body', {
      kycFlowLink: this.intl.t('kyc.banner.unsubmitted.second-nudge.link-text'),
    });
  }

  get content() {
    return {
      type: this.isInFirst3DaysPeriod ? 'warning' : 'error',
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::KycInApp': typeof KycInAppTopbarComponent;
  }
}
