/* import __COLOCATED_TEMPLATE__ from './low-balance-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LowBalanceModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const LowBalanceModal = templateOnlyComponent<LowBalanceModalSignature>();

export default LowBalanceModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::PayLater::LowBalanceModal': typeof LowBalanceModal;
  }
}
