/* import __COLOCATED_TEMPLATE__ from './about.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { About } from 'qonto/react/components/savings-accounts/steps/about';

interface SavingsAccountsStepsAboutSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SavingsAccountsStepsAboutComponent extends Component<SavingsAccountsStepsAboutSignature> {
  about = About;

  @service declare segment: Services['segment'];

  @action
  handleNext() {
    this.segment.track('savings_about_cta');
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::Steps::About': typeof SavingsAccountsStepsAboutComponent;
  }
}
