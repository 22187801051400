/* import __COLOCATED_TEMPLATE__ from './beneficiary-card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { Information } from 'qonto/react/components/transfers/international-out/beneficiary-card/information';
import type { Beneficiary } from 'qonto/services/international-out/types';
import isFunction from 'qonto/utils/is-function';

interface Signature {
  Args: {
    beneficiary: Beneficiary;
    onDelete?: (beneficiary: Beneficiary) => Promise<void>;
    onSelect: (beneficiary: Beneficiary) => Promise<void>;
    onView?: (beneficiary: Beneficiary) => Promise<void>;
  };
  Element: HTMLElement;
}

export default class TransfersInternationalOutBeneficiaryCardComponent extends Component<Signature> {
  informationComponent = Information;

  @service declare abilities: Services['abilities'];

  get canDelete(): boolean {
    return isFunction(this.args.onDelete) && this.abilities.can('delete beneficiary');
  }

  get canView(): boolean {
    return isFunction(this.args.onView) && this.abilities.can('access beneficiary');
  }

  get hasActions(): boolean {
    return this.canDelete || this.canView;
  }

  onDelete = dropTask(async (beneficiary: Beneficiary): Promise<void> => {
    await this.args.onDelete?.(beneficiary);
  });

  onSelect = dropTask(async (beneficiary: Beneficiary): Promise<void> => {
    await this.args.onSelect(beneficiary);
  });

  onView = dropTask(async (beneficiary: Beneficiary): Promise<void> => {
    await this.args.onView?.(beneficiary);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::BeneficiaryCard': typeof TransfersInternationalOutBeneficiaryCardComponent;
  }
}
