/* import __COLOCATED_TEMPLATE__ from './beneficiary.hbs'; */
import Component from '@glimmer/component';

import { BeneficiaryCard } from 'qonto/react/components/transfers/sepa/beneficiary-card';
import { Info } from 'qonto/react/components/transfers/sepa/pay-by-invoice/beneficiary/info';

interface TransfersSepaPayByInvoiceBeneficiarySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransfersSepaPayByInvoiceBeneficiaryComponent extends Component<TransfersSepaPayByInvoiceBeneficiarySignature> {
  info = Info;
  beneficiaryCard = BeneficiaryCard;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::PayByInvoice::Beneficiary': typeof TransfersSepaPayByInvoiceBeneficiaryComponent;
  }
}
