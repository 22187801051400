/* import __COLOCATED_TEMPLATE__ from './kyc-missing-id-fourthline.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

interface TopbarKycMissingIdSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopbarKycMissingIdComponent extends Component<TopbarKycMissingIdSignature> {
  topBanner = TopBanner;

  @service declare organizationManager: Services['organizationManager'];

  @reads('organizationManager.membership.personalInfoProvided')
  // @ts-expect-error
  personalInfoProvided;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::KycMissingIdFourthline': typeof TopbarKycMissingIdComponent;
  }
}
