export default {
  "invoice-sidebar": "cWe",
  "body": "cWB",
  "box": "cWE",
  "box-header": "cWd",
  "sidebar-box": "cWI",
  "border-top": "cWt",
  "row": "cWM",
  "greyed-out": "cWP",
  "strike-through": "cWl"
};
