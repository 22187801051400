/* import __COLOCATED_TEMPLATE__ from './toast.hbs'; */
import Component from '@glimmer/component';

import { Toast } from '@repo/design-system-kit';

interface ToastSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ToastComponent extends Component<ToastSignature> {
  toast = Toast;

  get text() {
    // @ts-expect-error
    return String(this.args.content.message);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Toast: typeof ToastComponent;
  }
}
