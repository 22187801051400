/* import __COLOCATED_TEMPLATE__ from './review-duplicates-modal.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { isNil } from 'es-toolkit';
import { TrackedSet } from 'tracked-built-ins';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { GERMAN_INVOICE_FORMATS, INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { ItemSkeleton } from 'qonto/react/components/review-duplicates-modal/item-skeleton';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

const XMLFormat = 'xml';

interface ReviewDuplicatesModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReviewDuplicatesModal extends Component<ReviewDuplicatesModalSignature> {
  @service declare sentry: Services['sentry'];
  @service declare networkManager: Services['networkManager'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];

  @tracked showEmptySelectionError = false;
  selected = new TrackedSet([]);
  itemSkeleton = ItemSkeleton;

  constructor(owner: unknown, args: ReviewDuplicatesModalSignature['Args']) {
    super(owner, args);

    this.fetchDuplicatesTask
      .perform()
      .then(() => this.preselectDuplicates())
      .catch(ignoreCancelation);
  }

  get currentDocument() {
    return this.fetchDuplicatesTask.lastSuccessful?.value.current_document;
  }

  get duplicates() {
    return this.fetchDuplicatesTask.lastSuccessful?.value.duplicates;
  }

  get deletableDuplicates() {
    // @ts-expect-error
    return this.duplicates.filter(({ is_deletable }) => is_deletable);
  }

  get shouldDisplayTooltip() {
    let { lastSuccessful } = this.fetchDuplicatesTask;
    if (lastSuccessful) {
      // @ts-expect-error
      return this.duplicates.some(({ status }) => status !== INVOICE_STATUSES.toReview);
    }

    return false;
  }

  get isFetchError() {
    return this.fetchDuplicatesTask.last?.isError;
  }

  @action
  // @ts-expect-error
  isSelected(id) {
    // @ts-expect-error
    return this.selected.has(id);
  }

  @action
  // @ts-expect-error
  updateSelection(id) {
    // @ts-expect-error
    let fn = this.selected.has(id) ? 'delete' : 'add';
    // @ts-expect-error
    this.selected[fn](id);
  }

  @action
  // @ts-expect-error
  handleClose(origin) {
    this.segment.track('supplier-invoices_duplicates-modal_closed', {
      origin,
    });
    // @ts-expect-error
    this.args.close();
  }

  preselectDuplicates() {
    // @ts-expect-error
    if (!this.duplicates.every(el => el.is_deletable)) {
      // @ts-expect-error
      this.selected.add(this.currentDocument.id);
    }

    // @ts-expect-error
    this.duplicates.forEach(e => {
      if (e.is_deletable) {
        // @ts-expect-error
        this.selected.add(e.id);
      }
    });
  }

  fetchDuplicatesTask = dropTask(async () => {
    // @ts-expect-error
    let { invoiceId } = this.args.data;

    return await this.networkManager.request(
      `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/${invoiceId}/duplicates`
    );
  });

  confirmDeleteSelected = dropTask(async () => {
    if (this.selected.size === 0) {
      this.showEmptySelectionError = true;
      next(() => scrollIntoView('[data-has-error]'));
      return;
    }
    this.showEmptySelectionError = false;
    await this.modals.open('popup/destructive', {
      title: this.intl.t('supplier-invoices.modals.review-duplicates.confirm-deletion.title', {
        count: this.selected.size,
      }),
      description: this.intl.t(
        'supplier-invoices.modals.review-duplicates.confirm-deletion.subtitle',
        { count: this.selected.size }
      ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t(
        'supplier-invoices.modals.review-duplicates.confirm-deletion.confirm-cta',
        { count: this.selected.size }
      ),
      confirmTask: this.deleteDuplicatesTask,
    });
  });

  #trackDeleteEvent() {
    let listOfDeletableIds = [...this.deletableDuplicates, this.currentDocument].map(
      ({ id }) => id
    );

    // @ts-expect-error
    let remainingDeletableInvoices = listOfDeletableIds.filter(id => !this.selected.has(id)).length;

    let deletableEInvoices = [...this.deletableDuplicates, this.currentDocument].filter(
      invoice => invoice.is_einvoice
    );
    let keptEInvoices =
      deletableEInvoices.length > 0
        ? // @ts-expect-error
          deletableEInvoices.filter(eInvoice => !this.selected.has(eInvoice.id))
        : [];

    let keptInvoices;

    if (remainingDeletableInvoices === 1) {
      keptInvoices = 'one';
    } else if (remainingDeletableInvoices > 1) {
      keptInvoices = 'multiple';
    } else {
      keptInvoices = 'none';
    }

    this.handleEventLogging(keptInvoices, keptEInvoices);
  }

  // @ts-expect-error
  handleEventLogging(keptInvoicesCount, keptEInvoices) {
    // @ts-expect-error
    let { einvoice_type, invoice_format, is_einvoice } = this.args.data;
    let keptEInvoiceItems;

    keptEInvoiceItems = {
      kept_einvoices_number: keptEInvoices.length,
      kept_einvoices: this.getKeptEInvoiceFieldsList(keptEInvoices),
    };

    if (einvoice_type && invoice_format && !isNil(is_einvoice)) {
      this.segment.track('supplier-invoices_duplicates_deleted', {
        keptInvoices: keptInvoicesCount,
        is_einvoice,
        einvoice_type,
        invoice_format,
        ...keptEInvoiceItems,
      });
    } else {
      this.segment.track('supplier-invoices_duplicates_deleted', {
        keptInvoices: keptInvoicesCount,
      });
    }
  }

  // @ts-expect-error
  getKeptEInvoiceFieldsList(invoices) {
    // @ts-expect-error
    return invoices.map(invoice => {
      return {
        invoice_id: invoice.id,
        einvoice_type: this.getFileType(invoice.file_name),
        invoice_format: this.getInvoiceFormat(invoice),
      };
    });
  }

  // @ts-expect-error
  getInvoiceFormat(invoice) {
    let isGermanEInvoice =
      this.organizationManager.organization.legalCountry === 'DE' && invoice.is_einvoice;
    let eInvoiceType = null;

    if (isGermanEInvoice) {
      eInvoiceType =
        invoice.file_name && invoice.file_name.includes(XMLFormat)
          ? GERMAN_INVOICE_FORMATS.XRECHNUNG
          : GERMAN_INVOICE_FORMATS.ZUGFERD;
    }

    return eInvoiceType;
  }

  // @ts-expect-error
  getFileType(fileType) {
    if (!fileType) return null;

    let types = {
      image: /image/,
      pdf: /pdf/,
      xml: /xml/,
    };

    return Object.entries(types).find(entry => entry[1].test(fileType))?.[0] ?? null;
  }

  deleteDuplicatesTask = dropTask(async closeConfirmation => {
    this.#trackDeleteEvent();
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_delete`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: Array.from(this.selected),
          }),
        }
      );

      // @ts-expect-error
      let deletedCurrentDocument = this.selected.has(this.currentDocument.id);
      let deletedAllDuplicates =
        !deletedCurrentDocument &&
        // @ts-expect-error
        this.deletableDuplicates.every(({ id }) => this.selected.has(id));

      if (deletedAllDuplicates) {
        let supplierInvoice = this.store.peekRecord('supplier-invoice', this.currentDocument.id);
        // @ts-expect-error
        supplierInvoice.hasDuplicates = false;
      }

      if (deletedCurrentDocument) {
        this.router.transitionTo('supplier-invoices.index');
      }

      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-invoices.modals.review-duplicates.confirm-deletion.success-toast', {
          count: this.selected.size,
        })
      );
      await closeConfirmation();
      // @ts-expect-error
      this.args?.close();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      await closeConfirmation();
      this.toastFlashMessages.toastError(
        this.intl.t('supplier-invoices.modals.review-duplicates.errors.deletion-failed-toast', {
          count: this.selected.size,
        })
      );

      // @ts-expect-error
      if (errorInfo.shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::ReviewDuplicatesModal': typeof ReviewDuplicatesModal;
  }
}
