/* import __COLOCATED_TEMPLATE__ from './nationalities.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  addEmptyNationality,
  removeNationalityFromArray,
  updateNationalityInArray,
} from 'qonto/utils/update-nationalities';

interface SelectNationalitiesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

/**
 * ! This component duplicates the component in the `qonto-register-js` application.
 * ! Please make sure to keep them in sync. You can see the `qonto-register-js` component here:
 * ! https://gitlab.qonto.co/front/qonto-web/-/blob/master/apps/qonto-register-js/app/components/select/nationalities.js
 */
export default class SelectNationalitiesComponent extends Component<SelectNationalitiesSignature> {
  // @ts-expect-error
  @tracked optionalNationalities = this.args.initialOptionalNationalities || [];

  get canAddOptionalNationality() {
    return this.optionalNationalities.length < 3;
  }

  @action
  addOptionalNationality() {
    if (this.canAddOptionalNationality) {
      this.optionalNationalities = addEmptyNationality(this.optionalNationalities);
      // @ts-expect-error
      this.args.onUpdateOptionalNationalities(this.optionalNationalities);
    }
  }

  @action
  // @ts-expect-error
  updateOptionalNationality(index, countryCode) {
    this.optionalNationalities = updateNationalityInArray(
      this.optionalNationalities,
      index,
      countryCode
    );
    // @ts-expect-error
    this.args.onUpdateOptionalNationalities(this.optionalNationalities);
  }

  @action
  // @ts-expect-error
  removeOptionalNationality(index) {
    this.optionalNationalities = removeNationalityFromArray(this.optionalNationalities, index);
    // @ts-expect-error
    this.args.onUpdateOptionalNationalities(this.optionalNationalities);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Select::Nationalities': typeof SelectNationalitiesComponent;
  }
}
