export default {
  "wrapper": "qdB",
  "section-wrapper": "qdE",
  "header": "qdd",
  "title": "qdI body-1",
  "close-icon": "qdt",
  "section": "qdM",
  "section-title": "qdP caption-bold",
  "labels": "qdl",
  "labels-title": "qdX",
  "section-checkbox": "qdC",
  "grow": "qdk scroll",
  "label": "qdJ",
  "buttons": "qds"
};
