/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner, Tooltip } from '@repo/design-system-kit';
import { dropTask, timeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';
import { isNil } from 'es-toolkit';

import { REQUEST_TYPES } from 'qonto/constants/approval-workflow';
// @ts-expect-error
import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import {
  INVOICE_OR_CREDIT_NOTE_TEXT,
  INVOICE_SOURCES,
  INVOICE_STATUSES,
  SOURCE_TYPES,
} from 'qonto/constants/supplier-invoice';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface SupplierInvoicesDetailsSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
    isWaitingForOcrScan?: boolean;
    isEligibleToInternationalTransfers?: boolean;
    isConfirmTransferTaskRunning?: boolean;
    invoice: SupplierInvoiceModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierInvoicesDetailsComponent extends Component<SupplierInvoicesDetailsSignature> {
  spinner = Spinner;
  tooltip = Tooltip;

  @service declare abilities: Services['abilities'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare supplierInvoicesManager: Services['supplierInvoicesManager'];

  constructor(owner: unknown, args: SupplierInvoicesDetailsSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.ANALYZED, this, 'refreshModel');
  }

  willDestroy() {
    // @ts-expect-error
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.ANALYZED, this, 'refreshModel');

    super.willDestroy();
  }

  get shouldDisplayApprovalWorkflowStateSection() {
    // @ts-expect-error
    let { approvalWorkflowState, isApprovalWorkflowStateLoading, invoice } = this.args;

    return (
      (approvalWorkflowState || isApprovalWorkflowStateLoading) &&
      !approvalWorkflowState?.isFallback &&
      !invoice?.isCreditNote
    );
  }

  get shouldDisplayApprovalWorkflowEmptyState() {
    if (this.abilities.can('create approval-workflow')) {
      let hasSupplierInvoicesApprovalWorkflowRulesets = this.store
        .peekAll('approval-workflow')
        // @ts-expect-error
        .some(
          // @ts-expect-error
          ({ requestType, rulesets }) =>
            requestType === REQUEST_TYPES.SUPPLIER_INVOICE && rulesets.length > 0
        );

      return !hasSupplierInvoicesApprovalWorkflowRulesets;
    }

    return false;
  }

  get canDelete() {
    return (
      !this.args.isLoading &&
      this.abilities.can('delete supplierInvoice') &&
      ![INVOICE_STATUSES.scheduled, INVOICE_STATUSES.pending].includes(this.args.invoice?.status) &&
      !this.args.invoice?.selfInvoiceId
    );
  }

  get canArchive() {
    return (
      this.abilities.can('update supplier-invoice') &&
      this.args.invoice?.status === INVOICE_STATUSES.toReview
    );
  }

  get canUnarchive() {
    return (
      this.abilities.can('update supplier-invoice') &&
      this.args.invoice?.status === INVOICE_STATUSES.archived
    );
  }

  get showCreditNoteText() {
    return this.args.invoice?.isCreditNote;
  }

  get tooltipDownloadText() {
    return this.showCreditNoteText
      ? this.intl.t('supplier-invoices.edit.modal.credit-note-details.download-credit-note')
      : this.intl.t('supplier-invoices.cta.download-tooltip');
  }

  get tooltipArchiveText() {
    return this.showCreditNoteText
      ? this.intl.t('supplier-invoices.edit-modal.credit-note-details.archive-credit-note.tooltip')
      : this.intl.t('supplier-invoices.cta.archive-tooltip');
  }

  get tooltipUnarchiveText() {
    return this.showCreditNoteText
      ? this.intl.t(
          'supplier-invoices.edit-modal.credit-note-details.unarchive-credit-note.tooltip'
        )
      : this.intl.t('supplier-invoices.cta.unarchive-tooltip');
  }

  get tooltipDeleteText() {
    return this.showCreditNoteText
      ? this.intl.t('supplier-invoices.edit-modal.delete-credit-note.tooltip')
      : this.intl.t('supplier-invoices.cta.delete-tooltip');
  }

  @action
  handleDelete() {
    this.modals.open('popup/destructive', {
      title: this.showCreditNoteText
        ? this.intl.t('supplier-invoices.delete-modal.credit-note.title')
        : this.intl.t('supplier-invoices.delete-modal.title'),
      description: this.showCreditNoteText
        ? this.intl.t('supplier-invoices.delete-modal.credit-note.subtitle')
        : this.intl.t('supplier-invoices.delete-modal.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.showCreditNoteText
        ? this.intl.t('supplier-invoices.credit-notes.bulk.popup.delete.confirm', { count: 1 })
        : this.intl.t('btn.delete'),
      confirmTask: this.deleteInvoiceTask,
    });
  }

  get canApproveRequest() {
    let { membership } = this.organizationManager;

    return this.args.invoice.approvalWorkflow?.approverIds?.includes(membership.id);
  }

  get showEditForm() {
    let { isLoading, invoice } = this.args;
    if (this.abilities.can('update supplierInvoice')) {
      if (
        [INVOICE_STATUSES.toApprove, INVOICE_STATUSES.awaitingPayment].includes(invoice?.status)
      ) {
        return this.canApproveRequest;
      }

      return !isLoading && [INVOICE_STATUSES.toReview].includes(invoice?.status);
    }
  }

  get importedBy() {
    let { initiator, source } = this.args.invoice || {};
    if (initiator) {
      return this.intl.t('supplier-invoices.preview.imported-by.team-member', {
        teamMember: initiator.fullName,
      });
    }

    if (source === SOURCE_TYPES.E_INVOICING) {
      return this.intl.t('supplier-invoices.preview.imported-by.e-invoicing');
    }

    return this.intl.t('supplier-invoices.preview.imported-by.whitelist');
  }

  get subtitle() {
    if (this.args.invoice) {
      let datetime = this.intl.formatTime(this.args.invoice.createdAt, { format: 'long' });
      let importedOn = this.intl.t('supplier-invoices.preview.imported-on', {
        datetime,
      });
      return `${importedOn} ${this.importedBy}`;
    }
  }

  get isFrenchEInvoice() {
    let isEInvoice = this.args.invoice?.source === INVOICE_SOURCES.eInvoicing;

    return isEInvoice && this.organizationManager.organization.legalCountry === 'FR';
  }

  get isInvalidGermanEInvoice() {
    return this.args.invoice?.isAttachmentNonFinancial && this.args.invoice?.isGermanEInvoice;
  }

  get eInvoiceAttachmentDisplayConditions() {
    return (
      this.args.invoice?.isGermanEInvoice &&
      // @ts-expect-error
      !isNil(this.args.displayAttachment) && // @ts-expect-error
      this.args.attachment?.id !== this.args.displayAttachment?.id
    );
  }

  get attachmentForDisplay() {
    // @ts-expect-error
    let { attachment, displayAttachment } = this.args;
    return this.eInvoiceAttachmentDisplayConditions ? displayAttachment?.file : attachment?.file;
  }

  @action
  handleDownload() {
    // @ts-expect-error
    window.open(this.args.attachment.downloadUrl);
    this.segment.track('supplier-invoices_download_clicked');
  }

  @action
  // @ts-expect-error
  refreshModel(eventPayload) {
    // @ts-expect-error
    let id = this.supplierInvoicesManager.getSupplierInvoiceIdFromAnalyzedEvent(eventPayload);

    if (!this.args.isLoading && id === this.args.invoice?.id) {
      // @ts-expect-error
      this.args.reload();
    }
  }

  deleteInvoiceTask = dropTask(async close => {
    let { invoice } = this.args;

    this.segment.track('supplier-invoices_delete_clicked', {
      action_type: 'single_item',
      is_non_financial_document: invoice.isAttachmentNonFinancial,
      origin_type: this.showCreditNoteText
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
    });

    try {
      // @ts-expect-error
      await this.supplierInvoicesManager.deleteInvoiceTask.perform(invoice);
      close({ isDeletion: true });
      // @ts-expect-error
      this.args.onClose();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  // @ts-expect-error
  updateForm(form) {
    // @ts-expect-error
    this.form = form;
  }

  @action
  // @ts-expect-error
  handleArchive(id) {
    let { isCreditNote } = this.args.invoice || {};
    this.segment.track('supplier-invoices_archive_clicked', {
      origin: 'invoice_detail',
      action_type: 'single_item',
      origin_type: this.showCreditNoteText
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
    });

    this.modals.open('supplier-invoices/archive-popup', { id, isCreditNote });
  }

  @action
  // @ts-expect-error
  handleUnarchive(id) {
    let { isCreditNote } = this.args.invoice || {};
    this.segment.track('supplier-invoices_unarchive_clicked', {
      origin: 'invoice_detail',
      origin_type: isCreditNote
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
    });

    this.modals.open('supplier-invoices/unarchive-popup', { id, isCreditNote });
  }

  handleClose = dropTask(async ({ isDeletion }) => {
    await timeout(250);

    // @ts-expect-error
    if (this.form?.isDirty && !isDeletion) {
      let result = await this.modals.open('popup/confirmation', {
        title: this.intl.t('supplier-invoices.edit.modal.save-modal.title'),
        description: this.intl.t('supplier-invoices.edit.modal.save-modal.description'),
        cancel: this.intl.t('btn.discard'),
        confirm: this.intl.t('btn.save_settings'),
      });

      // @ts-expect-error
      if (result === 'confirm') {
        try {
          // @ts-expect-error
          await this.form.submit({ partial: true });
          this.toastFlashMessages.toastSuccess(
            this.intl.t('supplier-invoices.success-toast.changes-saved')
          );
          // @ts-expect-error
          this.args.onClose();
        } catch (error) {
          // ignore form validation errors
          // @ts-expect-error
          if (error.name !== 'FormValidationError' && error.status !== 400) {
            throw error;
          }
        }
        // @ts-expect-error
      } else if (result === 'cancel') {
        // @ts-expect-error
        this.args.onClose();
      }
    } else {
      // @ts-expect-error
      this.args.onClose();
    }
  });

  get isElectronicInvoice() {
    let {
      frenchEInvoicing = false,
      isGermanEInvoice = false,
      isItalianEInvoice = false,
    } = this.args.invoice || {};
    return frenchEInvoicing || isGermanEInvoice || isItalianEInvoice;
  }

  get canConvert() {
    return (
      this.abilities.can('update supplier-invoice') &&
      ![INVOICE_STATUSES.archived, INVOICE_STATUSES.paid, INVOICE_STATUSES.scheduled].includes(
        this.args.invoice?.status
      ) &&
      !this.isElectronicInvoice &&
      !this.args.invoice?.relatedInvoices?.length
    );
  }

  get convertTooltipMessage() {
    let { isCreditNote } = this.args.invoice || {};
    return isCreditNote
      ? this.intl.t('supplier-invoices.edit.modal.credit-note-details.convert-to-invoice')
      : this.intl.t('supplier-invoices.edit.modal.invoice-details.convert-to-credit-note');
  }

  @action
  handleConvert() {
    let { isCreditNote } = this.args.invoice || {};

    this.modals.open('popup/confirmation', {
      title: isCreditNote
        ? this.intl.t('supplier-invoices.convert-credit-note.pop-up.title')
        : this.intl.t('supplier-invoices.convert-invoice.pop-up.title'),
      description: isCreditNote
        ? this.intl.t('supplier-invoices.convert-credit-note.pop-up.subtitle')
        : this.intl.t('supplier-invoices.convert-invoice.pop-up.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.convert'),
      confirmTask: this.convertTask,
    });
    this.segment.track('supplier-invoices_convert_clicked', {
      origin_type: this.args.invoice.isCreditNote
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
    });
  }

  convertTask = dropTask(async close => {
    let { isCreditNote } = this.args.invoice || {};
    try {
      if (isCreditNote) {
        await this.args.invoice.convertToInvoice();

        await this.reloadApprovalWorkflowTask.linked().perform(this.args.invoice);
      } else {
        await this.args.invoice.convertToCreditNote();
      }

      this.segment.track('supplier-invoices_convert_submitted', {
        origin_type: isCreditNote
          ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
          : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
      });

      this.toastFlashMessages.toastSuccess(
        isCreditNote
          ? this.intl.t('supplier-invoices.converted-invoice.success-toast')
          : this.intl.t('supplier-invoices.converted-credit-note.success-toast')
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      close();
    }
  });

  reloadApprovalWorkflowTask = dropTask(async invoice => {
    let { status } = invoice;
    if (status === INVOICE_STATUSES.toReview) {
      // @ts-expect-error
      await this.args.fetchApprovalWorkflowEstimateTask.perform(invoice);
    }

    // @ts-expect-error
    await this.args.fetchApprovalWorkflowStateTask.perform(invoice);
  });

  unlinkInvoiceFromCreditNoteTask = dropTask(async creditNoteId => {
    let isCreditNote = this.args.invoice?.isCreditNote || false;
    let payload = { isCreditNote };
    if (isCreditNote) {
      // @ts-expect-error
      payload['creditNoteId'] = this.args.invoice.id;
      // @ts-expect-error
      payload['supplierInvoiceId'] = creditNoteId;
    } else {
      // @ts-expect-error
      payload['supplierInvoiceId'] = this.args.invoice.id;
      // @ts-expect-error
      payload['creditNoteId'] = creditNoteId;
    }

    // @ts-expect-error
    await this.supplierInvoicesManager.unlinkInvoiceFromCreditNote.perform(payload);

    let toastMessage = isCreditNote
      ? this.intl.t('supplier-invoices.invoice-removed-from-credit-note.toast')
      : this.intl.t('supplier-invoices.credit-note-removed-from-invoice.toast');

    this.segment.track('supplier-invoices_unlink-document_submitted', {
      is_einvoice: this.args.invoice?.isElectronicInvoice ?? false,
    });

    this.toastFlashMessages.toastSuccess(toastMessage);
    // @ts-expect-error
    this.args.reload();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Details': typeof SupplierInvoicesDetailsComponent;
  }
}
