/* import __COLOCATED_TEMPLATE__ from './sidebar-placeholder.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarPlaceholderSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Placeholder = templateOnlyComponent<SidebarPlaceholderSignature>();

export default Placeholder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::SuggestedCreditNotesModal::SidebarPlaceholder': typeof Placeholder;
  }
}
