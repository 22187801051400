/* import __COLOCATED_TEMPLATE__ from './upgrade-required-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface UpgradeRequiredModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const UpgradeRequiredModal = templateOnlyComponent<UpgradeRequiredModalSignature>();

export default UpgradeRequiredModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UpgradeRequiredModal: typeof UpgradeRequiredModal;
  }
}
