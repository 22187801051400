/* import __COLOCATED_TEMPLATE__ from './single-dropzone.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SingleDropzoneSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SingleDropzone = templateOnlyComponent<SingleDropzoneSignature>();

export default SingleDropzone;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::PayByInvoice::SingleDropzone': typeof SingleDropzone;
  }
}
