/* import __COLOCATED_TEMPLATE__ from './add-transaction.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';

interface AddTransactionSignature {
  // The arguments accepted by the component
  Args: {
    invoice: SupplierInvoiceModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AddTransactionComponent extends Component<AddTransactionSignature> {
  @service declare modals: Services['modals'];
  @service declare router: Services['router'];

  @action
  openTransactionsModal() {
    if (variation('feature--boolean-match-transaction-modal-migration')) {
      this.router.transitionTo('supplier-invoices.list.match-transaction', {
        queryParams: { invoice_id: this.args.invoice.id },
      });
    } else {
      this.modals.open('supplier-invoices/transactions-modal', {
        isFullScreenModal: true,
        attachment: this.args.invoice.attachment,
        invoice: this.args.invoice,
        // @ts-expect-error
        onCloseModal: () => this.args.refreshTransaction(),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::TransactionsContainer::AddTransaction': typeof AddTransactionComponent;
  }
}
