/* import __COLOCATED_TEMPLATE__ from './refusal-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface RefusalModalArgs {
  data?: {
    einvoicingSettings?: any;
  };
  args: { close: () => void };
}

export default class RefusalModal extends Component<RefusalModalArgs> {
  button = Button;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  textAreaField = TextAreaField;

  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked selectedReason = '';
  @tracked reasonComment = '';
  @tracked formSubmitted = false;

  get einvoicingSettings() {
    return this.args.data?.einvoicingSettings || {};
  }

  get reasonsList() {
    return [
      this.intl.t('settings.einvoicing.disable-modal.reasons.first'),
      this.intl.t('settings.einvoicing.disable-modal.reasons.second'),
      this.intl.t('settings.einvoicing.disable-modal.reasons.third'),
    ];
  }

  get dropdownError() {
    return this.selectedReason.length === 0 && this.formSubmitted;
  }

  @action handleReasonOption(reason: string): void {
    this.selectedReason = reason;
    this.updateFullReason();
  }

  @action handleReasonComment(reason: string): void {
    this.reasonComment = reason;
    this.updateFullReason();
  }

  @action updateFullReason(): void {
    this.einvoicingSettings.reason = `${this.selectedReason} ${this.reasonComment}`;
  }

  confirmRefusalTask = dropTask(async () => {
    this.formSubmitted = true;

    try {
      if (this.selectedReason.length === 0) {
        return;
      }

      await this.einvoicingSettings.deactivate(this.einvoicingSettings.reason, [
        // @ts-expect-error
        this.args.data.address,
      ]);

      this.toastFlashMessages.toastSuccess(
        this.intl.t('settings.einvoicing.receive-status.disable.success-toast')
      );

      let reasons = {
        [this.intl.t('settings.einvoicing.disable-modal.reasons.first')]: 'other-tool',
        [this.intl.t('settings.einvoicing.disable-modal.reasons.second')]: 'refuse-reception',
        [this.intl.t('settings.einvoicing.disable-modal.reasons.third')]: 'other',
      };

      this.segment.track('e-invoicing-deactivation_disable-CTA_clicked', {
        reason: reasons[this.selectedReason],
      });

      // @ts-expect-error
      await this.args.close();
    } catch (error) {
      this.toastFlashMessages.toastError(
        this.intl.t('settings.einvoicing.disable-modal.disable.error-toast')
      );

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  closeModal = dropTask(async () => {
    if (this.einvoicingSettings?.reason) {
      this.modals.open('popup/confirmation', {
        title: this.intl.t('settings.einvoicing.disable-modal.popup.title'),
        description: this.intl.t('settings.einvoicing.disable-modal.popup.subtitle'),
        cancel: this.intl.t('settings.einvoicing.disable-modal.popup.cancel.cta'),
        confirm: this.intl.t('settings.einvoicing.disable-modal.popup.leave.cta'),
        confirmTask: this.closeModalAndPopup,
      });
    } else {
      // @ts-expect-error
      await this.args.close();
    }
  });

  closeModalAndPopup = dropTask(async close => {
    if (this.einvoicingSettings) {
      this.einvoicingSettings.reason = null;
    }

    // @ts-expect-error, close the modal
    await this.args.close();
    // close the popup
    close?.();
  });
}
