/* import __COLOCATED_TEMPLATE__ from './add-invoices-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class AddInvoicesModal extends Component {
  @service router;
  @service supplierInvoicesUploadManager;
  @service toastFlashMessages;
  @service clipboardManager;
  @service segment;
  @service intl;

  @action
  close() {
    this.args.close();
  }

  @action
  handleSuccessCopy() {
    this.close();
    this.toastFlashMessages.toastInfo(
      this.intl.t('supplier-invoices.toast.info.copied-to-clipboard')
    );
  }

  @action
  handleClickTracking(origin) {
    this.segment.track('supplier-invoices_cta_clicked', { source: origin });
  }

  copyTask = dropTask(async () => {
    this.handleClickTracking('e-mail_forward');
    await this.clipboardManager.copyTask.perform({
      clipboardText: this.args.data.receiptForwardEmail,
      onSuccess: this.handleSuccessCopy,
      successLabel: this.intl.t('supplier-invoices.toast.info.copied-to-clipboard'),
    });
  });
}
