export default {
  "attachment-sidebar": "qcl",
  "sidebar-scroll": "qcX",
  "top-section": "qcC",
  "timeline-section": "qck",
  "sidebar-header": "qcJ",
  "matched-section": "qcs",
  "collapsible-timeline": "qcF",
  "timeline-status-label": "qcg",
  "m-24": "qcD"
};
