/* import __COLOCATED_TEMPLATE__ from './beneficiary-form.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { authBaseURL } from 'qonto/constants/hosts';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
// @ts-expect-error
import { isIbanFromSepaZone, parseInvalidBeneficiaryErrors } from 'qonto/utils/beneficiaries';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface TransfersBeneficiarySepaFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class TransfersBeneficiarySepaFormComponent extends Component<TransfersBeneficiarySepaFormSignature> {
  @service declare segment: Services['segment'];
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];
  @tracked isValidationEnabled = false;
  @tracked isEligibleToInternationalTransfers = false;

  constructor(owner: unknown, args: TransfersBeneficiarySepaFormSignature['Args']) {
    super(owner, args);
    this.checkInternationalOutEligibilityTask.perform().catch(ignoreCancelation);
  }

  get beneficiary() {
    // @ts-expect-error
    return this.args.beneficiary;
  }

  get canReadCustomLabels() {
    return this.abilities.can('read custom-labels');
  }

  get canViewBookeeping() {
    return this.abilities.can('view bookkeeping');
  }

  get hasLabelsList() {
    return this.beneficiary.organization.get('labelLists').length > 0;
  }

  get ibanErrorMessage() {
    let message = this.beneficiary.validations.attrs.iban?.message;

    if (message) {
      return message === 'invalid_iban' ? this.intl.t('validations.errors.invalid_iban') : message;
    }

    if (!this.isIbanFromSepaZone && !this.isEligibleToInternationalTransfers) {
      return this.intl.t('validations.errors.iban_not_sepa');
    }

    return null;
  }

  get isIbanFromSepaZone() {
    return isIbanFromSepaZone(this.beneficiary.iban);
  }
  get shouldShowDefenseModeCardDisclaimer() {
    const isDefenseModeEnabled = this.organizationManager.featuresManager.isEnabled(
      'defensiveModeNewBeneficiaryDisclaimer'
    );
    const hasBankAccountFeature = this.subscriptionManager.hasFeature('bank_account'); // Not a light membership
    return isDefenseModeEnabled && hasBankAccountFeature;
  }

  trackDisclaimerIsSeen = dropTask(async () => {
    return await this.networkManager.request(`${authBaseURL}/v5/disclaimer_seen`, {
      method: 'POST',
      body: JSON.stringify({
        disclaimer_key: 'new_beneficiary',
        disclaimer_content: this.intl.t('transfers.sepa.new.beneficiaries.phishing-disclaimer'),
      }),
    });
  });

  checkInternationalOutEligibilityTask = dropTask(async () => {
    this.isEligibleToInternationalTransfers = await this.internationalOutManager.isEligible();
  });

  saveBeneficiaryTask = dropTask(async () => {
    this.isValidationEnabled = false;
    // eslint-disable-next-line ember/no-array-prototype-extensions
    this.beneficiary.errors.clear();

    let { validations } = await this.beneficiary.validate();
    this.isValidationEnabled = true;

    if (validations.isValid && this.isIbanFromSepaZone) {
      try {
        await this.beneficiary.save();
        // @ts-expect-error
        this.args.transitionToNext(this.beneficiary);
      } catch (error) {
        // @ts-expect-error
        if (error instanceof InvalidError && error.errors) {
          // @ts-expect-error
          let { errors } = error;

          let parsedFieldErrors = parseInvalidBeneficiaryErrors(errors);
          // @ts-expect-error
          this.networkManager.errorModelInjector(this.beneficiary, parsedFieldErrors, error);

          this._scrollToError();
        }
      }
    } else {
      this._scrollToError();
    }
    if (this.shouldShowDefenseModeCardDisclaimer) {
      this.trackDisclaimerIsSeen.perform().catch(error => {
        // We don't need a toast here since the user shouldn't get notified
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
    }
  });

  @action
  // @ts-expect-error
  saveLabels(labelsList, label) {
    let labelsToUpdate = getLabelsToUpdate(this.beneficiary.labels, labelsList, label);
    this.beneficiary.labels = labelsToUpdate;
  }

  @action
  // @ts-expect-error
  updateEmail(email) {
    this.beneficiary.email = isEmpty(email) ? null : email;
  }

  @action
  // @ts-expect-error
  updateVatRate(beneficiary, { vatRate }) {
    beneficiary.vatRate = vatRate;
  }

  @action
  handleLinkClick() {
    this.segment.track('transfer-sepa_swift-redirection_clicked');

    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'international-out',
      stepId: 'quote',
      queryParams: { targetCurrency: CURRENCIES.EUR },
    });
  }

  _scrollToError() {
    next(() => scrollIntoView('[data-has-error]'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::BeneficiaryForm': typeof TransfersBeneficiarySepaFormComponent;
  }
}
