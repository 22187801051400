/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface TableSignature {
  Element: null;
  Args: {
    isLoading?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const Table = templateOnlyComponent<TableSignature>();

export default Table;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Suppliers::Table': typeof Table;
  }
}
