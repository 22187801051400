export default {
  "card-header": "qMA",
  "counterparty-name": "qMY",
  "pan-section": "qMh header-section body-2",
  "infobox": "qMf header-section",
  "see-card-button": "qMK",
  "card-identifier-section": "qMG",
  "card-icon": "qMr",
  "nickname": "qMb body-1",
  "pan": "qMy body-2"
};
