/* import __COLOCATED_TEMPLATE__ from './generic-banner.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { GenericBannerComponent } from 'qonto/react/components/topbar/defense-mode';
interface TopbarGenericBannerSignature {
  // The arguments accepted by the component
  Args: {
    close: () => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopbarGenericBannerComponent extends Component<TopbarGenericBannerSignature> {
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  genericBannerComponent = GenericBannerComponent;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::GenericBanner': typeof TopbarGenericBannerComponent;
  }
}
