export default {
  "sidebar-header": "qMj",
  "sidebar-header__top": "qMW",
  "sidebar-header__body": "qMu",
  "sidebar-header__content": "qPS",
  "sidebar-header__date": "qPc",
  "infobox": "qPq",
  "infobox--link": "qPZ",
  "ghost": "qPR",
  "fail": "qPQ",
  "r-transaction-disclaimer": "qPe",
  "hold-disclaimer": "qPB",
  "transaction-link": "qPE body-2",
  "icon-link": "qPd"
};
