/* import __COLOCATED_TEMPLATE__ from './sdd-edito-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SddEditoModalSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SddEditoModal = templateOnlyComponent<SddEditoModalSignature>();

export default SddEditoModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SddEditoModal: typeof SddEditoModal;
  }
}
