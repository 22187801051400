/* import __COLOCATED_TEMPLATE__ from './additional-informations.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AdditionalInformationsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AdditionalInformations = templateOnlyComponent<AdditionalInformationsSignature>();

export default AdditionalInformations;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::External::AdditionalInformations': typeof AdditionalInformations;
  }
}
