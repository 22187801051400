/* import __COLOCATED_TEMPLATE__ from './contact-cs-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ContactCsModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ContactCsModal = templateOnlyComponent<ContactCsModalSignature>();

export default ContactCsModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::Close::ContactCsModal': typeof ContactCsModal;
  }
}
