/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

interface SelfBillingShowInvoiceModalSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SelfBillingShowInvoiceModalSidebarComponent extends Component<SelfBillingShowInvoiceModalSidebarSignature> {
  tag = Tag;

  @service declare intl: Services['intl'];

  get countryName() {
    // @ts-expect-error
    let { countryCode } = this.args.invoice.supplierSnapshot;

    // @ts-expect-error
    return countryCode ? this.intl.formatCountry(countryCode) : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SelfBilling::ShowInvoiceModal::Sidebar': typeof SelfBillingShowInvoiceModalSidebarComponent;
  }
}
