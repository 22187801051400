import { template as template_64af4ce2bd3c4a108348fe5c22d1dabf } from "@ember/template-compiler";
import Component from '@glimmer/component';
import themedAsset from '@qonto/ui-kit/helpers/themed-asset';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import styles from './step-state.strict-module.css';
export default class TimelineGenericStep extends Component {
    get icon() {
        let { iconType } = this.args;
        switch(iconType){
            case 'arrow':
                return {
                    iconName: 'icon_arrow_bottom_rounded_outlined',
                    class: styles['step-icon']
                };
            case 'current':
                return {
                    iconName: 'icon_checkmark_rounded_filled',
                    class: styles['current-icon']
                };
            case 'mileage':
                return {
                    iconName: 'icon_pin_rounded_filled',
                    class: styles['mileage-icon']
                };
            case 'plus':
                return {
                    iconName: 'icon_plus_rounded_outlined',
                    class: styles['step-icon']
                };
            case 'reject':
                return {
                    iconName: 'icon_cross_rounded_filled',
                    class: styles['reject-icon']
                };
            case 'payment':
                return {
                    iconName: 'icon_euro',
                    class: styles['step-icon']
                };
            case 'payment-current':
                return {
                    iconName: 'icon_euro_filled',
                    class: styles['current-icon']
                };
            case 'pending-filled':
                return {
                    iconName: 'icon_clock_filled',
                    class: styles['step-icon']
                };
            case 'success':
                return {
                    iconName: 'icon_checkmark_rounded_filled',
                    class: styles['success-icon']
                };
            case 'warning':
                return {
                    iconName: 'icon_warning_rounded_outlined',
                    class: styles['step-icon']
                };
            case 'warning-filled':
            case 'default-colour warning-filled':
                return {
                    iconName: 'icon_warning_rounded_filled',
                    class: styles['step-icon']
                };
            default:
                return {
                    iconName: 'icon_checkmark_rounded_outlined',
                    class: styles['step-icon']
                };
        }
    }
    get dataTestIconType() {
        let { iconType } = this.args;
        return iconType || 'default';
    }
    get lineStyle() {
        let { lineStyle } = this.args;
        switch(lineStyle){
            case 'solid':
                return styles['solid-line'];
            case 'dashed':
            default:
                return styles['dashed-line'];
        }
    }
    static{
        template_64af4ce2bd3c4a108348fe5c22d1dabf(`
    <div class={{styles.container}} ...attributes>
      {{#if @isLoading}}
        <div class={{styles.step-icon}} data-test-timeline-step-state-loading-icon>
          {{svgJar (themedAsset 'icon_dot_outlined') aria-hidden='true' class=styles.svg}}
        </div>
      {{else}}
        <div class={{this.icon.class}} data-test-timeline-step-state-icon={{this.dataTestIconType}}>
          {{svgJar (themedAsset this.icon.iconName) aria-hidden='true' class=styles.svg}}
        </div>
      {{/if}}
      {{#unless @shouldHideProgressLine}}
        <div class={{this.lineStyle}} data-test-timeline-step-state-progress-line />
      {{/unless}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
