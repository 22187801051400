import templateOnlyComponent from '@ember/component/template-only';

interface LinkedTooltipSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const LinkedTooltip = templateOnlyComponent<LinkedTooltipSignature>();

export default LinkedTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Experiments::LinkedTooltip': typeof LinkedTooltip;
  }
}
