export default {
  "transaction": "qtR",
  "transaction-details": "qtQ",
  "transaction-details-amount": "qte body-1",
  "credit": "qtB",
  "negative-amount": "qtE",
  "transaction-details-description": "qtd body-2",
  "transaction-remove-cta": "qtI",
  "avatar": "qtt mr-16",
  "spinner": "qtM"
};
