/* import __COLOCATED_TEMPLATE__ from './dropzone.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface SupplierInvoicesDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierInvoicesDetailsComponent extends Component<SupplierInvoicesDetailsSignature> {
  @service declare segment: Services['segment'];

  @action
  handleDrop() {
    // @ts-expect-error
    this.args.onDragAndDropStopped();
    this.segment.track('supplier-invoices_drag-and-drop_file-dropped');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Dropzone': typeof SupplierInvoicesDetailsComponent;
  }
}
