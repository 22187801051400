/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface GeneralSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const General = templateOnlyComponent<GeneralSignature>();

export default General;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::General': typeof General;
  }
}
