/* import __COLOCATED_TEMPLATE__ from './invoice-details.hbs'; */
// @ts-nocheck
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, Spinner } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { all, dropTask, race, rawTimeout, restartableTask, task, timeout } from 'ember-concurrency';

// @ts-expect-error
import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import {
  PAY_BY_INVOICE_ANIMATION_IN_DURATION_MS,
  PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN,
} from 'qonto/constants/transfers';
import { PopupConfirmation } from 'qonto/react/components/popups/confirmation';
// @ts-expect-error
import { isIbanFromSepaZone } from 'qonto/utils/beneficiaries';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
} from 'qonto/utils/transfers';

interface TransfersSepaInvoiceDetailsSignature {
  Args: {
    isValidationEnabled?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class TransfersSepaInvoiceDetailsComponent extends Component<TransfersSepaInvoiceDetailsSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  spinner = Spinner;

  @service declare abilities: Services['abilities'];
  @service declare beneficiariesManager: Services['beneficiariesManager'];
  @service declare errors: Services['errors'];
  @service declare intl: Services['intl'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare reactPopup: Services['reactPopup'];
  @service declare supplierInvoicesManager: Services['supplierInvoicesManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked beneficiaries = [];

  invoiceAnalysisResult = {
    amount_read: false,
    beneficiary_IBAN_read: false,
    beneficiary_IBAN_matched: false,
    beneficiary_name_read: false,
    beneficiary_name_matched: false,
    invoice_number_read: false,
  };

  constructor(owner: unknown, args: TransfersSepaInvoiceDetailsSignature['Args']) {
    // @ts-expect-error
    super(owner, args);
    this.initiateTransferFromInvoiceTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) this.sentry.captureException(error);
        this.toastFlashMessages.toastError(
          // @ts-expect-error
          this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
        );
      });
  }

  get beneficiary() {
    // @ts-expect-error
    return this.args.context.sepaTransfer.get('beneficiary').content;
  }

  get beneficiaryErrorMessage() {
    if (this.hasMissingBeneficiaryError) {
      // @ts-expect-error
      if (!this.loadBeneficiariesTask.lastSuccessful.value.length) {
        return this.intl.t('transfers.sepa.invoice.add-beneficiary-error');
      }

      return this.intl.t('validations.errors.transfer.presence');
    }

    return null;
  }

  get shouldShowInvalidInvoiceDisclaimer(): boolean {
    return (
      // @ts-expect-error
      this.args.context.invoice?.isGermanEInvoice &&
      this.args.context.invoice?.isAttachmentNonFinancial
    );
  }

  get hasMissingBeneficiaryError(): boolean {
    // @ts-expect-error
    return this.args.isValidationEnabled && !this.beneficiary;
  }

  get sepaTransfer() {
    // @ts-expect-error
    return this.args.context.sepaTransfer;
  }

  get invoiceLifeTime() {
    // @ts-expect-error
    return Math.max(dayjs().diff(this.args.context.invoice.createdAt), 0) || 0;
  }

  get shouldShowOcrAnimation() {
    return this.waitForOcrScanTask.isRunning;
  }

  get shouldTrackOcrResult() {
    return this.waitForOcrScanTask.performCount === 1;
  }

  get isEInvoice() {
    let {
      frenchEInvoicing = false,
      isGermanEInvoice = false,
      isItalianEInvoice = false,
      // @ts-expect-error
    } = this.args.context?.invoice || {};

    return frenchEInvoicing || isGermanEInvoice || isItalianEInvoice;
  }

  initiateTransferFromInvoiceTask = task(async () => {
    let tasks = [this.loadBeneficiariesTask.perform()];

    // @ts-expect-error
    let { invoice } = this.args.context;
    let { analyzedAt } = invoice;

    if (!analyzedAt && this.invoiceLifeTime <= PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN) {
      tasks.push(this.waitForOcrScanTask.perform());
    } else {
      tasks.push(this.prefillTransferTask.perform(invoice));
    }

    await all(tasks);

    if (this.shouldTrackOcrResult) {
      // @ts-expect-error
      let { origin } = this.args.context;
      this.segment.track('transfer-sepa_transfer_details_displayed', {
        flow: 'byinvoice',
        ...(origin && { origin }),
        ...this.invoiceAnalysisResult,
      });
    }
  });

  loadBeneficiariesTask = dropTask(async () => {
    try {
      // @ts-expect-error
      let allBeneficiaries = await this._loadBeneficiaries();
      this.beneficiaries = allBeneficiaries;
      return allBeneficiaries;
    } catch {
      this.beneficiaries = [];
      return [];
    }
  });

  prefillTransferTask = dropTask(async invoice => {
    await invoice.reload();

    let { beneficiary, invoiceAnalysisResult, organizationManager, sepaTransfer } = this;
    let { iban: legacyIban, invoiceNumber, supplierName, amountToPay, supplierSnapshot } = invoice;

    let iban = supplierSnapshot?.iban || legacyIban;

    invoiceAnalysisResult.beneficiary_name_read = Boolean(supplierName);
    invoiceAnalysisResult.beneficiary_IBAN_read = Boolean(iban);
    invoiceAnalysisResult.amount_read = Boolean(amountToPay?.value);
    invoiceAnalysisResult.invoice_number_read = Boolean(invoiceNumber);

    // Paylater allows users to enter a certain amount to pay. In cases where there is already an invoice, our getter, `amountToPay`, in the
    // model returns the amount of the invoice and we use it as the amount in the sepaTransfer object. This amount from the invoice
    // is not aware that another amount has been populated to be used as the transferAmount.
    sepaTransfer.setProperties({
      amount: sepaTransfer?.amount ? sepaTransfer.amount : amountToPay?.value,
      reference: sepaTransfer.reference || (invoiceNumber ?? ''),
    });

    let isBeneficiaryPrefilled = null;
    if (!beneficiary && iban) {
      // @ts-expect-error
      let matchingBeneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
        organizationManager.organization.id,
        iban
      );
      if (matchingBeneficiary) {
        invoiceAnalysisResult.beneficiary_IBAN_matched = true;
        invoiceAnalysisResult.beneficiary_name_matched = matchingBeneficiary.name === supplierName;

        if (isIbanFromSepaZone(matchingBeneficiary.iban)) {
          this._selectBeneficiary(matchingBeneficiary);
          isBeneficiaryPrefilled = true;
        }
      }
    }

    let hasOCRSucceded = [
      isBeneficiaryPrefilled,
      invoiceAnalysisResult.amount_read,
      invoiceAnalysisResult.invoice_number_read,
    ].some(Boolean);
    if (!hasOCRSucceded) {
      this.toastFlashMessages.toastError(this.intl.t('errors.invoice_ocr_failed'));
    }
  });

  searchBeneficiaryTask = restartableTask(async searchQuery => {
    try {
      await timeout(DEBOUNCE_MS);
      let filteredBeneficiaries = await this._loadBeneficiaries(searchQuery);
      this.beneficiaries = filteredBeneficiaries;
      return filteredBeneficiaries;
    } catch {
      this.beneficiaries = [];
      return [];
    }
  });

  openNotSepaModalTask = dropTask(async () => {
    return await this.reactPopup.open(PopupConfirmation, {
      title: this.intl.t('transfers.modals.beneficiary-not-sepa.title'),
      subtitle: this.intl.t('transfers.modals.beneficiary-not-sepa.description'),
      confirmText: this.intl.t('transfers.modals.beneficiary-not-sepa.confirm'),
      cancelText: this.intl.t('btn.cancel'),
    });
  });

  selectBeneficiary = dropTask(async beneficiary => {
    if (!isIbanFromSepaZone(beneficiary.iban)) {
      this.segment.track('transfer-sepa_out_of_SEPA_modal_displayed');

      let result = (await this.openNotSepaModalTask.perform()) as 'confirm' | 'cancel';

      if (result === 'confirm') {
        this.segment.track('transfer-sepa_out_of_SEPA_edit_clicked');
        return this.editBeneficiary(beneficiary);
      } else {
        return this.segment.track('transfer-sepa_out_of_SEPA_cancel_clicked');
      }
    }

    this._selectBeneficiary(beneficiary);
  });

  waitForOcrScanTask = dropTask(async () => {
    // @ts-expect-error
    let { invoice, origin } = this.args.context;

    this.segment.track('transfer-sepa_OCR_transition_displayed', {
      ...(origin && { origin }),
    });

    let waitForOcrAnimation = rawTimeout(PAY_BY_INVOICE_ANIMATION_IN_DURATION_MS);
    let waitForScanCompletionOrDelay = this.waitForScanCompletionOrDelayTask.perform();

    let [eventedResponse] = await all([waitForScanCompletionOrDelay, waitForOcrAnimation]);

    if (eventedResponse?.event === SUPPLIER_INVOICE_EVENTS.ANALYZED) {
      let { payload } = eventedResponse;
      // @ts-expect-error
      let id = this.supplierInvoicesManager.getSupplierInvoiceIdFromAnalyzedEvent(payload);

      if (id === invoice.id) {
        await this.prefillTransferTask.perform(invoice);
      }
    } else {
      // If we timed out waiting for the websocket, we try to refetch and prefill last time
      await this.prefillTransferTask.perform(invoice);
    }
  });

  waitForScanCompletionOrDelayTask = task(async () => {
    // @ts-expect-error
    let isInvoiceScanned = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.ANALYZED
    );
    let delay = rawTimeout(PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN);
    return await race([isInvoiceScanned, delay]);
  });

  @action
  addBeneficiary() {
    // @ts-expect-error
    let { context, pushFlow } = this.args;
    let { origin } = context;
    this.segment.track('transfer-sepa_new-beneficiary_clicked', {
      flow: 'byinvoice',
      ...(origin && { origin }),
    });
    // We reset the `beneficiaryToEdit` property in case we have already visited the beneficiary edition flow
    context.beneficiaryToEdit = null;
    pushFlow('sepa-transfer-beneficiary', 'add-beneficiary');
  }

  @action
  // @ts-expect-error
  editBeneficiary(beneficiary) {
    // @ts-expect-error
    let { context, pushFlow } = this.args;
    let { origin, invoice } = context;
    this.segment.track('transfer-sepa_edit-beneficiary_clicked', {
      flow: 'byinvoice',
      ...(origin && { origin }),
    });
    context.beneficiaryToEdit = beneficiary;
    pushFlow('sepa-transfer-beneficiary', 'edit-beneficiary');
  }

  // @ts-expect-error
  async _loadBeneficiaries(searchQuery) {
    // @ts-expect-error
    return await this.beneficiariesManager.loadSepaBeneficiaries(
      this.organizationManager.organization.id,
      searchQuery
    );
  }

  // @ts-expect-error
  _selectBeneficiary(beneficiary) {
    this.sepaTransfer.set('beneficiary', beneficiary);

    copyBeneficiaryIntoTransfer(this.sepaTransfer, beneficiary, { forceCopy: true });

    copyBeneficiaryLabelsIntoTransfer(this.sepaTransfer, beneficiary);

    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(this.sepaTransfer, beneficiary);
    }

    // @ts-expect-error
    let { origin, invoice } = this.args.context;
    this.segment.track('transfer-sepa_beneficiary_selected', {
      flow: 'byinvoice',
      ...(origin && { origin }),
      ...(invoice && {
        is_einvoice: this.isEInvoice,
      }),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::InvoiceDetails': typeof TransfersSepaInvoiceDetailsComponent;
  }
}
