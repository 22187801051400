/* import __COLOCATED_TEMPLATE__ from './group-selector.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLFieldSetElement;
  Args: {
    isDisabled?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const GroupSelector = templateOnlyComponent<Signature>();

export default GroupSelector;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::GroupSelector': typeof GroupSelector;
  }
}
