/* import __COLOCATED_TEMPLATE__ from './pending.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PendingSignature {
  Element: HTMLTableRowElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Pending = templateOnlyComponent<PendingSignature>();

export default Pending;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Placeholder::Pending': typeof Pending;
  }
}
