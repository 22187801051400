/* import __COLOCATED_TEMPLATE__ from './budget.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { restartableTask } from 'ember-concurrency';
import { TrackedWeakMap } from 'tracked-built-ins';

// @ts-expect-error
import { NO_PERIOD_ID } from 'qonto/constants/budget';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface TransactionsSidebarDetailsBudgetSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransactionsSidebarDetailsBudgetComponent extends Component<TransactionsSidebarDetailsBudgetSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  @tracked _selectedOption = new TrackedWeakMap();

  get options() {
    // @ts-expect-error
    return this.args.periodOptionsTaskInstance.value?.options;
  }

  get selected() {
    return this.selectedOption?.name;
  }

  get selectedOption() {
    // @ts-expect-error
    if (this._selectedOption.has(this.args.periodOptionsTaskInstance)) {
      // @ts-expect-error
      return this._selectedOption.get(this.args.periodOptionsTaskInstance);
    } else {
      // @ts-expect-error
      return this.args.periodOptionsTaskInstance.value?.selectedOption;
    }
  }

  get subscriptionStatus() {
    return this.subscriptionManager.currentSubscription?.status;
  }

  onOptionSelectTask = restartableTask(async option => {
    // @ts-expect-error
    let oldSelectedOption = this._selectedOption.get(this.args.periodOptionsTaskInstance);
    // @ts-expect-error
    this._selectedOption.set(this.args.periodOptionsTaskInstance, option);
    try {
      // @ts-expect-error
      await this.args.onPeriodSelection?.(option);
      this.segment.track(
        option.id === NO_PERIOD_ID
          ? 'budget-allocation_no-budget_selected'
          : 'budget-allocation_budget_selected'
      );
    } catch (error) {
      // @ts-expect-error
      this._selectedOption.set(this.args.periodOptionsTaskInstance, oldSelectedOption);
      this.toastFlashMessages.toastError(this.intl.t('errors.unexpected_error_occured'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Details::Budget': typeof TransactionsSidebarDetailsBudgetComponent;
  }
}
