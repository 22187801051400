export default {
  "wrapper": "cjR",
  "content": "cjQ",
  "title": "cje",
  "aside": "cjB",
  "description": "cjE",
  "faq-link": "cjd",
  "close-btn": "cjI",
  "container": "cjt",
  "mandate-field-list": "cjM",
  "mandate-field-item": "cjP",
  "mandate-field-item__dot": "cjl",
  "mandate-field-item__text": "cjX"
};
