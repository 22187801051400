/* import __COLOCATED_TEMPLATE__ from './bookkeeping.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { VatDetails } from 'qonto/react/components/transactions/table/side-panel/details/vat';
// @ts-expect-error
import transactionHasVat from 'qonto/utils/transaction-has-vat';

interface BookkeepingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class BookkeepingComponent extends Component<BookkeepingSignature> {
  badgeStatus = BadgeStatus;

  // @ts-expect-error
  @reads('args.transaction.declined') transactionDeclined;
  // @ts-expect-error
  @reads('args.transaction.isBilling') transactionIsBilling;
  // @ts-expect-error
  @reads('args.transaction.isDeclinedTransfer') transactionIsDeclinedTransfer;

  @service declare abilities: Services['abilities'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];

  VatDetails = VatDetails;

  constructor(owner: unknown, args: BookkeepingSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    assert('@transaction is required', this.args.transaction);
    // @ts-expect-error
    assert('@openDetails is required', this.args.openDetails);
    // @ts-expect-error
    assert('@markAsVerified is required', this.args.markAsVerified);
  }

  get level() {
    // @ts-expect-error
    return this.args.transaction.qualifiedForAccounting ? 'validated' : 'waiting';
  }

  get text() {
    // @ts-expect-error
    return this.args.transaction.qualifiedForAccounting
      ? this.intl.t('transactions.sidebar.bookkeeping.badge.verified')
      : this.intl.t('transactions.sidebar.bookkeeping.badge.to-be-verified');
  }

  get subjectDeclined() {
    // @ts-expect-error
    return this.args.transaction.subject?.get('declined');
  }

  get isNotDeclined() {
    return !this.subjectDeclined && !this.transactionDeclined;
  }

  get canManageStatus() {
    return this.isNotDeclined && this.abilities.can('qualify for accounting transactions');
  }

  get canShowVat() {
    // @ts-expect-error
    return this.isNotDeclined && transactionHasVat(this.args.transaction);
  }

  get categoryId() {
    // @ts-expect-error
    return this.args.transaction.categoryAssignment?.id;
  }

  get transactionAmount() {
    // @ts-expect-error
    let { amount: value, amountCurrency: currency } = this.args.transaction;

    return { value, currency };
  }

  markAsVerifiedTask = dropTask(async () => {
    // @ts-expect-error
    let isVerified = !this.args.transaction.qualifiedForAccounting;
    // @ts-expect-error
    let transactionIds = [this.args.transaction.id];

    try {
      // @ts-expect-error
      await this.args.markAsVerified(isVerified, transactionIds);

      this.track(isVerified);

      let successMessage = isVerified
        ? this.intl.t('attachment-viewer.verified.footer.qualified.toast.success')
        : this.intl.t('attachment-viewer.verified.footer.disqualified.toast.success');

      this.toastFlashMessages.toastSuccess(successMessage);
    } catch (error) {
      this.sentry.captureException(
        new Error(`Transaction sidebar mark as verified triggered an error`, { cause: error })
      );

      this.toastFlashMessages.toastError(
        this.intl.t('attachment-viewer.bookkeeping.generic-error.toast')
      );
    }
  });

  // @ts-expect-error
  track(isVerified) {
    let segmentTracker = isVerified
      ? 'history_transaction_side_panel_mark_as_verified_checkbox_ticked'
      : 'history_transaction_side_panel_mark_as_verified_checkbox_unticked';

    this.segment.track(segmentTracker);
  }

  @action
  handleEdit() {
    this.segment.track('transaction_analytic_label_manage_label_clicked');
    // @ts-expect-error
    this.args.openDetails();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Details::Bookkeeping': typeof BookkeepingComponent;
  }
}
