export default {
  "beneficiary-details": "qsZ",
  "beneficiary-account-types": "qsR",
  "divider": "qsQ",
  "label": "qse",
  "labels": "qsB",
  "error": "qsE",
  "link": "qsd caption",
  "sticky-panel": "qsI"
};
