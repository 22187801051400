/* import __COLOCATED_TEMPLATE__ from './topbar.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface SubscriptionTopbarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionTopbarComponent extends Component<SubscriptionTopbarSignature> {
  topBanner = TopBanner;

  constructor(owner: unknown, args: SubscriptionTopbarSignature['Args']) {
    super(owner, args);
    let data = safeLocalStorage.getItem('price-plan-change-in-progress');
    try {
      // @ts-expect-error
      let { code, recurrence } = JSON.parse(data);
      if (!code || !recurrence) {
        this.close();
      }
      // @ts-expect-error
      this.pricePlanCode = code;
      // @ts-expect-error
      this.recurrence = recurrence;
    } catch {
      this.close();
    }
  }

  @action
  close() {
    // @ts-expect-error
    this.args.close();
    safeLocalStorage.removeItem('price-plan-change-in-progress');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::Topbar': typeof SubscriptionTopbarComponent;
  }
}
