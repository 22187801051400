/* import __COLOCATED_TEMPLATE__ from './requirements.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface SavingsAccountsStepsRequirementsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SavingsAccountsStepsRequirementsComponent extends Component<SavingsAccountsStepsRequirementsSignature> {
  checkbox = Checkbox;

  @service declare segment: Services['segment'];

  @tracked allowFATCAError = false;
  @tracked allowBannedCountriesError = false;
  @tracked allowProSecrecyError = false;

  @action
  toggleAllowFATCA() {
    // @ts-expect-error
    this.args.context.toggleAllowFATCA();
    this.allowFATCAError = false;
  }

  @action
  toggleAllowBannedCountries() {
    // @ts-expect-error
    this.args.context.toggleAllowBannedCountries();
    this.allowBannedCountriesError = false;
  }

  @action
  toggleAllowProSecrecy() {
    // @ts-expect-error
    this.args.context.toggleAllowProSecrecy();
    this.allowProSecrecyError = false;
  }

  handleNextTask = dropTask(async () => {
    // @ts-expect-error
    this.allowFATCAError = !this.args.context.allowFATCA;
    // @ts-expect-error
    this.allowBannedCountriesError = !this.args.context.allowBannedCountries;
    // @ts-expect-error
    this.allowProSecrecyError = !this.args.context.allowProSecrecy;

    if (
      // @ts-expect-error
      this.args.context.allowFATCA &&
      // @ts-expect-error
      this.args.context.allowBannedCountries &&
      // @ts-expect-error
      this.args.context.allowProSecrecy
    ) {
      this.segment.track('savings_compliance_cta');
      // @ts-expect-error
      await this.args.context.loadMemberships();
      // @ts-expect-error
      this.args.transitionToNext();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::Steps::Requirements': typeof SavingsAccountsStepsRequirementsComponent;
  }
}
