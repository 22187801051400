/* import __COLOCATED_TEMPLATE__ from './loading-placeholder.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutDynamicFormLoadingPlaceholderComponent extends Component<Signature> {
  placeholderBlock: typeof SkeletonLoader.Block = SkeletonLoader.Block;
  placeholderLine: typeof SkeletonLoader.Line = SkeletonLoader.Line;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::LoadingPlaceholder': typeof TransfersInternationalOutDynamicFormLoadingPlaceholderComponent;
  }
}
