/* import __COLOCATED_TEMPLATE__ from './trigger.hbs'; */
import Component from '@glimmer/component';

import { Currency } from 'qonto/react/components/transfers/international-out/currency';

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutAmounCurrencyFieldTriggerComponent extends Component<Signature> {
  currency = Currency;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::AmountCurrencyField::Trigger': typeof TransfersInternationalOutAmounCurrencyFieldTriggerComponent;
  }
}
