export default {
  "container": "qtN",
  "item": "qtv caption-bold",
  "hover": "qto",
  "active": "qtn",
  "name-wrapper": "qti",
  "name": "qtA body-2",
  "count": "qtY",
  "actions-placeholder": "qth",
  "actions": "qtf",
  "show": "qtK",
  "action-button": "qtG"
};
