/* import __COLOCATED_TEMPLATE__ from './archive-popup.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ArchivePopupSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ArchivePopup extends Component<ArchivePopupSignature> {
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  archiveTask = dropTask(async close => {
    // @ts-expect-error
    let { supplier } = this.args.data;
    try {
      await supplier.archive();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-list.archived-suplier.success-toast')
      );
      this.segment.track('archive-supplier_submit', { result: 'success' });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.segment.track('archive-supplier_submit', { result: 'error' });
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
    } finally {
      this.router.transitionTo('suppliers', { queryParams: { highlighted: undefined } });
      close();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Suppliers::ArchivePopup': typeof ArchivePopup;
  }
}
