/* import __COLOCATED_TEMPLATE__ from './temporary-announcement.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface TopbarTemporaryAnnouncementSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopbarTemporaryAnnouncementComponent extends Component<TopbarTemporaryAnnouncementSignature> {
  topBanner = TopBanner;

  @action
  close() {
    // @ts-expect-error
    let { bannerId } = this.args.content;
    // @ts-expect-error
    safeLocalStorage.setItem(bannerId, true);

    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::TemporaryAnnouncement': typeof TopbarTemporaryAnnouncementComponent;
  }
}
