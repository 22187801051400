/* import __COLOCATED_TEMPLATE__ from './amount-input.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DEFAULT_LOCALE } from '@repo/shared-config/app/constants/locales';
import { isNil } from 'es-toolkit';

import type { CurrencyCode } from 'qonto/constants/international-out/currency';
import {
  formatAmountWithoutCurrency,
  getAmountProperties,
  parseFormattedAmount,
} from 'qonto/utils/amount';

const removeTrailingSpaces = (value: string | undefined): string => value?.replace(/\s/g, '') ?? '';

interface Signature {
  Args: {
    value?: number | null;
    currency?: CurrencyCode;
    update?: (value: number | null) => void;
    numberOfDecimals?: number;
    placeholder?: string;
  };
  Element: HTMLInputElement;
}

/**
 * An amount/money input component.
 *
 * @public
 * @class AmountInput
 *
 * @example
 *
 * ```hbs
 *  <AmountInput @value={{this.value}} @currency={{this.currency}} @update={{fn (mut this.value)}} />
 * ```
 */
export default class TransfersInternationalOutAmountCurrencyFieldAmountInputComponent extends Component<Signature> {
  @service declare localeManager: Services['localeManager'];

  /**
   * The properties of the amount (group separator, decimal separator, step, etc.) based on the currency and locale.
   */
  get amountProperties() {
    return getAmountProperties(this.args.currency, this.localeManager.locale);
  }

  /**
   * Specifies the number of decimals to use for the amount value.
   * Can be >= 0.
   * Defaults to based in the currency code
   */
  get numberOfDecimals(): number {
    return this.args.numberOfDecimals ?? this.amountProperties.numberOfDecimals;
  }

  /**
   * The placeholder displayed in the input.
   * Defaults to based in the currency code
   */
  get placeholder(): string {
    return this.args.placeholder ?? this.amountProperties.placeholder;
  }

  /**
   * The value to display in the input.
   */
  get value(): string {
    const { value } = this.args;
    return isNil(value) || isNaN(parseFloat(String(value)))
      ? ''
      : formatAmountWithoutCurrency(value, this.localeManager.locale ?? DEFAULT_LOCALE);
  }

  /**
   * Handles the blur event for the amount input field.
   */
  @action
  onBlur(event: FocusEvent): boolean {
    const target = event.target as HTMLInputElement;
    const { value } = target;
    const sanitizedValue = removeTrailingSpaces(value);
    const parsedValue = this.#parseFormattedAmount(sanitizedValue);

    if (parsedValue !== this.args.value) {
      if (sanitizedValue === '') {
        this.args.update?.(null);
      } else {
        target.value = sanitizedValue;
        this.args.update?.(parsedValue);
      }
    }
    return true;
  }

  /**
   * Handles the input event.
   */
  @action
  onInput(event: InputEvent): boolean {
    const target = event.target as HTMLInputElement;
    const { value } = target;
    const sanitizedValue = removeTrailingSpaces(value);
    const parsedValue = this.#parseFormattedAmount(sanitizedValue);

    if (sanitizedValue === '') {
      this.args.update?.(null);
      return true;
    }

    // If the parsed value is not a valid number, we wait for the blur event to trigger an update
    if (isNaN(parsedValue)) {
      event.preventDefault();
      return false;
    }

    // If the last character value is a decimal separator or the decimal part is only zero, we wait for the next input (or blur) event
    if (
      this.#isLastCharacterDecimalSeparator(sanitizedValue) ||
      this.#isDecimalPartZero(sanitizedValue)
    ) {
      event.preventDefault();
      return false;
    }

    this.args.update?.(parsedValue);
    return true;
  }

  /**
   * Prevents user from typing non-numeric characters.
   */
  @action
  onKeyDown(event: KeyboardEvent): boolean {
    const { key, altKey, ctrlKey, metaKey } = event;

    if (this.numberOfDecimals === 0 && ['.', ','].includes(event.key)) {
      event.preventDefault();
      return false;
    }

    const isValidKey =
      key.length > 1 ||
      altKey ||
      ctrlKey ||
      metaKey ||
      ['.', ',', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key);

    if (!isValidKey) {
      event.preventDefault();
    }

    return isValidKey;
  }

  /**
   * Handles the paste event.
   */
  @action
  onPaste(event: ClipboardEvent): boolean {
    const value = event.clipboardData?.getData('text');
    const sanitizedValue = removeTrailingSpaces(value);
    const parsedValue = this.#parseFormattedAmount(sanitizedValue);

    // If the parsed value is not a valid number, we wait for the blur event to trigger an update
    if (isNaN(parsedValue)) {
      event.preventDefault();
      return false;
    }

    this.args.update?.(parsedValue);
    event.preventDefault();
    return true;
  }

  /**
   * Returns `true` if the last character of the value is the decimal separator.
   */
  #isLastCharacterDecimalSeparator(value: string): boolean {
    return value.slice(-1) === this.amountProperties.decimalSeparator;
  }

  /**
   * Returns `true` if the decimal part of the value is zero.
   */
  #isDecimalPartZero(value: string): boolean {
    const { groupSeparator, decimalSeparator } = this.amountProperties;
    const normalizedAmount = value.replaceAll(groupSeparator, '').replace(decimalSeparator, '.');
    const decimals = normalizedAmount.split('.').at(1);
    return decimals === '0';
  }

  /**
   * Parses the formatted amount string into a number.
   */
  #parseFormattedAmount(value: string): number {
    return parseFormattedAmount(value, this.amountProperties);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::AmountCurrencyField::AmountInput': typeof TransfersInternationalOutAmountCurrencyFieldAmountInputComponent;
  }
}
