/* import __COLOCATED_TEMPLATE__ from './update-avatar-form.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import LocalFile from 'qonto/objects/local-file';

interface UpdateAvatarFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class UpdateAvatarForm extends Component<UpdateAvatarFormSignature> {
  // @ts-expect-error
  @tracked cropper;
  // @ts-expect-error
  @tracked crop;

  // @ts-expect-error
  @action cropAction(cropper, event) {
    let cropData = event.detail;
    let imageData = cropper.getImageData();
    this.cropper = cropper;
    this.crop = { cropData, imageData };

    return this.handleAvatarChanges();
  }

  @action handleAvatarChanges() {
    let { cropper } = this;

    if (cropper) {
      next(() => {
        let canvas = cropper.getCroppedCanvas();

        // @ts-expect-error
        this.args.handleFinalFile(
          LocalFile.create({
            // @ts-expect-error
            fileName: this.args.avatar.fileName,
            fileContentType: 'image/png',
            fileUrl: canvas.toDataURL(),
          })
        );
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UpdateAvatarForm: typeof UpdateAvatarForm;
  }
}
