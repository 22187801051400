/* import __COLOCATED_TEMPLATE__ from './categories-loading.hbs'; */
import Component from '@glimmer/component';

import { SearchField } from '@repo/design-system-kit';

interface SettingsConnectHubCategoriesLoadingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SettingsConnectHubCategoriesLoadingComponent extends Component<SettingsConnectHubCategoriesLoadingSignature> {
  searchField = SearchField;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::ConnectHub::CategoriesLoading': typeof SettingsConnectHubCategoriesLoadingComponent;
  }
}
