/* import __COLOCATED_TEMPLATE__ from './error.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ErrorSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Error = templateOnlyComponent<ErrorSignature>();

export default Error;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::CustomExports::Modal::Error': typeof Error;
  }
}
