/* import __COLOCATED_TEMPLATE__ from './close.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CloseSignature {
  Element: HTMLButtonElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Close = templateOnlyComponent<CloseSignature>();

export default Close;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Close': typeof Close;
  }
}
