/* import __COLOCATED_TEMPLATE__ from './attachments-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';

interface TransfersSepaTransferFormAttachmentsSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransfersSepaTransferFormAttachmentsSelectorComponent extends Component<TransfersSepaTransferFormAttachmentsSelectorSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare abilities: Services['abilities'];
  @service declare attachmentsManager: Services['attachmentsManager'];
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked checked = Boolean(this.args.transfer.attachments?.length);

  constructor(owner: unknown, args: TransfersSepaTransferFormAttachmentsSelectorSignature['Args']) {
    super(owner, args);
    this._onCheckedStatusUpdate();
  }

  get isActive() {
    // @ts-expect-error
    return this.checked || this.args.transfer.shouldHaveAttachments;
  }

  get uploaderErrors() {
    // @ts-expect-error
    let { enableTransferValidations, transfer } = this.args;

    if (enableTransferValidations) {
      if (transfer.attachmentsFiles.length) {
        return this.attachmentsManager.fileErrors;
      }
      if (transfer.validations.attrs.attachments.errors.length) {
        return transfer.validations.attrs.attachments.errors;
      }
    }

    return false;
  }

  get hasRequiredFiles() {
    // @ts-expect-error
    return this.args.transfer.attachmentsFiles.some(attachment =>
      this.requiredAttachmentFiles.includes(attachment)
    );
  }

  get isReadOnly() {
    // @ts-expect-error
    return this.args.transfer.shouldHaveAttachments || this.hasRequiredFiles;
  }

  get requiredAttachmentFiles() {
    // @ts-expect-error
    return this.args.requiredAttachmentFiles ?? [];
  }

  get shouldDisplayRequiredDisclaimer() {
    // @ts-expect-error
    let { transfer } = this.args;
    return transfer.shouldHaveAttachments && !this.hasRequiredFiles;
  }

  get shouldDisplayProbateDisclaimer() {
    // @ts-expect-error
    let { transfer } = this.args;
    return Boolean(transfer.attachments?.length) && this.abilities.cannot('probate attachment');
  }

  @action
  toggleSelector() {
    this.checked = !this.checked;

    // @ts-expect-error
    if (!this.checked && this.args.transfer.attachments.content) {
      // @ts-expect-error
      this.args.transfer.attachments.content.length = 0;
    }

    this._onCheckedStatusUpdate();
  }

  _onCheckedStatusUpdate() {
    // @ts-expect-error
    this.args.onSelect?.(this.isActive);
  }

  // @ts-expect-error
  @action addAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.addAttachment(false, this.args.transfer, file);
  }

  // @ts-expect-error
  @action removeAttachment(file) {
    this.attachmentsManager.removeAttachment(
      // @ts-expect-error
      Boolean(this.args.transfer.id),
      // @ts-expect-error
      this.args.transfer,
      file
    );
  }

  // @ts-expect-error
  @action cancelUploadAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.cancelUploadAttachment(this.args.transfer, file);
  }

  // @ts-expect-error
  @action showAttachment(selectedFile) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile,
      // @ts-expect-error
      model: this.args.transfer,
      download: this.attachmentsManager.downloadAttachment,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::TransferForm::AttachmentsSelector': typeof TransfersSepaTransferFormAttachmentsSelectorComponent;
  }
}
