/* import __COLOCATED_TEMPLATE__ from './notify-beneficiary-selector.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { TextField } from '@repo/design-system-kit';

interface TransfersSepaTransferFormNotifyBeneficarySelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransfersSepaTransferFormNotifyBeneficarySelectorComponent extends Component<TransfersSepaTransferFormNotifyBeneficarySelectorSignature> {
  textField = TextField;

  @action
  toggleNotifyEmail() {
    // @ts-expect-error
    this.args.transfer.notifyByEmail = !this.args.transfer.notifyByEmail;
    // @ts-expect-error
    this.args.onToggleSelector();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::TransferForm::NotifyBeneficiarySelector': typeof TransfersSepaTransferFormNotifyBeneficarySelectorComponent;
  }
}
