/* import __COLOCATED_TEMPLATE__ from './details-section.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DetailsSectionSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DetailsSection = templateOnlyComponent<DetailsSectionSignature>();

export default DetailsSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::DetailsSection': typeof DetailsSection;
  }
}
