/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { type CalendarDate, parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';

import type { FormFieldArgs } from 'qonto/components/transfers/international-out/dynamic-form/field/arguments';
import isFunction from 'qonto/utils/is-function';

interface Signature {
  Args: FormFieldArgs;
  Element: HTMLDivElement;
}

export default class TransfersInternationalOutDynamicFormFieldDatePickerComponent extends Component<Signature> {
  datePicker = DatePicker;

  get date(): CalendarDate | null {
    return this.args?.value ? parseDate(this.args.value) : null;
  }

  get #shouldRefreshOnChange(): boolean {
    const { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onUpdate(date: CalendarDate | null): void {
    const { field, onRefresh, onUpdate } = this.args;

    onUpdate(field.key, date ? date.toString() : null);

    if (this.#shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::DatePicker': typeof TransfersInternationalOutDynamicFormFieldDatePickerComponent;
  }
}
