/* import __COLOCATED_TEMPLATE__ from './payment-methods.hbs'; */
// @ts-nocheck
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { AUTHORIZED_USD_PAYMENT_METHODS } from 'qonto/constants/international-out/payment-method';
import { DEFAULT_TARGET_CURRENCY_CODE } from 'qonto/constants/international-out/quote';
import { type FormGroupOptions } from 'qonto/utils/dynamic-form';

type PaymentMethodChoice = FormGroupOptions;

interface Signature {
  Args: {
    currency: string;
    paymentMethods?: string[] | null;
  };
  Blocks: {
    default: [data: PaymentMethodChoice];
  };
  Element: null;
}

const DISCLAIMER_OPTIONS: Readonly<Omit<PaymentMethodChoice['disclaimer'], 'content'>> = {
  level: 'info',
  type: 'inline',
} as const;

export default class TransfersInternationalOutProvidersPaymentMethodsComponent extends Component<Signature> {
  @service declare intl: Services['intl'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  get disclaimer(): PaymentMethodChoice['disclaimer'] | null {
    let { currency, paymentMethods } = this.args;

    let authorizedPaymentMethods = Object.values(AUTHORIZED_USD_PAYMENT_METHODS);

    if (
      currency === DEFAULT_TARGET_CURRENCY_CODE &&
      paymentMethods?.length === authorizedPaymentMethods.length &&
      paymentMethods?.every(method => authorizedPaymentMethods.includes(method))
    ) {
      return {
        ...DISCLAIMER_OPTIONS,
        content: this.intl.t('international-out.transfer-methods.disclaimer.usd.text', {
          // @ts-expect-error -- Expect a primitive type but get a SafeString
          link: htmlSafe(
            `<a
              href=${this.zendeskLocalization.getLocalizedArticle(9105540)}
              target="_blank"
              rel="noopener noreferrer"
              class="body-link"
              data-test-payment-methods-faq-link
            >
              ${this.intl.t('international-out.transfer-methods.disclaimer.usd.linkText')}
            </a>`
          ),
          htmlSafe: true,
        }),
      };
    }

    if (paymentMethods && paymentMethods.length > 1) {
      return {
        ...DISCLAIMER_OPTIONS,
        content: this.intl.t('international-out.transfer-methods.disclaimer.generic.text', {
          // @ts-expect-error -- Expect a primitive type but get a SafeString
          link: htmlSafe(
            `<a
              href=${this.zendeskLocalization.getLocalizedArticle(9105540)}
              target="_blank"
              rel="noopener noreferrer"
              class="body-link"
              data-test-payment-methods-faq-link
            >
              ${this.intl.t('international-out.transfer-methods.disclaimer.generic.linkText')}
            </a>`
          ),
          htmlSafe: true,
        }),
      };
    }

    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Providers::PaymentMethods': typeof TransfersInternationalOutProvidersPaymentMethodsComponent;
  }
}
