/* import __COLOCATED_TEMPLATE__ from './attachment-menu-dropdown.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface TransactionsSidebarDetailsAttachmentMenuDropdownSignature {
  // The arguments accepted by the component
  Args: {
    isInvoice?: boolean;
    isDebit?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSidebarDetailsAttachmentMenuDropdownComponent extends Component<TransactionsSidebarDetailsAttachmentMenuDropdownSignature> {
  @service declare abilities: Services['abilities'];

  get showSaveInSupplierInvoices() {
    return this.abilities.can('upload supplierInvoice');
  }

  get showSaveInClientInvoices() {
    return this.abilities.can('create receivableInvoice');
  }

  get showOpenInSupplierInvoices() {
    return this.abilities.can('view supplierInvoice');
  }

  get showOpenInClientInvoices() {
    return this.abilities.can('read receivableInvoice');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Details::AttachmentMenuDropdown': typeof TransactionsSidebarDetailsAttachmentMenuDropdownComponent;
  }
}
