/* import __COLOCATED_TEMPLATE__ from './request-declined.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RequestDeclinedSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const RequestDeclined = templateOnlyComponent<RequestDeclinedSignature>();

export default RequestDeclined;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::RequestDeclined': typeof RequestDeclined;
  }
}
