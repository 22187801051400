/* import __COLOCATED_TEMPLATE__ from './direct-debit-collection.hbs'; */
import { get } from '@ember/object';
import Component from '@glimmer/component';

import type DirectDebitCollectionModel from 'qonto/models/direct-debit-collection';
import type DirectDebitHoldModel from 'qonto/models/direct-debit-hold';
import type TransactionModel from 'qonto/models/transaction';
import { ObfuscatedIban } from 'qonto/react/components/direct-debit-collections';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';

interface PowerSelectMandatesMandateSelectTriggerSignature {
  // The arguments accepted by the component
  Args: {
    collection: DirectDebitCollectionModel | DirectDebitHoldModel;
    transaction: TransactionModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PowerSelectMandatesMandateSelectTriggerComponent extends Component<PowerSelectMandatesMandateSelectTriggerSignature> {
  Counterparty = Counterparty;
  ObfuscatedIban = ObfuscatedIban;

  get shouldDisplayOriginalTransactionLink() {
    const hasRelatedTransactionId =
      get(this.args.collection, 'originalTransactionId') ||
      get(this.args.collection, 'directDebitCollectionTransactionId');

    const isRefundedDebit = this.args.transaction.isSDDRefunded && this.args.transaction.debit;

    const isReturnedDebit =
      this.args.transaction.isSDDReturnedAfterSettlementDate && this.args.transaction.debit;

    const isHoldTransaction =
      this.args.transaction.isDirectDebitHold &&
      (this.args.transaction.debit || this.args.transaction.credit);

    return hasRelatedTransactionId && (isRefundedDebit || isReturnedDebit || isHoldTransaction);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::DirectDebitCollection': typeof PowerSelectMandatesMandateSelectTriggerComponent;
  }
}
