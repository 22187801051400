export default {
  "sidepanel": "qQw",
  "close-icon": "qQN",
  "close-button": "qQv",
  "supplier-card": "qQo",
  "supplier-card-details": "qQn",
  "supplier-name": "qQi title-3 mb-8",
  "supplier-card-placeholder": "qQA",
  "box": "qQY",
  "box-element": "qQh",
  "related-invoices-title": "qQf",
  "placeholder-container": "qQK",
  "related-invoices-wrapper": "qQG",
  "related-invoice-placeholder": "qQr",
  "related-invoice-placeholder-inner": "qQb",
  "actions": "qQy",
  "currency": "qQU"
};
