/* import __COLOCATED_TEMPLATE__ from './matched-transaction.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';
import { dropTask, timeout } from 'ember-concurrency';

import { TRANSACTION_UPDATE_DELAY_MS } from 'qonto/constants/supplier-invoice';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';

interface TransactionsInvoicesMatchedTransactionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransactionsInvoicesMatchedTransactionComponent extends Component<TransactionsInvoicesMatchedTransactionSignature> {
  statusAvatar = StatusAvatar;

  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  spinner = Spinner;

  unlinkTransactionTask = dropTask(async () => {
    try {
      // @ts-expect-error
      let attachment = await this.args.attachment;
      // @ts-expect-error
      await this.args.transaction.unlinkAttachment([attachment]);
      await timeout(TRANSACTION_UPDATE_DELAY_MS);
      // @ts-expect-error
      this.args.onUnlink();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Invoices::MatchedTransaction': typeof TransactionsInvoicesMatchedTransactionComponent;
  }
}
