/* import __COLOCATED_TEMPLATE__ from './ubo.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { countries } from 'qonto/constants/countries';
// @ts-expect-error
import { sortByPreferedCountriesWith } from 'qonto/utils/sorters';

interface SavingsAccountsUboSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class SavingsAccountsUbo extends Component<SavingsAccountsUboSignature> {
  @service declare intl: Services['intl'];

  countryOptions = [];

  constructor(owner: unknown, args: SavingsAccountsUboSignature['Args']) {
    super(owner, args);
    this.countryOptions = countries
      // @ts-expect-error
      .map(code => ({
        key: code,
        // @ts-expect-error
        value: this.intl.formatCountry(code),
      }))
      .sort(sortByPreferedCountriesWith({ code: 'key', name: 'value' }));
  }

  get selectedCountryOptions() {
    // @ts-expect-error
    let { selectedCountryOptions } = this.args;

    return Array.isArray(selectedCountryOptions)
      ? selectedCountryOptions.map(country =>
          this.countryOptions.find(({ key }) => country.key === key)
        )
      : [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::Ubo': typeof SavingsAccountsUbo;
  }
}
