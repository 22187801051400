/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface UserActionsListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class UserActionsListComponent extends Component<UserActionsListSignature> {
  @service declare segment: Services['segment'];

  get userActions() {
    return (
      // @ts-expect-error
      this.args.userActions
        // @ts-expect-error
        .map(userAction => {
          userAction.isDisabled = Boolean(
            // @ts-expect-error
            userAction.blockedBy?.some(blocker =>
              // @ts-expect-error
              this.args.userActions.some(action => action.name === blocker)
            )
          );
          return userAction;
        })
        // @ts-expect-error
        .sort((a, b) => (!a.isDisabled && b.isDisabled ? -1 : 1))
    );
  }

  @action
  // @ts-expect-error
  handleCardDidConstruct(action) {
    this.segment.track('product-discovery_user-action_viewed', {
      name: action.name,
      status: action.isDisabled ? 'disabled' : action.status,
      // @ts-expect-error
      price_plan_code: this.args.pricePlanCode,
    });
  }

  @action
  // @ts-expect-error
  handleCtaClick(action) {
    this.segment.track('product-discovery_user-action_clicked', {
      name: action.name,
      // @ts-expect-error
      price_plan_code: this.args.pricePlanCode,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UserActions::List': typeof UserActionsListComponent;
  }
}
