/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';

interface SavingsAccountsStepsReviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SavingsAccountsStepsReviewComponent extends Component<SavingsAccountsStepsReviewSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  @tracked hasTermsConditionsError = false;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  get interestRate() {
    // @ts-expect-error
    return this.args.context.rates.find(
      // @ts-expect-error
      ({ nominalMaturity }) => nominalMaturity === this.args.context.maturity
    ).nominalInterestRate;
  }

  get interestEarned() {
    // @ts-expect-error
    return Number(this.args.context.interestEarned);
  }

  get totalAmount() {
    // @ts-expect-error
    return this.interestEarned + Number(this.args.context.amount);
  }

  get contractDuration() {
    // @ts-expect-error
    return this.args.context.maturity.slice(0, -1);
  }

  get contractUrl() {
    // @ts-expect-error
    return `${apiBaseURL}/v3/savings_accounts/contract?organization_id=${this.organizationManager.organization.id}&procedure_id=${this.args.context.procedureId}`;
  }

  @action
  toggleHasAgreedTermsConditions() {
    // @ts-expect-error
    this.args.context.toggleHasAgreedTermsConditions();
    if (this.hasTermsConditionsError) {
      this.hasTermsConditionsError = false;
    }
  }

  handleNextTask = dropTask(async () => {
    this.hasTermsConditionsError = false;
    // @ts-expect-error
    if (this.args.context.hasAgreedTermsConditions) {
      try {
        // @ts-expect-error
        let { signSuccessful } = await this.args.context.signContractAndTransferTask.perform(
          // @ts-expect-error
          this.args.context.amount,
          // @ts-expect-error
          this.args.context.procedureId,
          // @ts-expect-error
          this.args.context.sourceOfFunds,
          // @ts-expect-error
          this.args.context.membershipFiscalResidences
        );
        if (signSuccessful) {
          this.segment.track('savings_summary_cta');
          // @ts-expect-error
          this.args.transitionToNext();
        }
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    } else {
      this.hasTermsConditionsError = true;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::Steps::Review': typeof SavingsAccountsStepsReviewComponent;
  }
}
