export default {
  "row": "cuw",
  "disabled": "cuN",
  "cell": "cuv body-2",
  "cell-link": "cuo",
  "cell-content": "cun",
  "item-truncate-text": "cui",
  "item-name-cell": "cuA",
  "item-account-cell": "cuY",
  "icon": "cuh",
  "icon-download": "cuf"
};
