/* import __COLOCATED_TEMPLATE__ from './filter.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FilterSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Filter = templateOnlyComponent<FilterSignature>();

export default Filter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Suppliers::Filter': typeof Filter;
  }
}
