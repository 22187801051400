/* import __COLOCATED_TEMPLATE__ from './nrc-payment.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

interface NrcPaymentHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class NrcPaymentHeader extends Component<NrcPaymentHeaderSignature> {
  amount = Amount;
  Counterparty = Counterparty;
  Date = Date;
  type = Type;

  @service declare intl: Services['intl'];

  get subject() {
    // @ts-expect-error
    return this.args.highlightedItem.subject;
  }

  get nrc() {
    return this.subject.get('nrc');
  }

  get modeloDescription() {
    let taxCode = this.subject.get('taxCode');
    let taxDescription = this.subject.get('taxDescription');
    return `${this.intl.t('aeat.sidepanel.labels.modelo')} ${taxCode} - ${taxDescription}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::NrcPayment': typeof NrcPaymentHeader;
  }
}
