/* import __COLOCATED_TEMPLATE__ from './matched-section.hbs'; */
import Component from '@glimmer/component';

import { dropTask, timeout } from 'ember-concurrency';

import { TRANSACTION_UPDATE_DELAY_MS } from 'qonto/constants/supplier-invoice';

interface SupplierInvoicesDetailsSidebarMatchedSectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SupplierInvoicesDetailsSidebarMatchedSectionComponent extends Component<SupplierInvoicesDetailsSidebarMatchedSectionSignature> {
  filterGroup = {
    conditional: 'and',
    expressions: [
      {
        property: 'attachment_ids',
        // @ts-expect-error
        values: [this.args.invoice.attachment?.id],
        operator: 'in',
      },
    ],
  };

  refreshTransactionsTask = dropTask(async searchTransactions => {
    await timeout(TRANSACTION_UPDATE_DELAY_MS);
    searchTransactions();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::DetailsSidebar::MatchedSection': typeof SupplierInvoicesDetailsSidebarMatchedSectionComponent;
  }
}
