/* import __COLOCATED_TEMPLATE__ from './confirm-modal.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import { task, waitForQueue } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { DISCLAIMER_TYPES, SEPA_MODAL_CLOSE_REASONS } from 'qonto/constants/transfers';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface TransfersInternalConfirmModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersInternalConfirmModalComponent extends Component<TransfersInternalConfirmModalSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare organizationManager: Services['organizationManager'];

  cancelButtonId = `cancel-btn-${guidFor(this)}`;
  submitButtonId = `submit-btn-${guidFor(this)}`;

  constructor(owner: unknown, args: TransfersInternalConfirmModalSignature['Args']) {
    super(owner, args);

    this._trackOnInit();
    this.setFocus();
  }

  @tracked showErrors = false;

  get disclaimers() {
    // @ts-expect-error
    let { warnings, errors, role } = this.args.data;
    return this._getInternalDisclaimers({ warnings, errors, role });
  }

  get transferDate() {
    // @ts-expect-error
    let { scheduledDate } = this.args.data.transfer;

    return dateToken({
      date: scheduledDate,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.COMPACT,
    });
  }

  get displayKycDisclaimer() {
    return this.organizationManager.membership.kycPending;
  }

  async setFocus() {
    await waitForQueue('afterRender');
    // @ts-expect-error
    if (this.args.data.disabledConfirm) {
      // @ts-expect-error
      document.getElementById(this.cancelButtonId).focus();
    } else {
      // @ts-expect-error
      document.getElementById(this.submitButtonId).focus();
    }
  }

  @action
  handleClick() {
    // @ts-expect-error
    this.sensitiveActions.runTask.perform(this.confirmTask).catch(ignoreCancelation);
    this.trackOnSubmit();
  }

  _trackOnInit() {
    // @ts-expect-error
    let { disabledConfirm, transfer } = this.args.data;
    let trackOptions = {
      confirm_clickable: !disabledConfirm,
    };

    if (!isEmpty(this.disclaimers)) {
      // @ts-expect-error
      trackOptions.general_warnings = this.disclaimers.map(({ type }) => type);
    }

    this.segment.track(this._chooseTrackingEvent(transfer, 'displayed'), trackOptions);
  }

  // @ts-expect-error
  _chooseTrackingEvent(transfer, action) {
    return transfer.isNew
      ? `transfer_internal_create_summary_${action}`
      : `transfer_edit_summary_${action}`;
  }

  @action
  trackOnSubmit() {
    // @ts-expect-error
    let { transfer } = this.args.data;

    this.segment.track(this._chooseTrackingEvent(transfer, 'confirmed'), {
      origin: this.router?.currentRoute?.queryParams?.origin,
    });
  }

  confirmTask = task(async () => {
    // @ts-expect-error
    await this.args.data.confirmTransferTask.linked().perform();
    // @ts-expect-error
    this.args.close(SEPA_MODAL_CLOSE_REASONS.CONFIRM_TRANSFER);
  });
  @action
  handleClose() {
    this.segment.track('transfer_create_summary_cancel');
    // @ts-expect-error
    this.args.close();
  }

  // @ts-expect-error
  _getInternalDisclaimers({ warnings = [], errors = [], role }) {
    let disclaimers = [
      {
        type: DISCLAIMER_TYPES.ALREADY_ISSUED,
        level: 'warning',
        message: this.intl.t('transfers.warnings.already_issued'),
      },
      {
        type: DISCLAIMER_TYPES.KYB_WAITING,
        level: 'warning',
        message: this.intl.t('transfers.warnings.kyb_waiting'),
      },
      {
        type: DISCLAIMER_TYPES.TOP_UP_REQUIRED,
        level: 'warning',
        message: this.intl.t('transfers.warnings.top_up_required'),
      },
      {
        type: DISCLAIMER_TYPES.BILLER_INSUFFICIENT_FUNDS,
        level: 'error',
        message:
          role === ROLES.MANAGER
            ? this.intl.t('transfers.warnings.manager.insufficient_funds')
            : this.intl.t('transfers.warnings.insufficient_funds'),
      },
    ];
    // @ts-expect-error
    let selectDisclaimer = disclaimer =>
      [...warnings, ...errors].find(warning => warning === disclaimer.type);
    return disclaimers.filter(selectDisclaimer);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Internal::ConfirmModal': typeof TransfersInternalConfirmModalComponent;
  }
}
