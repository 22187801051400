/* import __COLOCATED_TEMPLATE__ from './ar-promo-card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/receivable-invoice';
import { LabelTile } from 'qonto/react/components/transactions/table/labels/tile/label-tile';

interface TransactionsSiderbarDetailsAttachmentsArPromoCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSiderbarDetailsAttachmentsArPromoCard extends Component<TransactionsSiderbarDetailsAttachmentsArPromoCardSignature> {
  LabelTile = LabelTile;

  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  constructor(
    owner: unknown,
    args: TransactionsSiderbarDetailsAttachmentsArPromoCardSignature['Args']
  ) {
    super(owner, args);

    this.segment.track('ar-discoverability_banner_displayed', { type: 'attachment' });
  }

  @action
  onClick() {
    this.segment.track('ar-discoverability_banner_clicked', { type: 'attachment' });
    this.router.transitionTo('receivable-invoices.index', {
      queryParams: {
        status: STATUS.DRAFT,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Details::Attachments::ArPromoCard': typeof TransactionsSiderbarDetailsAttachmentsArPromoCard;
  }
}
