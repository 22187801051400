/* import __COLOCATED_TEMPLATE__ from './fieldset.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: null;
  Args: {
    isDisabled?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const Fieldset = templateOnlyComponent<Signature>();

export default Fieldset;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Fieldset': typeof Fieldset;
  }
}
